import React, { memo } from "react";

import { SideMenuLayout } from "@sk/uis";
import { Outlet, useNavigate } from "react-router-dom";

const Layout = () => {
  const router = useNavigate();
  return (
    <SideMenuLayout router={router}>
      <Outlet />
    </SideMenuLayout>
  );
};

export default memo(Layout);
