import { useAttachAdditionalData } from "@sk/hooks";
import { AccountService } from "@sk/services";
import {
  Amount,
  AppTitle,
  DateFormatter,
  HighlightText,
  KeyVal,
  NoDataFound,
  PageLoader,
} from "@sk/uis";
import { useCallback, useEffect, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import { viewModal } from "../../constantService";
import CreditDebitAuditLog from "../components/CreditDebitAuditLog";

const canvasStyle = { width: "50%" };

const CreditDebitViewModal = ({ show, creditNoteId, callback }) => {
  const [display, setDisplay] = useState("loading");
  const [setAdditionalData, attachAllData] = useAttachAdditionalData();
  const [data, setData] = useState({});

  // To Close Filter Modal
  const closeModal = () => {
    callback({ status: "closed" });
  };

  useEffect(() => {
    if (show && creditNoteId) {
      loadDetails();
    } else {
      setDisplay("noDataFound");
    }
  }, [loadDetails, creditNoteId, show]);

  const loadDetails = useCallback(async () => {
    setDisplay("loading");
    const r = await AccountService.getCreditDebitList({
      filter: { _id: creditNoteId },
    });
    const d = Array.isArray(r.resp) ? (r.resp.length ? r.resp[0] : {}) : {};

    if (d._id) {
      let tmp = [];
      // Attach User Info
      setAdditionalData([d], viewModal.additionalTableDataConfig, (x) => {
        tmp.push(x);
        if (tmp.length == viewModal.additionalTableDataConfig.length) {
          const t = [...attachAllData([d], tmp)][0];
          setData((v) => ({ ...v, ...t }));
        }
      });
    }

    if (d._id) {
      setData(d);
      setDisplay("details");
    } else {
      setDisplay("noDataFound");
    }
  }, [attachAllData, creditNoteId, setAdditionalData]);

  return (
    <>
      <Offcanvas
        show={show}
        onHide={closeModal}
        placement="end"
        style={canvasStyle}
      >
        <Offcanvas.Header closeButton className="bg-primary">
          {/* Modal Title */}
          <Offcanvas.Title>
            <AppTitle title={"View Credit/Debit Details #" + creditNoteId} />
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="p-0  border-top">
          {display == "loading" && <PageLoader />}
          {display == "noDataFound" && (
            <NoDataFound> No Data Found</NoDataFound>
          )}
          {display == "details" && (
            <div>
              {/* Franchise Details */}
              <div className="p-4 bg-light">
                <div className="row">
                  <div className="col-8">
                    <div className="fw-semibold fs-val-lg mb-2">Franchise</div>
                    <div className="text-primary fs-val-lg mb-1">
                      {data?._franchise?.name || "N/A"} - ({data?.franchiseId})
                    </div>
                    {data?._franchise?.name ? (
                      <div className="text-primary ">
                        {data?._franchise?.district}
                        {data?._franchise?.district ? ", " : null}
                        {data?._franchise?.state}- {data?._franchise?.pincode}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-4 pt-2">
                    <div className="mt-4">
                      <KeyVal label="Status" template="col">
                        <HighlightText status={data?.status} />
                      </KeyVal>
                    </div>
                  </div>
                </div>
              </div>

              {/* Basic Details */}
              <div className="p-4 pb-0">
                <div className="fw-semibold fs-val-lg mb-2">Basic Details</div>

                <div className="row">
                  <div className="col-4 mb-4">
                    <KeyVal label="Franchise Acc No." template="col">
                      {data?.accountNo || "N/A"}
                    </KeyVal>
                  </div>

                  <div className="col-4 mb-4">
                    <KeyVal label="Batch ID" template="col">
                      {data?.batchId || "N/A"}
                    </KeyVal>
                  </div>

                  <div className="col-4 mb-4">
                    <KeyVal
                      label="Type"
                      template="col"
                      className="text-primary"
                    >
                      {data?.type}
                    </KeyVal>
                  </div>

                  <div className="col-4 mb-4">
                    <KeyVal label="Wallet Type" template="col">
                      {data?.walletType}
                    </KeyVal>
                  </div>

                  <div className="col-4 mb-4">
                    <KeyVal label="Amount(Rs.)" template="col">
                      <Amount value={data?.amount} />
                    </KeyVal>
                  </div>

                  <div className="col-4 mb-4">
                    <KeyVal label="Reason for Credit/Debit" template="col">
                      {data?.reasonForCredit || "N/A"}
                    </KeyVal>
                  </div>
                </div>

                <div className="row mb-4">
                  <div className="col-4 mb-4">
                    <KeyVal label="Created On" template="col">
                      <DateFormatter date={data?.createdAt} />
                    </KeyVal>
                  </div>

                  <div className="col-4 mb-4">
                    <KeyVal label="Created By" template="col">
                      <div className="fs-sm">
                        {data?._createdBy?.name || "N/A"}
                      </div>
                    </KeyVal>
                  </div>

                  <div className="col-4 mb-4">
                    <KeyVal label="Last Update On" template="col">
                      <DateFormatter date={data?.lastUpdated} />
                    </KeyVal>
                  </div>

                  <div className="col-4 mb-4">
                    <KeyVal label="Approved On" template="col">
                      <div className="fs-sm">{"N/A"}</div>
                    </KeyVal>
                  </div>

                  <div className="col-4 mb-4">
                    <KeyVal label="Approved By" template="col">
                      <div className="fs-sm">
                        {data?._modifiedBy?.name || "N/A"}
                      </div>
                    </KeyVal>
                  </div>

                  {data?.approvedRemarks ? (
                    <div className="col-4 mb-4">
                      <KeyVal label="Approved Remarks" template="col">
                        <div className="fs-sm">
                          {data?.approvedRemarks || "N/A"}
                        </div>
                      </KeyVal>
                    </div>
                  ) : null}

                  {data?.remarks ? (
                    <div className="col-4">
                      <KeyVal label="Credited Reason" template="col">
                        <div className="fs-sm">{data?.remarks || "N/A"}</div>
                      </KeyVal>
                    </div>
                  ) : null}

                  {/* Rejected Details */}
                  {data.status == "Rejected" ? (
                    <>
                      <div className="col-4 mb-4">
                        <KeyVal label="Rejected On" template="col">
                          <DateFormatter date={data?.rejectedAt} />
                        </KeyVal>
                      </div>
                      <div className="col-4 mb-4">
                        <KeyVal label="Rejected By" template="col">
                          <div className="fs-sm">
                            {data?._rejectedBy?.name || "N/A"}
                          </div>
                        </KeyVal>
                      </div>
                      <div className="col-4 mb-4">
                        <KeyVal label="Rejected Remarks" template="col">
                          <div className="fs-sm">
                            {data?.rejectedRemarks || "N/A"}
                          </div>
                        </KeyVal>
                      </div>
                    </>
                  ) : null}
                </div>
              </div>

              {/* Audit Log */}
              <div className="p-4 pt-0">
                <CreditDebitAuditLog details={data} />
              </div>
            </div>
          )}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default CreditDebitViewModal;
