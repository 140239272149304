import totalImg from "../../assets/imgs/total.svg";
import activeImg from "../../assets/imgs/active.svg";
import inactiveImg from "../../assets/imgs/inactive.svg";

// LIST VIEW START
const listView = {
  breadcrumb: [
    {
      name: "Home",
      link: "/auth/init",
    },
    {
      name: "Credit Debit",
    },
  ],
  // Breadcrumbs Ends Here

  summaryData: [
    {
      label: "Total",
      value: 0,
      loading: true,
      filter: {},
      color: "primary",
      img: totalImg,
    },
    {
      label: "Accounted",
      value: 0,
      loading: true,
      filter: { status: "Accounted" },
      color: "warning",
      img: activeImg,
    },
    {
      label: "Approved",
      value: 0,
      loading: true,
      filter: { status: "Approved" },
      color: "warning",
      img: activeImg,
    },
    {
      label: "Pending Approval",
      value: 0,
      loading: true,
      filter: { status: "Pending Approval" },
      color: "warning",
      img: activeImg,
    },
    {
      label: "Rejected",
      value: 0,
      loading: true,
      filter: { status: "Rejected" },
      color: "warning",
      img: inactiveImg,
    },
  ],
  //Summary Card Data Ends Here

  additionalTableDataConfig: [
    {
      key: "franchiseId",
      api: "franchise",
      loadingKey: "franchiseLoading",
      dataKey: "_franchise",
      filter: (ids) => ({
        page: 1,
        count: ids.length,
        filter: { _id: { $in: ids } },
        select: "name,state,district,town,pincode",
      }),
    },
    {
      key: "createdBy",
      api: "user",
      loadingKey: "createdByLoading",
      dataKey: "_createdBy",
      filter: (ids) => ({
        page: 1,
        count: ids.length,
        filter: { _id: { $in: ids } },
        select: "name",
      }),
    },
  ],

  // Additional Table Data Ends Here

  pagination: {
    totalRecords: 0,
    rowsPerPage: 10,
    activePage: 1,
    startSlNo: 1,
    endSlNo: 10,
  },
  // Pagination Ends Here

  formLabels: {
    status: {
      label: "Status",
    },
    createdAt: {
      label: "Created on",
      type: "dateRange",
    },
    type: {
      label: "Type",
    },
    franchise: {
      label: "Franchise",
      valuePath: "[0].value.name",
    },
    state: {
      label: "State",
    },
    district: {
      label: "District",
    },
    town: {
      label: "Town",
    },
    noOfRows: {
      label: "No Of Rows",
    },
  },

  // Form Labels Ends Here

  filterFormData: {
    type: "",
    createdAt: [],
    state: "",
    district: "",
    town: "",
    status: "",
    noOfRows: 10,
    franchise: [],
  },

  defaultHeaders: [
    { label: "Sl No", width: "5%", isCentered: true },
    { label: "ID" },
    { label: "Type", isCentered: true },
    { label: "Reason For Credit/Debit", isCentered: true },
    { label: "Franchise" },
    { label: "Batch ID", width: "8%", isCentered: true },
    { label: "Wallet Type", isCentered: true },
    { label: "Amount(Rs.)", isCentered: true },
    { label: "Created On", key: "createdAt", enableSort: true },
    { label: "Status", isCentered: true },
    { label: "Actions", isCentered: true },
  ],
};
// LIST VIEW EDS HERE

export const filterView = {
  typeOptions: [
    { label: "All", value: "" },
    { label: "Credit", value: "Credit" },
    { label: "Debit", value: "Debit" },
    { label: "Settlement", value: "Settlement" },
  ],

  statusOptions: [
    { label: "All", value: "" },
    { label: "Accounted", value: "Accounted" },
    { label: "Approved", value: "Approved" },
    { label: "Pending Approval", value: "Pending Approval" },
    { label: "Rejected", value: "Rejected" },
  ],

  noOfRowsOptions: [
    { label: "10", value: "10" },
    { label: "25", value: "25" },
    { label: "50", value: "50" },
    { label: "75", value: "75" },
    { label: "100", value: "100" },
    { label: "150", value: "150" },
    { label: "200", value: "200" },
    { label: "250", value: "250" },
  ],

  filterFormData: {
    type: "",
    createdAt: [],
    state: "",
    district: "",
    town: "",
    status: "",
    noOfRows: "",
    franchise: [],
  },
};

export const editView = {
  defaultFormData: {},
};

export const viewModal = {
  additionalTableDataConfig: [
    {
      key: "franchiseId",
      api: "franchise",
      loadingKey: "franchiseLoading",
      dataKey: "_franchise",
      filter: (ids) => ({
        page: 1,
        count: ids.length,
        filter: { _id: { $in: ids } },
        select: "name,state,district,town,pincode",
      }),
    },
    {
      key: "createdBy",
      api: "user",
      loadingKey: "createdByLoading",
      dataKey: "_createdBy",
      filter: (ids) => ({
        page: 1,
        count: ids.length,
        filter: { _id: { $in: ids } },
        select: "name",
      }),
    },
    {
      key: "modifiedBy",
      api: "user",
      loadingKey: "modifiedByLoading",
      dataKey: "_modifiedBy",
      filter: (ids) => ({
        page: 1,
        count: ids.length,
        filter: { _id: { $in: ids } },
        select: "name",
      }),
    },
    {
      key: "rejectedBy",
      api: "user",
      loadingKey: "rejectedByLoading",
      dataKey: "_rejectedBy",
      filter: (ids) => ({
        page: 1,
        count: ids.length,
        filter: { _id: { $in: ids } },
        select: "name",
      }),
    },
  ],

  auditLogAdditionalTableDataConfig: [
    {
      key: "createdBy",
      api: "user",
      loadingKey: "auditUserLoading",
      dataKey: "_userCreatedBy",
      filter: (ids) => ({
        page: 1,
        count: ids.length,
        filter: { _id: { $in: ids } },
        select: "name",
      }),
    },
  ],

  defaultHeaders: [
    { label: "Sl No", width: "10%", isCentered: true },
    { label: "Logged On" },
    { label: "Logged By" },
    { label: "Old Status" },
    { label: "New Status" },
  ],
};

export default listView;
