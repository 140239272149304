import { useAttachAdditionalData } from "@sk/hooks";
import {
  AccountService,
  CommonService,
  DigitalPartnerService,
} from "@sk/services";
import {
  AppliedFilterLabel,
  PageInfo,
  PaginationSummary,
  TextInput,
} from "@sk/uis";
import { set } from "date-fns";
import { debounce } from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import listView from "../constantService";
import HostToHostSummaryCard from "./components/HostToHostSummaryCard";
import HostToHostTable from "./components/HostToHostTable";
import BeneficiaryFormModal from "./modals/BeneficiaryFormModal";
import HostToHostAdvanceFilterModal from "./modals/HostToHostAdvanceFilterModal";
import HostToHostViewModal from "./modals/HostToHostViewModal";

const defaultSummaryData = listView.summaryData;

const defaultFilterFormData = listView.filterFormData;

const defaultPagination = listView.pagination;

const defaultSort = listView.defaultSortOpt;

const getData = async (params) => {
  const r = await AccountService.getHostToHostList(params);
  return Array.isArray(r.resp) ? r.resp : [];
};

const getCount = async (params) => {
  delete params.count;
  delete params.page;
  try {
    const r = await AccountService.getHostToHostListCount(params);
    return { count: r.statusCode == 200 && r.resp ? r.resp : 0 };
  } catch (error) {
    return new Promise((resolve) => resolve({ count: 0 }));
  }
};

const prepareFilterParams = (pagination = {}, filter = {}, sort = {}) => {
  let p = {
    page: pagination.activePage,
    count: pagination.rowsPerPage,
    filter: {},
  };

  let d = filter || {};
  let searchKey = d.name?.length
    ? { $regex: d.name.trim(), $options: "i" }
    : "";

  if (searchKey) {
    p.filter = {
      $or: [
        { ben_account_num: searchKey },
        { rem_acc_no: searchKey },
        { trans_id: searchKey },
        { _id: searchKey },
      ],
    };
  }

  if (d?.createdAt?.length) {
    p.filter.createdAt = {
      $gte: set(filter.createdAt[0], {
        hours: 0,
        minutes: 0,
        seconds: 0,
      }),
      $lte: set(filter.createdAt[1], {
        hours: 23,
        minutes: 59,
        seconds: 59,
      }),
    };
  }
  if (d?.status) {
    p.filter.status = d.status;
  }

  if (d?.type) {
    p.filter.trans_type = d.type;
  }

  if (d?.partner) {
    p.filter.partnerId = d.partner;
  }

  if (d?.franchise?.length) {
    p.filter.franchiseId = d.franchise?.[0]?.value?._id;
  }

  if (sort?.key) {
    p.sort = sort.value == "desc" ? `-${sort.key}` : sort.key;
  }

  return p;
};

const HostToHostList = () => {
  const { register, getValues } = useForm({
    defaultValues: listView.filterFormData,
  });

  // Use Navigate Hook
  const navigate = useNavigate();

  const [data, setData] = useState([]);

  const [loadingData, setLoadingData] = useState(true);

  // To set Loading state For Get Count API
  const [loadingTotalRecords, setLoadingTotalRecords] = useState(true);

  const [setAdditionalData, attachAllData] = useAttachAdditionalData();

  const [filterLabels, setFilterLabels] = useState([]);

  const [showAdvFilterModal, setShowAdvFilterModal] = useState(false);

  const [summaryCard, setSummaryCard] = useState([]);

  const [showViewModal, setShowViewModal] = useState({
    status: false,
    data: "",
  });

  const [showBeneficiaryModal, setShowBeneficiaryModal] = useState({
    status: false,
    data: "",
  });

  const filterDataRef = useRef(defaultFilterFormData);

  const paginationRef = useRef({ ...defaultPagination });

  const sortRef = useRef({ ...defaultSort });

  const digitalPartnerOptionsRef = useRef([]);

  useEffect(() => {
    init();
  }, [init]);

  const init = useCallback(async () => {
    applyFilter();
    loadDigitalPartnerOptions();
  }, [applyFilter, loadDigitalPartnerOptions]);

  const applyFilter = useCallback(async () => {
    paginationRef.current = { ...defaultPagination };
    prepareFilterLabels();
    loadSummaryCard();
    loadList();

    setLoadingTotalRecords(true);
    const p = getFilterParams();
    const c = await getCount(p);
    paginationRef.current.totalRecords = c.count;
    setLoadingTotalRecords(false);
  }, [loadList, loadSummaryCard, prepareFilterLabels]);

  const loadSummaryCard = useCallback(() => {
    let d = [...defaultSummaryData].map((x) => {
      let p = getFilterParams();
      if (p.filter) {
        delete p.filter?.isActive;
      }

      return {
        ...x,
        filterParams: { ...p.filter, ...x.filter },
      };
    });
    setSummaryCard(d);
  }, []);

  const loadDigitalPartnerOptions = useCallback(async () => {
    let params = {
      select: "name",
      filter: {
        $or: [
          {
            "services.HostToHost.linkedBank": { $exists: true, $ne: {} },
            status: "active",
          },
          {
            "services.dynamicUPI.linkedBank": { $exists: true, $ne: {} },
            status: "active",
          },
        ],
      },
    };
    const r = await DigitalPartnerService.getDigitalPartners(params);
    if (r.statusCode === 200) {
      let opt = (r.resp || []).map((e) => {
        return { label: e.name, value: e._id };
      });
      opt.unshift({ label: "All", value: "" });
      digitalPartnerOptionsRef.current = opt;
    } else {
      digitalPartnerOptionsRef.current = [{ label: "All", value: "" }];
    }
  }, []);

  const loadList = useCallback(async () => {
    // for list
    setLoadingData(true);
    const p = getFilterParams();
    const d = await getData(p);
    let tmp = [];

    // Attaching Additional Data
    setAdditionalData(d, listView.additionalTableDataConfig, (x) => {
      tmp.push(x);
      if (tmp.length == listView.additionalTableDataConfig.length) {
        setData([...attachAllData(d, tmp)]);
      }
    });

    setData(d);
    setLoadingData(false);
  }, [attachAllData, setAdditionalData]);

  // Get Filter Params
  const getFilterParams = () => {
    return prepareFilterParams(
      paginationRef.current,
      filterDataRef.current,
      sortRef.current
    );
  };

  // For Applying Filter Params
  const prepareFilterLabels = useCallback(() => {
    const v = { ...(filterDataRef.current || {}) };
    delete v.name;
    let s = digitalPartnerOptionsRef.current.find((k) => k.value === v.partner);
    s?.label ? (v.partner = s?.label) : "";
    if (v.partner == "All") {
      delete v.partner;
    }
    const l = CommonService.prepareAppliedFilterLabels(listView.formLabels, v);
    setFilterLabels(l);
  }, []);

  // Pagination Callback
  const paginationCb = useCallback(
    (data) => {
      paginationRef.current.startSlNo = data.startSlNo;
      paginationRef.current.endSlNo = data.endSlNo;
      paginationRef.current.activePage = data.activePage;
      loadList();
    },
    [loadList]
  );

  const sortCb = useCallback(
    (data) => {
      sortRef.current = { ...data };
      applyFilter();
    },
    [applyFilter]
  );

  // For Searching via Brand
  const onSearch = useCallback(
    debounce(() => {
      filterDataRef.current = {
        ...filterDataRef.current,
        name: getValues("name"),
      };
      applyFilter();
    }, 700),
    [applyFilter, getValues]
  );

  // For Opening Advance Filter Modal
  const openAdvFilterModal = useCallback(() => setShowAdvFilterModal(true), []);

  // For Closing Advance Filer Modal
  const closeAdvFilterModal = useCallback(
    () => setShowAdvFilterModal(false),
    []
  );

  // To Handle Advance Filter
  const advFilterCb = (data) => {
    if (["applied", "reset"].indexOf(data.status) != -1) {
      paginationRef.current = { ...defaultPagination };
      filterDataRef.current = { ...filterDataRef.current, ...data.formData };
      applyFilter();
    }
    closeAdvFilterModal(false);
  };

  const viewCb = (data) => {
    setShowViewModal({ status: true, data: data });
  };

  const closeViewModal = () => {
    setShowViewModal({ status: false, data: {} });
  };

  const updateCb = (d) => {
    setShowBeneficiaryModal({ status: true, data: d });
  };

  const beneficiaryModalCb = (d) => {
    setShowBeneficiaryModal({ status: false, data: {} });
    if (d.status == "success") {
      init();
    }
  };

  return (
    <>
      <PageInfo
        title="Host To Host"
        breadcrumbs={listView.breadcrumb}
        navigate={navigate}
      />

      {/* Summary Card Overview */}
      <div className="row mb-2">
        {summaryCard.map((card, i) => (
          <div className="col-3" key={i}>
            <HostToHostSummaryCard
              label={card.label}
              color={card.color}
              filterParams={card.filterParams}
              img={card.img}
            />
          </div>
        ))}
      </div>

      {/* Filter  Block  */}
      <div className="row align-items-center">
        <div className="col-6">
          <div className="row">
            {/*  Search Input */}
            <div className="col-8 align-self-center">
              <TextInput
                name="name"
                type="text"
                register={register}
                callback={onSearch}
                placeholder="Search By  Virtual Acc No./ Remitter Acc No./ UTR No."
              />
            </div>

            {/* Filter Button */}
            <div className="col-auto">
              <button
                className="btn app-filter-btn"
                type="button"
                onClick={openAdvFilterModal}
              >
                <i className="bi bi-funnel"></i> FILTER
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Filter selected Label */}
      <div className="my-3">
        {filterLabels.length > 0 ? (
          <AppliedFilterLabel labels={filterLabels} />
        ) : null}
      </div>

      {/* PAGINATION SUMMARY */}
      <div className="mb-3">
        <PaginationSummary
          paginationConfig={paginationRef.current}
          loadingTotalRecords={loadingTotalRecords}
        />
      </div>

      {/* Table Component */}
      <HostToHostTable
        data={data}
        loading={loadingData}
        paginationConfig={paginationRef.current}
        paginationCb={paginationCb}
        loadingTotalRecords={loadingTotalRecords}
        sortCb={sortCb}
        sort={sortRef.current}
        viewCb={viewCb}
        updateCb={updateCb}
      />

      {/* Advance Filter Modal  */}
      <HostToHostAdvanceFilterModal
        callback={advFilterCb}
        show={showAdvFilterModal}
        formData={filterDataRef.current}
        partnerBankOptions={digitalPartnerOptionsRef.current}
      />

      <HostToHostViewModal
        show={showViewModal.status}
        callback={closeViewModal}
        dataId={showViewModal.data._id}
      />

      <BeneficiaryFormModal
        show={showBeneficiaryModal.status}
        callback={beneficiaryModalCb}
        details={showBeneficiaryModal.data}
      />
    </>
  );
};

export default HostToHostList;
