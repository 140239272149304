import totalImg from "../../assets/imgs/total.svg";
import activeImg from "../../assets/imgs/active.svg";
import inactiveImg from "../../assets/imgs/inactive.svg";

// LIST VIEW START
const listView = {
  breadcrumb: [
    {
      name: "Home",
      link: "/auth/init",
    },
    {
      name: "Upload Notifications",
    },
  ],
  // Breadcrumbs Ends Here

  summaryData: [
    {
      label: "Total Uploads",
      value: 0,
      loading: true,
      color: "primary",
      img: totalImg,
      key: "total",
    },
    {
      label: "Approved Count",
      value: 0,
      loading: true,
      color: "approved",
      img: activeImg,
      key: "approved",
    },
    {
      label: "Pending Count",
      value: 0,
      loading: true,
      img: activeImg,
      key: "pending",
    },
    {
      label: "Rejected Count",
      value: 0,
      loading: true,
      img: inactiveImg,
      key: "rejected",
    },
  ],
  //Summary Card Data Ends Here

  additionalTableDataConfig: [
    {
      key: "createdBy",
      api: "user",
      loadingKey: "userLoading",
      dataKey: "_createdBy",
      matchKey: "username",
      filter: (ids) => ({
        page: 1,
        count: ids.length,
        filter: { username: { $in: ids }, userType: "Employee" },
        select: "name,username",
      }),
    },
    {
      key: "approvedBy",
      api: "user",
      loadingKey: "userLoading",
      dataKey: "_approvedBy",
      matchKey: "username",
      filter: (ids) => ({
        page: 1,
        count: ids.length,
        filter: { username: { $in: ids }, userType: "Employee" },
        select: "name,username",
      }),
    },
  ],

  // Additional Table Data Ends Here

  pagination: {
    totalRecords: 0,
    rowsPerPage: 10,
    activePage: 1,
    startSlNo: 1,
    endSlNo: 10,
  },
  // Pagination Ends Here

  formLabels: {
    status: {
      label: "Status",
    },
    createdAt: {
      label: "Created on",
      type: "dateRange",
    },
    type: {
      label: "Type",
    },
    state: {
      label: "State",
    },
  },

  // Form Labels Ends Here

  filterFormData: {
    status: "",
  },

  defaultHeaders: [
    { label: "Sl No", width: "5%", isCentered: true },
    { label: "Batch ID" },
    { label: "Batch Name" },
    { label: "No Of RF's", isCentered: true },
    { label: "No of Records", isCentered: true },
    { label: "Download", isCentered: true },
    { label: "Mail", isCentered: true },
    { label: "Uploaded By", isCentered: true },
    { label: "Approved / Rejected By", isCentered: true },
    { label: "Status", isCentered: true },
    { label: "Actions", isCentered: true },
  ],
};
// LIST VIEW EDS HERE

export const filterView = {
  statusOptions: [
    { label: "All", value: "" },
    { label: "Approved", value: "APPROVED" },
    { label: "Rejected", value: "REJECTED" },
  ],

  // Form Labels Ends Here

  filterFormData: {
    status: "",
    createdAt: [],
  },
};

export default listView;
