import { AjaxService, DigitalPartnerService } from "@sk/services";
import {
  Alert,
  Amount,
  BusyLoader,
  DateFormatter,
  HighlightText,
  NoDataFound,
  PaginationBlock,
  Rbac,
  TableHeader,
  TableSkeletonLoader,
  Toaster,
} from "@sk/uis";
import classNames from "classnames";
import { memo, useState } from "react";

import listView from "../../constantService";

const rbac = {
  editButton: ["EditSmartMiniStoreConfig"],
};

const SmartMiniStoreTable = ({
  data,
  sort,
  sortCb,
  loading,
  paginationConfig,
  paginationCb,
  loadingTotalRecords,
  editCb,
  callback,
}) => {
  const [busyLoader, setBusyLoader] = useState({ msg: "", loading: false });

  const updateStatus = async (x) => {
    let status = x.status == "Active" ? "Inactive" : "Active";
    // asking confirm to submit
    let res = await Alert.confirm({
      title: "Please confirm",
      text: "Do you want to " + status + " Config",
      icon: "info",
      okText: "Yes",
      cancelText: "No",
    });

    if (!res.isConfirmed) {
      return;
    }
    setBusyLoader({
      msg: "",
      loading: true,
    });
    let r = await DigitalPartnerService.updateTierConfig(x._id, {
      ...x,
      status: status,
    });
    setBusyLoader({ msg: "", loading: false });

    if (r.statusCode == 200) {
      Toaster.success("Smart Mini Store Config Status Updated Successfully");
      callback({ status: "success" });
    } else {
      let error = AjaxService.parseError(r.resp);
      Toaster.error(error.msg[0]);
    }
  };

  return (
    <>
      <table className="table table-bordered bg-white">
        <TableHeader
          data={listView.defaultHeaders}
          sort={sort}
          sortCb={sortCb}
        />
        <tbody>
          {loading && (
            <TableSkeletonLoader
              rows={10}
              cols={listView.defaultHeaders?.length}
              height={40}
            />
          )}

          {!loading && !data.length ? (
            <tr>
              <td colSpan={listView.defaultHeaders.length}>
                <NoDataFound>Data Not Found </NoDataFound>
              </td>
            </tr>
          ) : null}

          {!loading &&
            data.map((x, index) => (
              <tr key={x._id} className="fs-val-md">
                {/* SL No */}
                <td className="text-center">
                  {paginationConfig.startSlNo + index}
                </td>

                {/* ID */}
                <td className="text-center">{x?._id}</td>

                {/* Tier Name */}
                <td className="text-center">{x.tierName}</td>

                {/* From */}
                <td className="text-center">
                  <Amount value={x?.from} />
                </td>

                {/* To */}
                <td className="text-center">
                  <Amount value={x?.to} />
                </td>

                {/* Achived value From */}
                <td className="text-center">
                  <Amount value={x?.achivedvalueFrom} />
                </td>

                {/* Achived value To */}
                <td className="text-center">
                  <Amount value={x?.achivedvalueTo} />
                </td>

                {/* SK Rent */}
                <td className="text-center">
                  <Amount value={x?.rent} />
                </td>

                {/* SK Share */}
                <td className="text-center">{x?.skShare}</td>

                {/*  Created At */}
                <td>
                  <DateFormatter date={x.createdAt} />
                  <div>by {x?._createdBy?.name || "N/A"}</div>
                </td>

                {/*  Modified On */}
                <td>
                  <DateFormatter date={x.lastUpdated} />
                  {/* <div>by:{x?._modifiedBy?.name || "N/A"}</div> */}
                </td>

                {/* Status */}
                <td className="text-center">
                  {<HighlightText status={x.status} />}
                </td>

                {/* Action */}
                <td className="text-center">
                  <Rbac roles={rbac.editButton}>
                    <button
                      className="btn btn-sm btn-outline-primary me-1 fs-val-xs"
                      onClick={() => {
                        editCb(x);
                      }}
                    >
                      Edit
                    </button>
                  </Rbac>

                  <Rbac roles={rbac.editButton}>
                    <button
                      className={classNames({
                        "btn btn-sm  me-1 fs-val-xs": true,
                        "btn-outline-success": x.status == "Inactive",
                        "btn-outline-danger": x.status == "Active",
                      })}
                      onClick={() => {
                        updateStatus(x);
                      }}
                    >
                      {x.status == "Active" ? "Inactive" : "Active"}
                    </button>
                  </Rbac>
                </td>
              </tr>
            ))}
        </tbody>
      </table>

      <PaginationBlock
        paginationConfig={paginationConfig}
        paginationCb={paginationCb}
        loadingTotalRecords={loadingTotalRecords}
      />

      <BusyLoader message={busyLoader.message} show={busyLoader.loading} />
    </>
  );
};

export default memo(SmartMiniStoreTable);
