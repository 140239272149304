import totalImg from "../../../assets/imgs/total.svg";
import activeImg from "../../../assets/imgs/active.svg";
import inactiveImg from "../../../assets/imgs/inactive.svg";

// LIST VIEW START
const listView = {
  breadcrumb: [
    {
      name: "Home",
      link: "/auth/init",
    },
    {
      name: "Bank Charge Config",
    },
  ],
  // Breadcrumbs Ends Here

  additionalTableDataConfig: [
    {
      key: "createdBy",
      api: "user",
      loadingKey: "userLoading",
      dataKey: "_createdBy",
      filter: (ids) => ({
        page: 1,
        count: ids.length,
        filter: { _id: { $in: ids } },
        select: "name",
      }),
    },
  ],

  // Additional Table Data Ends Here

  pagination: {
    totalRecords: 0,
    rowsPerPage: 100,
    activePage: 1,
    startSlNo: 1,
    endSlNo: 100,
  },
  // Pagination Ends Here

  formLabels: {
    bank: {
      label: "Bank",
      path: "[0].label",
    },
    createdAt: {
      label: "Created on",
      type: "dateRange",
    },
    status: {
      label: "Status",
    },
  },

  // Form Labels Ends Here

  filterFormData: {
    bank: "",
    createdAt: [],
    status: "",
  },

  defaultHeaders: [
    { label: "Sl No", width: "5%", isCentered: true },
    { label: "Bank ID", width: "5%" },
    { label: "Bank Name", width: "17%" },
    { label: "Charges" },
    { label: "Created on" },
    { label: "Status", priority: 10, isCentered: true },
    { label: "Actions", width: "12%", priority: 11, isCentered: true },
  ],

  defaultChargesHeaders: [
    { label: "Sl No", width: "10%", isCentered: true },
    { label: "From(Rs)" },
    { label: "To(Rs)" },
    { label: "Charge Type" },
    { label: "Charge Value" },
    { label: "Max Value(Rs)" },
  ],

  modalStatusOptions: [
    { label: "All", value: "" },
    { label: "Active", value: "Active" },
    { label: "Inactive", value: "Inactive" },
  ],
};
// LIST VIEW EDS HERE

export const manageView = {
  chargeTypeOptions: [
    { label: "Select", value: "" },
    { label: "Value", value: "value" },
    { label: "Percentage", value: "perc" },
  ],

  defaultFormState: {
    bank: "",
    isActive: false,
    slab: [],
  },

  defaultSlabForm: {
    from: null,
    to: null,
    value: null,
    type: "",
    maxValue: null,
  },

  defaultTableHeader: [
    { label: "Sl No", width: "10%", isCentered: true },
    { label: "From(Rs)", isCentered: true },
    { label: "To(Rs)", isCentered: true },
    { label: "Charge Type", isCentered: true },
    { label: "Charge Value", isCentered: true },
    { label: "Max Value(Rs)", isCentered: true },
    { label: "Actions", isCentered: true },
  ],
};
export default listView;
