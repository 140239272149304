import { yupResolver } from "@hookform/resolvers/yup";
import { TextareaInput } from "@sk/uis";
import { memo, useCallback } from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import * as yup from "yup";
const validationSchema = yup.object({
  remarks: yup.string().trim().required("Please provide remarks"),
});

const defaultFormValues = {
  remarks: "",
};

const CashDepositRemarkModal = ({ show, callback }) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: { ...defaultFormValues },
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = useCallback(
    (data) => {
      callback({ status: "submit", data });
    },
    [callback]
  );

  const triggerCloseModal = useCallback(() => {
    reset(defaultFormValues);
    callback({ status: "closed" });
  }, [callback, reset]);

  return (
    <Modal show={show} backdrop="static" onHide={triggerCloseModal}>
      <Modal.Header closeButton>
        <Modal.Title className="mb-0 pb-0">Remarks</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <TextareaInput
          name="remarks"
          label={`Enter remarks`}
          error={errors?.remarks?.message}
          register={register}
          isMandatory={true}
          note="Maximum 250 character allowed"
          placeholder={`Enter remarks here`}
        />
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn btn-sm btn-primary"
          onClick={handleSubmit(onSubmit)}
        >
          Submit
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default memo(CashDepositRemarkModal);
