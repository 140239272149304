import { AppTitle, TextareaInput, Toaster } from "@sk/uis";
import { memo, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";

const CreditDebitRemarksModal = ({ show, type, callback, creditNoteId }) => {
  const { register, reset, control, errors, setValue, getValues } = useForm({
    defaultValues: { remarks: "" },
  });

  const closeModal = () => {
    callback({ status: "closed" });
  };

  const onSubmit = () => {
    let remarks = getValues("remarks");
    let msg = "";
    if (!remarks?.trim().length) {
      msg = "Please Enter Remarks";
    } else if (remarks.length > 150) {
      msg = "Max Length Of Remarks is 150";
    }

    if (msg.length) {
      Toaster.error(msg);
      return;
    }
    callback({ status: "success", remarks: remarks });
  };

  useEffect(() => {
    if (show) {
      reset();
    }
  }, [reset, show]);

  return (
    <>
      <Modal show={show} backdrop={"static"} onHide={closeModal}>
        <Modal.Header closeButton className="bg-primary">
          {/* Modal Title */}
          <Modal.Title>
            <AppTitle title={type + " Remarks #" + creditNoteId} />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-4  border-top">
          <div>
            <form autoComplete="off">
              {/* Template Add flow */}
              <div className="row">
                {/* Amount */}
                <div className="col-12 pe-3">
                  <TextareaInput
                    name="remarks"
                    placeholder=""
                    error={errors?.remarks?.message}
                    label="Enter Remarks"
                    register={register}
                    isMandatory={true}
                    maxLength="100"
                    rows="3"
                    note="Maximum allowed Character 100"
                  />
                </div>
              </div>

              <div className="mt-3 mb-3 text-end">
                <button
                  className="btn  btn-primary py-2"
                  type="button"
                  onClick={onSubmit}
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default memo(CreditDebitRemarksModal);
