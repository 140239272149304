import totalImg from "../../assets/imgs/total.svg";
import activeImg from "../../assets/imgs/active.svg";
import inactiveImg from "../../assets/imgs/inactive.svg";
import notLinkedImg from "../../assets/imgs/not-linked.svg";

// LIST VIEW START
const listView = {
  breadcrumb: [
    {
      name: "Home",
      link: "/auth/init",
    },
    {
      name: "Host To Host",
    },
  ],
  // Breadcrumbs Ends Here

  summaryData: [
    {
      label: "Total",
      value: 0,
      loading: true,
      filter: {},
      color: "primary",
      img: totalImg,
    },
    {
      label: "Pending",
      value: 0,
      loading: true,
      filter: { status: "pending" },
      color: "warning",
      img: activeImg,
    },
    {
      label: "Pending Approval",
      value: 0,
      loading: true,
      filter: { status: "Pending Approval" },
      color: "warning",
      img: activeImg,
    },
    {
      label: "Approved",
      value: 0,
      loading: true,
      filter: { status: "Approved" },
      color: "success",
      img: activeImg,
    },
    {
      label: "Not Approved",
      value: 0,
      loading: true,
      filter: { status: "Not Approved" },
      color: "danger",
      img: inactiveImg,
    },
    {
      label: "Invalid",
      value: 0,
      loading: true,
      filter: { status: "Invalid" },
      color: "danger",
      img: inactiveImg,
    },
    {
      label: "Suspicious",
      value: 0,
      loading: true,
      filter: { status: "suspicious" },
      color: "warning",
      img: notLinkedImg,
    },
    {
      label: "Duplicate",
      value: 0,
      loading: true,
      filter: { status: "Duplicate" },
      color: "warning",
      img: notLinkedImg,
    },
  ],
  //Summary Card Data Ends Here

  additionalTableDataConfig: [
    {
      key: "franchiseId",
      api: "franchise",
      loadingKey: "modifiedLoading",
      dataKey: "_franchise",
      filter: (ids) => ({
        page: 1,
        count: ids.length,
        filter: { _id: { $in: ids } },
        select: "name,state,district,town,pincode",
      }),
    },
  ],

  // Additional Table Data Ends Here

  pagination: {
    totalRecords: 0,
    rowsPerPage: 10,
    activePage: 1,
    startSlNo: 1,
    endSlNo: 10,
  },
  // Pagination Ends Here

  formLabels: {
    status: {
      label: "Status",
    },
    createdAt: {
      label: "Created on",
      type: "dateRange",
    },
    type: {
      label: "Type",
    },
    franchise: {
      label: "Franchise",
      valuePath: "[0].value.name",
    },
    partner: {
      label: "Partner",
    },
  },

  // Form Labels Ends Here

  filterFormData: {
    status: "",
    franchise: [],
    type: "",
    partner: "",
    createdAt: [],
  },

  defaultHeaders: [
    { label: "Sl No", width: "5%", isCentered: true },
    { label: "Trans. ID" },
    { label: "Partner" },
    { label: "Type" },
    { label: "Mode" },
    { label: "Franchise" },
    { label: "UTR Number" },
    { label: "Amount", isCentered: true },
    { label: "Receipt ID", isCentered: true },
    { label: "Created On" },
    { label: "Status", isCentered: true },
    { label: "Actions", isCentered: true },
  ],
};
// LIST VIEW EDS HERE

export const filterView = {
  typeOptions: [
    { label: "All", value: "" },
    { label: "Dynamic UPI", value: "Dynamic UPI" },
    { label: "Host To Host", value: "HostToHost" },
  ],

  statusOptions: [
    { label: "All", value: "" },
    { label: "Approved", value: "Approved" },
    { label: "Cancelled", value: "Cancelled" },
    { label: "Closed", value: "Closed" },
    { label: "Created", value: "Created" },
    { label: "Duplicate", value: "Duplicate" },
    { label: "Invalid", value: "Invalid" },
    { label: "Pending", value: "pending" },
    { label: "Success", value: "SUCCESS" },
    { label: "Suspicious", value: "suspicious" },
    { label: "Suspense Account", value: "Suspense Account" },
  ],

  // Form Labels Ends Here

  filterFormData: {
    status: "",
    type: "",
    partner: "",
    franchise: [],
    createdAt: [],
  },
};

export const formView = {
  breadcrumb: [
    {
      name: "Home",
      link: "/auth/init",
    },
    {
      name: "Notification Templates",
      link: "/utility/notifications/list",
    },
    {
      name: "Add Notification Templates",
    },
  ],

  // Breadcrumbs Ends Here
  defaultFormValues: {
    businessEntity: "",
    subject: "",
    body: "",
    type: "",
    to: [],
    mode: "",
    isActive: true,
    emailTemplateType: "",
    languages: [],
  },
};

export default listView;
