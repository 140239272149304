import {
  PageInfo,
  SummaryCard,
  TextInput,
  AppliedFilterLabel,
  PaginationSummary,
  EntitySearchInput,
  Rbac,
} from "@sk/uis";

import { useCallback, useEffect, useRef, useState } from "react";

import { useForm, Controller } from "react-hook-form";

import { useNavigate } from "react-router-dom";

import merge from "lodash/merge";

import debounce from "lodash/debounce";

import set from "date-fns/set";

import produce from "immer";

import { AccountService, CommonService } from "@sk/services";

import { useAttachAdditionalData } from "@sk/hooks";

import listView from "../constantService";

import Table from "./components/Table";

import AdvFilterModal from "./modal/AdvFilterModal";

import ManageReceiptModal from "./modal/ManageReceiptModal";

const defaultSummaryCard = listView.summaryData;

const defaultPaginationOpt = listView.pagination;

const breadcrumbs = listView.breadcrumb;

const additionalTableDataConfig = listView.additionalTableDataConfig;

const defaultFilterData = listView.filterFormData;

const defaultSortOpt = { key: "lastUpdated", value: "desc" };

const getData = async (params) => {
  const r = await AccountService.getReceiptList(params);
  return Array.isArray(r.resp)
    ? r.resp.map((x) => {
        x.franchiseLoading = true;
        x.createdLoading = true;
        x.modifiedLoading = true;
        return x;
      })
    : [];
};

const getCount = async (params) => {
  delete params.count;
  delete params.page;
  try {
    const r = await AccountService.getReceiptListCount(params);
    return { count: r.statusCode == 200 && r.resp ? r.resp : 0 };
  } catch (error) {
    return new Promise((resolve) => resolve({ count: 0 }));
  }
};

const prepareFilterParams = (pagination = {}, filter = {}, sort = {}) => {
  let p = {
    page: pagination.activePage,
    count: pagination.rowsPerPage,
    filter: {},
  };
  let d = filter || {};

  if (d?.status) {
    p.filter.status = d.status;
  }
  const search = d?.search?.trim();
  const searchRegex = { $regex: search, $options: "gi" };
  if (search) {
    p.filter._id = searchRegex;
  }
  if (filter?.createdAt && filter?.createdAt?.length == 2) {
    p.filter.dateSubmitted = {
      $gte: set(filter.createdAt[0], {
        hours: 0,
        minutes: 0,
        seconds: 0,
      }),
      $lte: set(filter.createdAt[1], {
        hours: 23,
        minutes: 59,
        seconds: 59,
      }),
    };
  }
  if (d?.franchiseId) {
    p.filter.franchiseId = d.franchiseId;
  }

  if (d?.bank) {
    p.filter.bank = d.bank;
  }
  if (d?.paymentType) {
    p.filter.paymentType = { $in: [d.paymentType] };
  }
  if (d?.amount) {
    p.filter.amount = { $lte: d.amount };
  }

  if (d?.state) {
    p.state = [d.state];
  }

  if (sort?.key) {
    p.sort = {
      [sort.key]: sort.value == "desc" ? -1 : 1,
    };
  }

  return p;
};

const rbac = {
  createButton: ["AddReceipt"],
  processingButton: ["ReceiptProcessing"],
};

const ReceiptList = () => {
  const [setAdditionalData, attachAllData] = useAttachAdditionalData();

  const navigate = useNavigate();

  const { register, control, getValues } = useForm();

  const [data, setData] = useState([]);

  const [filterLabels, setFilterLabels] = useState([]);

  const [loadingData, setLoadingData] = useState(true);

  const [loadingTotalRecords, setLoadingTotalRecords] = useState(true);

  const [showAdvFilterModal, setShowAdvFilterModal] = useState(false);

  const [showManageReceiptModal, setShowMangeReceiptModal] = useState({
    status: false,
    mode: "add",
    id: "",
  });

  const [summaryCard, setSummaryCard] = useState([...defaultSummaryCard]);

  const paginationRef = useRef({ ...defaultPaginationOpt });

  const filterRef = useRef({ ...defaultFilterData });

  const sortRef = useRef({ ...defaultSortOpt });

  const advFilterRef = useRef({ ...defaultFilterData });

  useEffect(() => {
    init();
  }, [init]);

  const init = useCallback(() => {
    applyFilter();
  }, [applyFilter]);

  const applyFilter = useCallback(() => {
    paginationRef.current = { ...defaultPaginationOpt };
    loadSummary();
    loadCount();
    loadList();
    prepareFilterLabels();
  }, [loadCount, loadList, loadSummary, prepareFilterLabels]);

  const getFilterParams = useCallback(() => {
    return prepareFilterParams(
      paginationRef.current,
      filterRef.current,
      sortRef.current
    );
  }, []);

  const loadSummary = useCallback(async () => {
    setSummaryCard(
      produce((draft) => {
        draft.forEach((x) => {
          x.loading = true;
        });
      })
    );

    const filterParams = getFilterParams();
    delete filterParams.page;
    delete filterParams.count;

    let promises = [];
    summaryCard.forEach((e) => {
      promises.push(getCount(merge({}, filterParams, { filter: e.filter })));
    });

    const r = await Promise.all(promises);
    setSummaryCard(
      produce((draft) => {
        draft.forEach((x, k) => {
          x.loading = false;
          x.value = r[k].count || 0;
        });
      })
    );
  }, [getFilterParams, summaryCard]);

  const loadList = useCallback(async () => {
    // for list
    setLoadingData(true);
    const d = await getData(getFilterParams());

    let tmp = [];
    setAdditionalData(d, additionalTableDataConfig, (x) => {
      tmp.push(x);
      if (tmp.length == additionalTableDataConfig.length) {
        setData([...attachAllData(d, tmp)]);
      }
    });

    setData(d);
    setLoadingData(false);
  }, [attachAllData, getFilterParams, setAdditionalData]);

  const loadCount = useCallback(async () => {
    setLoadingTotalRecords(true);
    const p = { ...getFilterParams() };
    delete p.count;
    delete p.page;
    const c = await getCount(p);

    paginationRef.current.totalRecords = c.count;
    setLoadingTotalRecords(false);
  }, [getFilterParams]);

  const onSearch = useCallback(
    debounce(() => {
      filterRef.current = { ...filterRef.current, search: getValues("search") };
      applyFilter();
    }, 500),
    [applyFilter, getValues]
  );

  // For Applying Filter Params
  const prepareFilterLabels = useCallback(() => {
    const v = { ...(filterRef.current || {}) };
    delete v.search;
    delete v.franchiseId;
    const l = CommonService.prepareAppliedFilterLabels(listView.formLabels, v);
    setFilterLabels(l);
  }, []);

  const paginationCb = useCallback(
    (data) => {
      paginationRef.current.startSlNo = data.startSlNo;
      paginationRef.current.endSlNo = data.endSlNo;
      paginationRef.current.activePage = data.activePage;
      loadList();
    },
    [loadList]
  );

  const sortCb = useCallback(
    (data) => {
      sortRef.current = { ...data };
      applyFilter();
    },
    [applyFilter]
  );

  const onFranchiseChange = useCallback(
    (chngFn) => (val) => {
      chngFn(val);

      filterRef.current = {
        ...filterRef.current,
        franchiseId: val?.[0]?.value?._id,
      };

      applyFilter();
    },
    [applyFilter]
  );

  const advFilterCb = useCallback(
    (payload) => {
      if (payload.action == "submitted") {
        advFilterRef.current = { ...payload.formData };
        filterRef.current = { ...filterRef.current, ...advFilterRef.current };
        applyFilter();
      }

      if (payload.action == "reset") {
        advFilterRef.current = { ...defaultFilterData };
        filterRef.current = { ...filterRef.current, ...defaultFilterData };
        applyFilter();
      }

      setShowAdvFilterModal(false);
    },
    [applyFilter]
  );

  const openAdvanceFilterModal = useCallback(() => {
    setShowAdvFilterModal(true);
  }, []);

  const openManageReceiptModal = useCallback((id = "") => {
    setShowMangeReceiptModal({
      status: true,
      mode: id ? "edit" : "add",
      id: id || "",
    });
  }, []);

  const manageReceiptModalCb = useCallback(
    (payload) => {
      setShowMangeReceiptModal({
        status: false,
        mode: "add",
        id: "",
      });
      if (payload.action == "submitted") {
        init();
      }
    },
    [init]
  );

  const viewReceiptTableCb = useCallback(
    (d) => {
      if (d.status == "success") {
        init();
      }
    },
    [init]
  );

  return (
    <>
      <PageInfo
        title="Manage Receipt "
        navigate={navigate}
        breadcrumbs={breadcrumbs}
      />

      <div className="row align-self-center mb-2">
        {summaryCard.map((e, k) => (
          <div className="col-2" key={k}>
            <SummaryCard
              value={e.value}
              title={e.label}
              loading={e.loading}
              valueColor={e.color}
              template={2}
              img={e.img}
            />
          </div>
        ))}
      </div>

      <div className="row">
        <div className="col-3">
          <TextInput
            register={register}
            placeholder="Enter Receipt Id"
            isMandatory={false}
            name="search"
            callback={onSearch}
          />
        </div>
        <div className="col-3">
          <Controller
            control={control}
            name="franchise"
            key="franchise"
            render={({ field: { value, onChange } }) => (
              <EntitySearchInput
                name="franchise"
                type="franchise"
                placeholder="Search by Franchise Name /ID"
                value={value}
                uid="franchise"
                callback={onFranchiseChange(onChange)}
              />
            )}
          />
        </div>

        <div className="col-2">
          <button
            className="btn app-filter-btn"
            type="button"
            onClick={openAdvanceFilterModal}
          >
            <i className="bi bi-funnel"></i> FILTER
          </button>
        </div>
        <div className="col-4 text-end">
          <Rbac roles={rbac.createButton}>
            <button
              className="btn btn-primary fs-val-md"
              onClick={() => openManageReceiptModal()}
            >
              Create Receipt
            </button>
          </Rbac>
        </div>
      </div>

      {/* Filter selected Label */}
      <div className="my-3">
        {filterLabels.length > 0 ? (
          <AppliedFilterLabel labels={filterLabels} />
        ) : null}
      </div>

      <PaginationSummary
        paginationConfig={paginationRef.current}
        loadingTotalRecords={loadingTotalRecords}
      />
      <Table
        data={data}
        loading={loadingData}
        paginationConfig={paginationRef.current}
        paginationCb={paginationCb}
        sort={sortRef.current}
        sortCb={sortCb}
        loadingTotalRecords={loadingTotalRecords}
        callback={viewReceiptTableCb}
      />

      <AdvFilterModal
        show={showAdvFilterModal}
        callback={advFilterCb}
        filterData={advFilterRef.current}
      />

      <ManageReceiptModal
        show={showManageReceiptModal.status}
        callback={manageReceiptModalCb}
        mode={showManageReceiptModal.mode}
        id={showManageReceiptModal.id}
      />
    </>
  );
};

export default ReceiptList;
