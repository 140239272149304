/* eslint-disable jsx-a11y/label-has-associated-control */
import { yupResolver } from "@hookform/resolvers/yup";
import {
  AccountService,
  AjaxService,
  CommonService,
  UtilityService,
} from "@sk/services";
import {
  Alert,
  Amount,
  AppCard,
  AppTitle,
  AutoCompleteInput,
  DatePickerInput,
  EntitySearchInput,
  InputErrorMsg,
  KeyVal,
  SelectInput,
  Spinner,
  TextInput,
  Toaster,
} from "@sk/uis";

import { memo, useCallback, useEffect, useState } from "react";

import { Offcanvas } from "react-bootstrap";

import { Controller, useForm, useWatch } from "react-hook-form";

import * as yup from "yup";

import debounce from "lodash/debounce";

const manageReceiptValidationSchema = yup.object({
  franchise: yup.array().min(1, "Please add franchise"),

  paymentType: yup.string().required("Please select Payment Mode"),

  amount: yup
    .number()
    .nullable()
    .transform((v) => (isNaN(v) ? null : v))
    .max(1000000)
    .required("Amount is required"),
  walletType: yup.string().required("Please select wallet type"),

  bank: yup.array().min(1, "Please add SK Bank"),

  fromBank: yup.array().when("paymentType", {
    is: (paymentType) => {
      return paymentType && paymentType != "Cash";
    },
    then: (schema) =>
      schema
        .label("From Bank")
        .min(1, "From Bank is Required")
        .required("From Bank is Required"),
  }),
  utr: yup.string().when("paymentType", {
    is: (paymentType) =>
      paymentType && paymentType != "Cash" && paymentType != "Cheque",
    then: (schema) =>
      schema.label("UTR Number").trim().required("UTR Number is required"),
  }),

  chequeNo: yup
    .number()
    .nullable()
    .transform((v) => (isNaN(v) ? null : v))
    .when("paymentType", {
      is: (paymentType) => paymentType == "Cheque",
      then: (schema) =>
        schema.label("Cheque Number").required("Cheque Number is Required"),
    }),

  chequeDate: yup.date().when("paymentType", {
    is: (paymentType) => paymentType == "Cheque",
    then: (schema) =>
      schema.label("Cheque Date").required("Cheque Date is Required"),
  }),

  depositDate: yup.date().when("paymentType", {
    is: (paymentType) => paymentType == "Cheque",
    then: (schema) =>
      schema
        .label("Deposit Date")
        .min(yup.ref("chequeDate"), "Must be greater or equal to cheque date")
        .required("Deposit Date is Required"),
  }),
});

const franchiseSearchFilter = {
  select: "name,district,isSKCreditWalletEnabled",
};

// default From Values
const defaultFromData = {
  franchise: [],
  paymentType: "",
  amount: "",
  walletType: "",
  bank: [],
};

// Canvas Style
const style = {
  offCanvasHeaderStyle: {
    backgroundColor: "#e4edff",
  },
  offCanvasStyle: {
    width: "45%",
  },
};

const paymentOptions = [
  { label: "Select", value: "" },
  { label: "Cash", value: "Cash" },
  { label: "Cheque", value: "Cheque" },
  { label: "IMPS", value: "IMPS" },
  { label: "NEFT", value: "NEFT" },
  { label: "RTGS", value: "RTGS" },
];

let checkDateConfig = {
  minDate: new Date(),
  enableTime: true,
  dateFormat: "d M Y G:i K",
  defaultDate: new Date(),
};

let depositDateConfig = {
  enableTime: true,
  dateFormat: "d M Y G:i K",
};

const ManageReceiptModal = ({ show, callback, mode, id }) => {
  // To show Spinner on Submit Button While Submitting Form
  const [submitting, setSubmitting] = useState(false);

  const [franData, setFranData] = useState({});

  const [walletTypeOptions, setWalletTypeOptions] = useState([
    { label: "No Option Found", value: "" },
  ]);

  const [franBalance, setFranBalance] = useState({ loading: false, value: 0 });

  // React Hook Form
  const {
    reset,
    setValue,
    control,
    register,
    getValues,
    handleSubmit,
    trigger,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(manageReceiptValidationSchema),
    defaultValues: { ...defaultFromData },
  });

  const paymentType = useWatch({
    control,
    name: "paymentType",
  });

  const bank = useWatch({ control, name: "bank" });

  useEffect(() => {
    if (show) {
      reset();
    }
  }, [mode, id, show, reset]);

  // Preparing Payload While Hitting API
  const preparePayload = (data) => {
    let p = {
      amount: data.amount,
      paymentType: data.paymentType,
      walletType: data.walletType,
      franchiseId: data.franchise[0].value._id,
      franchiseName: data.franchise[0].value.name,
      bank: data.bank[0].value.name,
      acName: data.bank[0].value.acName,
      acNumber: data.bank[0].value.acNumber,
      ifscCode: data.bank[0].value.ifsc,
      accountNo: data.bank[0].value.acNumber + "",
    };

    if (data.utr) {
      p.utr = data.utr;
    }

    if (data.fromBank) {
      p.fromBank = data.fromBank[0].value.name;
    }
    if (data.chequeNo) {
      p.chequeNo = data.chequeNo;
      p.chequeDate = data.chequeDate;
      p.depositDate = data.depositDate;
    }

    return p;
  };

  // for resetting the form data and its dependent state
  const resetFormData = () => {
    // reset();
  };

  const onSubmit = async (data) => {
    let res = await Alert.confirm({
      title: "Please confirm",
      text: "Do you want to proceed?",
      icon: "info",
      okText: "Yes",
      cancelText: "No",
    });

    if (!res.isConfirmed) {
      return;
    }

    const p = preparePayload(data);

    setSubmitting(true);
    let r = {};

    if (mode == "add") {
      r = await AccountService.createReceipt(p);
    }

    setSubmitting(false);

    if (r.statusCode != 200) {
      let error = AjaxService.parseError(r.resp);
      Toaster.error(error.msg[0] || "Failed to update");
      return;
    }

    resetFormData();

    Toaster.success(`${mode == "add" ? "Added" : "Updated"} Successfully`);

    triggerCloseModal("submitted");
  };

  // To Close Modal
  const triggerCloseModal = (action = "close") => {
    callback({ action });
  };

  const onFranchiseSelect = useCallback(
    (chngFn) => {
      return (e) => {
        chngFn(e);

        if (e.length) {
          let fid = e.length ? e[0].value._id : "";

          setFranData(e[0].value);

          loadFranchiseBalance(fid);

          setWalletTypeOptions([]);

          loadWalletOptions(fid);
        } else {
          setFranData({});
        }
      };
    },
    [loadFranchiseBalance, loadWalletOptions]
  );

  const loadFranchiseBalance = useCallback(async (fid) => {
    setFranBalance({ loading: true, value: 0 });
    let p = { page: 1, count: 1, filter: { owner: fid } };
    const r = await AccountService.getFranBalance(p);
    const d = Array.isArray(r.resp) ? (r.resp.length ? r.resp[0] : {}) : {};
    setFranBalance({ loading: false, value: d.actualBalance || 0 });
  }, []);

  const loadWalletOptions = useCallback(async (fid) => {
    let p = { page: 1, count: 50, filter: { owner: fid } };
    const r = await AccountService.getFranBalance(p);
    const d = Array.isArray(r.resp) ? r.resp : [];
    let opt = [{ label: "Select", value: "" }];
    d.forEach((e) => {
      if (e.accountSubType == "Credit") {
        opt.push({ label: "Credit Wallet", value: "creditWallet" });
      } else if (e.accountSubType == undefined) {
        opt.push({ label: "SK Advance Wallet", value: "skWallet" });
      }
    });
    setWalletTypeOptions(opt);
  }, []);

  const onAmountChange = useCallback(
    debounce(() => {
      let val = getValues("amount") * 1;
      val = CommonService.roundedByDecimalPlace(val, 3);

      if (val <= 0) {
        val = "";
      }

      setValue("amount", val);
    }, 1000),
    [setValue, getValues]
  );

  const onSkBankSearch = (val, callback) => {
    let params = {
      page: 1,
      count: 10,
      filter: {
        skBank: true,
      },
    };
    onBankSearch(val, callback, params);
  };

  const onFromBankSearch = (val, callback) => {
    let params = {
      page: 1,
      count: 10,
      filter: {},
    };
    onBankSearch(val, callback, params);
  };

  const onBankSearch = async (val, callback, params) => {
    const search = val.trim();
    const searchString = CommonService.sanitizeRegex(search);
    const searchRegex = { $regex: searchString, $options: "gi" };

    if (search) {
      params.filter.name = searchRegex;
    }

    const r = await UtilityService.getBankList(params);

    callback(
      (r.resp || []).map((x) => ({
        label: x.name + " (" + x._id + ")",
        value: x,
      }))
    );
  };

  const dateChangeCb = (chngFn, key) => {
    return (val) => {
      if (!val.length) {
        return;
      }
      chngFn(val);

      setValue(key, val[0]);
      if (key == "chequeDate") {
        depositDateConfig = {
          ...depositDateConfig,
          minDate: val[0],
        };
      }
      trigger("depositDate");
    };
  };

  const onChequeNumberChange = useCallback(() => {
    let val = getValues("chequeNumber");
    val = CommonService.roundedByDecimalPlace(val, 0);
    if (val <= 0) {
      val = "";
    }
    setValue("chequeNumber", val);
  }, [getValues, setValue]);

  return (
    <>
      <Offcanvas
        show={show}
        onHide={triggerCloseModal}
        backdrop="static"
        keyboard={false}
        placement="end"
        style={style.offCanvasStyle}
      >
        <Offcanvas.Header
          closeButton
          closeVariant="white"
          style={style.offCanvasHeaderStyle}
        >
          {/* Modal Title */}
          <AppTitle
            title={
              mode === "edit" ? "Edit Receipt" + `  #${id}` : "Add Receipt"
            }
            className="fs-val-lg text-dark px-2"
          />
        </Offcanvas.Header>
        <Offcanvas.Body>
          <>
            {/* Basic details */}
            <div className="row p-2 py-4">
              {/* Franchise */}
              <div className="col-12 mb-3">
                <Controller
                  control={control}
                  name="franchise"
                  render={({ field: { onChange, value } }) => (
                    <EntitySearchInput
                      type={"franchise"}
                      label="Search for Franchise"
                      placeholder="Search By Name/ID"
                      isMandatory={false}
                      value={value}
                      callback={onFranchiseSelect(onChange)}
                      uid="franchise-search"
                      isMultiple={false}
                      error={errors?.franchise?.message}
                      filterParams={franchiseSearchFilter}
                    />
                  )}
                />
                {franData?._id ? (
                  <div className="my-4">
                    <AppCard title="Franchise Balance Info">
                      <div className="row">
                        <div className="col-12">
                          <KeyVal label="Balance">
                            {franBalance?.loading ? (
                              <Spinner type="dots" />
                            ) : (
                              <span className="text-primary">
                                <Amount
                                  value={franBalance.value}
                                  decimalPlace={2}
                                />
                              </span>
                            )}
                          </KeyVal>
                        </div>

                        <div className="col-12">
                          <KeyVal label="Location">{franData?.district}</KeyVal>
                        </div>
                      </div>
                    </AppCard>
                  </div>
                ) : null}
              </div>

              {/* Payment Mode */}
              <div className="col-4 mb-3">
                <SelectInput
                  register={register}
                  name="paymentType"
                  label="Payment Mode"
                  error={errors?.paymentType?.message}
                  options={paymentOptions}
                />
              </div>

              {/* Amount */}
              <div className="col-4">
                <TextInput
                  type="number"
                  register={register}
                  label="Amount"
                  name="amount"
                  error={errors?.amount?.message}
                  placeholder="Please Enter amount here"
                  callback={onAmountChange}
                />
              </div>

              <div className="col-4">
                <SelectInput
                  register={register}
                  name="walletType"
                  label="Wallet"
                  error={errors?.walletType?.message}
                  disabled={!franData?._id}
                  options={walletTypeOptions}
                />
              </div>
            </div>

            {/* Bank Details */}
            <div className="p-4 bg-light border-bottom">
              <div className="row">
                <div className="col-12">
                  <div className="fw-semibold fs-val-lg mb-3">Bank Details</div>
                </div>
                {/* From Bank  */}
                {paymentType && paymentType != "Cash" ? (
                  <div className="col-12 mb-4">
                    <Controller
                      control={control}
                      name="fromBank"
                      render={({ field: { onChange, value } }) => (
                        <AutoCompleteInput
                          label="Search For From Bank "
                          placeholder="Search By Name /ID "
                          value={value}
                          onSearch={onFromBankSearch}
                          callback={onChange}
                          uid="Bank-search"
                          error={errors?.fromBank?.message}
                          isMultiple={false}
                          emptyLabel="No Bank Found"
                          gap={0}
                          isMandatory={true}
                        />
                      )}
                    />
                  </div>
                ) : null}

                {/* SK Bank */}
                <div className="col-12 mb-4">
                  <Controller
                    control={control}
                    name="bank"
                    render={({ field: { onChange, value } }) => (
                      <AutoCompleteInput
                        label="Search For Bank "
                        placeholder="Search By Name /ID "
                        value={value}
                        onSearch={onSkBankSearch}
                        callback={onChange}
                        uid="Bank-search"
                        isMultiple={false}
                        error={errors?.bank?.message}
                        emptyLabel="No Bank Found"
                        gap={0}
                        isMandatory={true}
                      />
                    )}
                  />
                  {paymentType && paymentType == "Cash" && bank.length ? (
                    <div className="my-4">
                      <AppCard title="Bank Details">
                        <div className="row">
                          <div className="col-12">
                            <KeyVal label="IFSC">
                              <span className="text-primary fs-val-sm">
                                {bank?.[0]?.value?.ifsc}
                              </span>
                            </KeyVal>
                            <KeyVal label="A/C Number">
                              <span className="text-primary fs-val-sm">
                                {bank?.[0]?.value?.acNumber}
                              </span>
                            </KeyVal>
                            <KeyVal label="A/C Name">
                              <span className="text-primary fs-val-sm">
                                {bank?.[0]?.value?.acName}
                              </span>
                            </KeyVal>
                          </div>
                        </div>
                      </AppCard>
                    </div>
                  ) : null}
                </div>

                {paymentType &&
                ["Cheque", "Cash"].indexOf(paymentType) == -1 ? (
                  <TextInput
                    type="text"
                    register={register}
                    label="UTR Number"
                    name="utr"
                    error={errors?.utr?.message}
                    placeholder="Reference Number"
                  />
                ) : null}
              </div>
            </div>

            {/* Cheque Details */}
            {paymentType == "Cheque" ? (
              <div className="p-4 bg-white">
                <div className="row">
                  <div className="col-12">
                    <div className="fw-semibold fs-val-lg mb-3">
                      Cheque Details
                    </div>
                  </div>

                  {/* Cheque Number */}
                  <div className="col-4">
                    <TextInput
                      type="number"
                      register={register}
                      label="Cheque Number"
                      name="chequeNo"
                      error={errors?.chequeNo?.message}
                      callback={onChequeNumberChange}
                      placeholder="Cheque Number"
                    />
                  </div>

                  {/*  Cheque Date   */}
                  <div className="col-4 mb-2">
                    <label className=" mb-0 fs-val-md">
                      Cheque Date
                      <span className="text-danger">*</span>
                    </label>
                    <Controller
                      control={control}
                      name="chequeDate"
                      render={({ field: { onChange, value } }) => {
                        return (
                          <DatePickerInput
                            placeholder="Choose"
                            value={[value]}
                            inpChange={dateChangeCb(onChange, "chequeDate")}
                            config={checkDateConfig}
                          />
                        );
                      }}
                    />
                    <InputErrorMsg msg={errors?.chequeDate?.message} />
                  </div>

                  {/* Deposit Date */}
                  <div className="col-4 mb-2">
                    <label className="mb-0 fs-val-md">
                      Deposit Date
                      <span className="text-danger">*</span>
                    </label>
                    <Controller
                      control={control}
                      name="depositDate"
                      render={({ field: { onChange, value } }) => (
                        <DatePickerInput
                          placeholder="Choose"
                          value={[value]}
                          inpChange={dateChangeCb(onChange, "depositDate")}
                          config={depositDateConfig}
                        />
                      )}
                    />
                    <InputErrorMsg msg={errors?.depositDate?.message} />
                  </div>
                </div>
              </div>
            ) : null}
          </>
        </Offcanvas.Body>
        <Offcanvas.Header>
          <div className="col-auto ms-auto">
            <button
              className="btn btn-primary fs-val-md"
              disabled={submitting}
              onClick={handleSubmit(onSubmit)}
            >
              Submit {submitting ? <Spinner isSmall={true} /> : null}
            </button>
          </div>
        </Offcanvas.Header>
      </Offcanvas>
    </>
  );
};

export default memo(ManageReceiptModal);
