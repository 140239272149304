import { useCallback, useEffect, useState } from "react";

import { UtilityService, CommonService } from "@sk/services";

import { Offcanvas } from "react-bootstrap";

import { useForm, Controller } from "react-hook-form";

import listView from "../../constantService";

import debounce from "lodash/debounce";

import { SelectInput, DatePickerInput, TextInput } from "@sk/uis";

const paymentOptions = [
  { label: "All", value: "" },
  { label: "Cash", value: "Cash" },
  { label: "Cheque", value: "Cheque" },
  { label: "IMPS", value: "IMPS" },
  { label: "PG", value: "PG" },
  { label: "NEFT", value: "NEFT" },
  { label: "RTGS", value: "RTGS" },
  { label: "Wallet", value: "Wallet" },
];

const statusOptions = [
  { label: "All", value: "" },
  { label: "Accounted", value: "Accounted" },
  { label: "Created", value: "Created" },
  { label: "Pending Approval", value: "Pending Approval" },
  { label: "Rejected", value: "Rejected" },
  { label: "Reversed", value: "Reversed" },
];

const createdOnDateConfig = { mode: "range", maxDate: new Date() };

// Canvas Style
const style = {
  offCanvasHeaderStyle: {
    backgroundColor: "#e4edff",
  },
  offCanvasStyle: {
    width: "25%",
  },
};

const defaultFormData = { ...listView.filterFormData };

const AdvFilterModal = ({ show = false, callback, filterData }) => {
  const [banks, setBanks] = useState([{ label: "No Bank Found", value: "" }]);

  const [states, setStates] = useState([
    { label: "No States Found", value: "" },
  ]);

  const { handleSubmit, control, register, setValue, getValues, reset } =
    useForm({
      defaultValues: { ...defaultFormData },
    });

  useEffect(() => {
    if (show) {
      reset();
      Object.keys(filterData || {}).forEach((x) => {
        setValue(x, filterData[x]);
      });
    }
  }, [filterData, getValues, reset, setValue, show]);

  useEffect(() => {
    // to load bank list for bank select input
    const loadBankDetails = async () => {
      const r = await UtilityService.getBankList({
        page: 1,
        count: 100,
        filter: { skBank: true },
      });

      const d = Array.isArray(r.resp)
        ? r.resp.map((x) => {
            return { label: x.name, value: x.name };
          })
        : [];

      if (d.length) {
        setBanks([{ label: "Select Bank", value: "" }, ...d]);
      }
    };

    // to load state list for state select input
    const loadStateDetails = async () => {
      const r = await UtilityService.getStateList();

      const d = Array.isArray(r.resp)
        ? r.resp.map((x) => {
            return { label: x.name, value: x.name };
          })
        : [];

      if (d.length) {
        setStates([{ label: "Select State", value: "" }, ...d]);
      }
    };

    loadBankDetails();

    loadStateDetails();
  }, []);

  const triggerCloseModal = useCallback(() => {
    callback({ action: "close" });
  }, [callback]);

  const onSubmit = useCallback(
    (data) => {
      callback({ action: "submitted", formData: data });
    },
    [callback]
  );

  const resetForm = useCallback(() => {
    reset({ ...defaultFormData });
    callback({ action: "reset" });
  }, [callback, reset]);

  const onAmountChange = useCallback(
    debounce(() => {
      let val = getValues("amount") * 1;
      val = CommonService.roundedByDecimalPlace(val, 3);

      if (val <= 0) {
        val = "";
      }

      setValue("amount", val);
    }, 1000),
    [setValue, getValues]
  );

  return (
    <>
      <Offcanvas
        show={show}
        onHide={triggerCloseModal}
        placement="end"
        style={style.offCanvasStyle}
      >
        <Offcanvas.Header
          closeButton
          closeVariant="white"
          style={style.offCanvasHeaderStyle}
        >
          <Offcanvas.Title>
            <div className="page-title"> Advance Filter </div>
          </Offcanvas.Title>
        </Offcanvas.Header>

        <Offcanvas.Body className="p-4  border-top">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-4">
              <SelectInput
                register={register}
                name="state"
                label="States"
                options={states}
              />
            </div>

            <div className="mb-4">
              <SelectInput
                register={register}
                name="bank"
                label="Banks"
                options={banks}
              />
            </div>

            <div className="mb-4">
              <SelectInput
                register={register}
                name="paymentType"
                label="Payment Mode"
                options={paymentOptions}
              />
            </div>

            <div className="mb-4">
              <SelectInput
                register={register}
                name="status"
                label="Status"
                options={statusOptions}
              />
            </div>

            <div className="mb-4">
              <TextInput
                type="number"
                register={register}
                label="Amount"
                name="amount"
                placeholder="Please Enter amount here"
                callback={onAmountChange}
              />
            </div>

            {/* date range */}
            <div className="mb-4">
              <div className="fs-val-md mb-2">Created On</div>
              <Controller
                name="createdAt"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <DatePickerInput
                    placeholder="Choose"
                    value={value}
                    inpChange={onChange}
                    config={createdOnDateConfig}
                  />
                )}
              />
            </div>
          </form>
        </Offcanvas.Body>

        <Offcanvas.Header>
          {/* Action Buttons */}
          <div className="col-auto ms-auto">
            <button
              className="btn btn-warning me-3 px-4 fw-semibold"
              onClick={resetForm}
            >
              Reset
            </button>

            <button
              className="btn btn-primary px-4 fw-semibold"
              onClick={handleSubmit(onSubmit)}
            >
              Apply
            </button>
          </div>
        </Offcanvas.Header>
      </Offcanvas>
    </>
  );
};

export default AdvFilterModal;
