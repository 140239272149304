import { useAttachAdditionalData, useIsFirstRender } from "@sk/hooks";
import {
  AppTitle,
  DateFormatter,
  PaginationBlock,
  Spinner,
  TableHeader,
} from "@sk/uis";
import { memo, useCallback, useEffect, useRef, useState } from "react";

const tableHeaders = [
  {
    label: "Sl No",
    width: "10%",
    isCentered: true,
  },
  {
    label: "Updated On",
  },
  {
    label: "Updated By",
  },
  {
    label: "Key",
  },
  {
    label: "Old Value",
  },
  {
    label: "New Value",
  },
];

const attachAdditionalDataConfig = [
  {
    key: "createdBy",
    api: "user",
    loadingKey: "userLoading",
    dataKey: "_user",
    filter: (ids) => ({
      page: 1,
      count: ids.length,
      filter: { _id: { $in: ids } },
      select: "name",
    }),
  },
];

const defaultPagination = {
  totalRecords: 0,
  rowsPerPage: 10,
  activePage: 1,
  startSlNo: 1,
  endSlNo: 10,
};

export const AuditLogManageReceipt = ({ auditLogs = [] }) => {
  const isFirstRender = useIsFirstRender();

  const [setAdditionalData, attachAllData] = useAttachAdditionalData();

  const [data, setData] = useState(() =>
    auditLogs.slice(0, 10).map((x) => ({ ...x, userLoading: true }))
  );

  const paginationRef = useRef({
    ...defaultPagination,
    totalRecords: auditLogs.length,
  });

  useEffect(() => {
    if (isFirstRender) {
      let tmp = [];
      setAdditionalData(data, attachAdditionalDataConfig, (x) => {
        tmp.push(x);
        if (tmp.length == attachAdditionalDataConfig.length) {
          setData([...attachAllData(data, tmp)]);
        }
      });
    }
  }, [attachAllData, data, isFirstRender, setAdditionalData]);

  const paginationCb = useCallback(
    (r) => {
      paginationRef.current.startSlNo = r.startSlNo;
      paginationRef.current.endSlNo = r.endSlNo;
      paginationRef.current.activePage = r.activePage;
      const d = auditLogs
        .slice(
          paginationRef.current.startSlNo - 1,
          paginationRef.current.endSlNo
        )
        .map((x) => ({ ...x, userLoading: true }));
      setData(d);
      let tmp = [];
      setAdditionalData(d, attachAdditionalDataConfig, (x) => {
        tmp.push(x);
        if (tmp.length == attachAdditionalDataConfig.length) {
          setData([...attachAllData(d, tmp)]);
        }
      });
    },
    [attachAllData, auditLogs, setAdditionalData]
  );

  return (
    <div className="p-4">
      <div className="pb-2">
        <AppTitle title="Audit Logs" />
      </div>
      <table className="table table-bordered">
        <TableHeader data={tableHeaders} />
        <tbody className="fs-val-sm">
          {data.length == 0 ? (
            <tr>
              <td colSpan={tableHeaders.length} className="text-center py-2">
                No Audit logs found
              </td>
            </tr>
          ) : null}
          {data.map((x, k) => (
            <tr key={x._id}>
              <td className="text-center">
                {paginationRef.current.startSlNo + k}
              </td>
              <td>
                <DateFormatter date={x.createdAt} />
              </td>
              <td>
                <div>
                  {x.userLoading ? (
                    <Spinner type="dots" />
                  ) : (
                    x._user?.name || x.loggedBy
                  )}
                </div>
              </td>
              <td>{x.audit[0].field}</td>
              <td>{x.audit[0].oldStatus || "N/A"}</td>
              <td>{x.audit[0].newStatus}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <PaginationBlock
        paginationConfig={paginationRef.current}
        paginationCb={paginationCb}
      />
    </div>
  );
};

export default memo(AuditLogManageReceipt);
