import { UtilityService } from "@sk/services";
import { DatePickerInput, SelectInput, EntitySearchInput } from "@sk/uis";
import { memo, useCallback, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import listView, { filterView } from "../../constantService";

const canvasStyle = { width: "30%" };

const defaultFormData = listView.filterFormData;

const createdOnDateConfig = { mode: "range" };

const CreditDebitAdvanceFilter = ({
  show,
  callback,
  formData = {},
  stateOptions,
}) => {
  const { register, handleSubmit, reset, control, setValue, getValues } =
    useForm({
      defaultValues: defaultFormData,
    });

  const [districtOptions, setDistrictOptions] = useState([]);

  const [townOptions, setTownOptions] = useState([]);

  const onSubmit = (data) => {
    callback({ formData: data, status: "applied" });
  };

  // To Reset Filter Form Data
  const resetFormData = () => {
    reset(defaultFormData);
    callback({ formData: defaultFormData, status: "reset" });
  };

  // To Close Filter Modal
  const closeModal = () => {
    callback({ status: "closed" });
  };

  const onStateChange = useCallback(
    async (val, type = "") => {
      if (type != "init") {
        setValue("district", "");
        setValue("town", "");
      }
      let r = val.length
        ? await UtilityService.getDistrictList(val)
        : { resp: [] };
      let opt = (r?.resp || []).map((e) => {
        return { label: e.name, value: e.name };
      });
      opt.unshift({ label: "All", value: "" });
      setDistrictOptions(opt);
      type != "init" ? setTownOptions([]) : null;
    },
    [setValue]
  );

  const onDistrictChange = useCallback(
    async (val) => {
      setValue("town", "");
      let r = val.length
        ? await UtilityService.getTownList(getValues("state"), val)
        : { resp: [] };
      let opt = (r.resp || []).map((e) => {
        return { label: e.name, value: e.name };
      });
      opt.unshift({ label: "All", value: "" });
      setTownOptions(opt);
    },
    [getValues, setValue]
  );
  return (
    <>
      <Offcanvas
        show={show}
        onHide={closeModal}
        placement="end"
        style={canvasStyle}
      >
        <Offcanvas.Header closeButton className="bg-light">
          {/* Modal Title */}
          <Offcanvas.Title>
            <div className="fs-val-lg"> Advance Filter </div>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="p-3  border-top">
          <>
            <div className="row">
              <div className="col-12 mb-4">
                <label className="fw-semibold mb-1 fs-val-md">Created On</label>
                <Controller
                  name="createdAt"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <DatePickerInput
                      placeholder="Choose"
                      value={value}
                      inpChange={onChange}
                      config={createdOnDateConfig}
                    />
                  )}
                />
              </div>

              {/* Franchise */}
              <div className="col-12">
                <Controller
                  control={control}
                  name="franchise"
                  render={({ field: { onChange, value } }) => (
                    <EntitySearchInput
                      type={"franchise"}
                      label="Search for Franchise"
                      placeholder="Search By Name/ID"
                      isMandatory={false}
                      value={value}
                      callback={onChange}
                      uid="franchise-search"
                      isMultiple={false}
                    />
                  )}
                />
              </div>

              {/* State */}
              <div className="col-12 mb-3">
                <SelectInput
                  type="text"
                  label="State"
                  register={register}
                  name="state"
                  options={stateOptions}
                  callback={onStateChange}
                />
              </div>

              {/* District */}
              <div className="col-6 mb-3">
                <SelectInput
                  type="text"
                  label="District"
                  register={register}
                  name="district"
                  options={districtOptions}
                  callback={onDistrictChange}
                  disabled={!getValues("state")?.length}
                />
              </div>

              {/* Town */}
              <div className="col-6 mb-3">
                <SelectInput
                  type="text"
                  label="Choose Town"
                  register={register}
                  name="town"
                  options={townOptions}
                  disabled={!getValues("district")?.length}
                />
              </div>

              {/* Type */}
              <div className="col-6 mb-3">
                <SelectInput
                  label="Type"
                  register={register}
                  name="type"
                  isMandatory={false}
                  options={filterView.typeOptions}
                />
              </div>

              {/* Status */}
              <div className="col-6 mb-3">
                <SelectInput
                  label="Status"
                  register={register}
                  name="status"
                  isMandatory={false}
                  options={filterView.statusOptions}
                />
              </div>

              {/* No Of Rows */}
              <div className="col-6 mb-3">
                <SelectInput
                  label="No. Of Rows"
                  register={register}
                  name="noOfRows"
                  isMandatory={false}
                  options={filterView.noOfRowsOptions}
                />
              </div>
            </div>

            {/*  Action Button */}
            <div className="text-end w-100 mt-4">
              <button
                className="btn me-1 btn-warning btn-sm"
                type="button"
                onClick={resetFormData}
              >
                Reset
              </button>
              <button
                className="btn btn-primary btn-sm"
                type="button"
                onClick={handleSubmit(onSubmit)}
              >
                Apply
              </button>
            </div>
          </>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default CreditDebitAdvanceFilter;
