import { ThemeInit } from "@sk/theme";
import React from "react";
import Router from "./Router";
import { LoadUserRoles } from "@sk/features";
import { ToasterContainer } from "@sk/uis";

export default function Root(props) {
  return (
    <React.StrictMode>
      <LoadUserRoles />
      <ThemeInit />
      <Router />
      <ToasterContainer />
    </React.StrictMode>
  );
}
