import { SummaryCard } from "@sk/uis";
import { memo } from "react";

const UploadNotificationSummaryCard = ({ summaryList }) => {
  return (
    <>
      <div className="row">
        {summaryList.map((card, i) => (
          <div className="col-3" key={i}>
            <SummaryCard
              value={card.value}
              title={card.label}
              loading={card.loading}
              valueColor={card.color}
              img={card.img}
              template={2}
            />
          </div>
        ))}
      </div>
    </>
  );
};

export default memo(UploadNotificationSummaryCard);
