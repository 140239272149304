import totalImg from "../../assets/imgs/total.svg";
import activeImg from "../../assets/imgs/active.svg";
import inactiveImg from "../../assets/imgs/inactive.svg";
import { sub } from "date-fns";
const listView = {
  breadcrumb: [
    {
      name: "Home",
      link: "/auth/init",
    },
    {
      name: "Manage Receipts",
    },
  ],

  summaryData: [
    {
      label: "Total",
      value: 0,
      loading: true,
      filter: {},
      color: "primary",
      img: totalImg,
    },
    {
      label: "Created",
      value: 0,
      loading: true,
      filter: { status: "Created" },
      color: "warning",
      img: activeImg,
    },
    {
      label: "Pending",
      value: 0,
      loading: true,
      filter: { status: "Pending" },
      color: "warning",
      img: activeImg,
    },
    {
      label: "Accounted",
      value: 0,
      loading: true,
      filter: { status: "Accounted" },
      color: "warning",
      img: inactiveImg,
    },
    {
      label: "Reserved",
      value: 0,
      loading: true,
      filter: { status: "Reserved" },
      color: "warning",
      img: inactiveImg,
    },
    {
      label: "Rejected",
      value: 0,
      loading: true,
      filter: { status: "Rejected" },
      color: "warning",
      img: inactiveImg,
    },
  ],
  additionalTableDataConfig: [
    {
      key: "franchiseId",
      api: "franchise",
      loadingKey: "franchiseLoading",
      dataKey: "_franchise",
      filter: (ids) => ({
        page: 1,
        count: ids.length,
        filter: { _id: { $in: ids } },
        select: "name,state,district,town,createdAt",
      }),
    },
    {
      key: "createdBy",
      api: "user",
      loadingKey: "createdLoading",
      dataKey: "_createdBy",
      filter: (ids) => ({
        page: 1,
        count: ids.length,
        filter: { _id: { $in: ids } },
        select: "name",
      }),
    },
    {
      key: "modifiedBy",
      api: "user",
      loadingKey: "modifiedLoading",
      dataKey: "_modifiedBy",
      filter: (ids) => ({
        page: 1,
        count: ids.length,
        filter: { _id: { $in: ids } },
        select: "name",
      }),
    },
  ],
  pagination: {
    totalRecords: 0,
    rowsPerPage: 10,
    activePage: 1,
    startSlNo: 1,
    endSlNo: 10,
  },
  filterFormData: {
    status: "",
    createdAt: [
      sub(new Date(), {
        months: 3,
      }),
      new Date(),
    ],
    bank: "",
    paymentType: "", // paymentMode || Mode
    amount: "",
    state: "",
  },
  formLabels: {
    status: {
      label: "Status",
    },
    createdAt: {
      label: "Created on",
      type: "dateRange",
    },
    paymentType: {
      label: "Payment Type",
    },
    amount: {
      label: "Amount",
    },
  },
};

export const receiptView = {
  attachAdditionalDataConfig: [
    {
      key: "franchiseId",
      api: "franchise",
      loadingKey: "franchiseLoading",
      dataKey: "_franchise",
      filter: (ids) => ({
        page: 1,
        count: ids.length,
        filter: { _id: { $in: ids } },
        select:
          "name,state,district,town,createdAt,shop_details,contact_details",
      }),
    },
    {
      key: "createdBy",
      api: "user",
      loadingKey: "createdLoading",
      dataKey: "_createdBy",
      filter: (ids) => ({
        page: 1,
        count: ids.length,
        filter: { _id: { $in: ids } },
        select: "name",
      }),
    },
    {
      key: "modifiedBy",
      api: "user",
      loadingKey: "modifiedLoading",
      dataKey: "_modifiedBy",
      filter: (ids) => ({
        page: 1,
        count: ids.length,
        filter: { _id: { $in: ids } },
        select: "name",
      }),
    },
  ],
};

export default listView;
