import {
  AccountService,
  AjaxService,
  AuthService,
  CommonService,
} from "@sk/services";
import {
  Alert,
  BusyLoader,
  HighlightText,
  NoDataFound,
  PaginationBlock,
  TableHeader,
  TableSkeletonLoader,
  Toaster,
  Rbac,
} from "@sk/uis";
import { memo, useCallback, useRef, useState } from "react";
import listView from "../../constantService";
import UploadNotificationApproveRejectModal from "../modals/UploadNotificationApproveRejectModal";

const rbac = {
  approveButton: ["ApproveUploadNotification"],
  rejectButton: ["RejectUploadNotification"],
};

const UploadNotificationTable = ({
  data,
  sort,
  sortCb,
  loading,
  paginationConfig,
  paginationCb,
  loadingTotalRecords,
  viewCb,
  creditOptions,
  callback,
}) => {
  const [busyLoader, setBusyLoader] = useState({ msg: "", loading: false });

  const [showApproveRejectModal, setShowApproveRejectModal] = useState(false);

  const modalInfo = useRef({ type: "", id: "" });

  const sendMail = async (d) => {
    let p = {
      batchId: d._id,
      emailId: AuthService.getLoggedInEmp().email,
    };
    setBusyLoader({
      msg: "please,wait Sending Mail ",
      loading: true,
    });

    let r = await AccountService.sendUploadNotificationEmail(p);
    setBusyLoader({ msg: "", loading: false });

    if (r.statusCode == 200) {
      Toaster.success("Email Sent Successfully");
    } else {
      let error = AjaxService.parseError(r.resp);
      Toaster.error(error.msg[0]);
    }
  };

  const onClickDownload = async (d) => {
    let r = await CommonService.downloadAsset(d.uploadedFile);
  };

  const approve = async (d) => {
    let res = await Alert.confirm({
      title: "Please confirm",
      text: "Do you want Approve  " + d._id + " Notification",
      icon: "info",
      okText: "Yes",
      cancelText: "No",
    });

    if (!res.isConfirmed) {
      return;
    }

    modalInfo.current = { type: "Approve", id: d._id };
    setShowApproveRejectModal(true);
  };

  const reject = async (d) => {
    let res = await Alert.confirm({
      title: "Please confirm",
      text: "Do you want Reject  " + d._id + " Notification",
      icon: "info",
      okText: "Yes",
      cancelText: "No",
    });

    if (!res.isConfirmed) {
      return;
    }

    modalInfo.current = { type: "Reject", id: d._id };
    setShowApproveRejectModal(true);
  };

  const modalCb = useCallback((d) => {
    modalInfo.current = { type: "", id: "" };
    setShowApproveRejectModal(false);
    callback(d);
  }, []);

  return (
    <>
      <table className="table table-bordered bg-white">
        <TableHeader
          data={listView.defaultHeaders}
          sort={sort}
          sortCb={sortCb}
        />
        <tbody>
          {loading && (
            <TableSkeletonLoader
              rows={10}
              cols={listView.defaultHeaders?.length}
              height={40}
            />
          )}

          {!loading && !data.length ? (
            <tr>
              <td colSpan={listView.defaultHeaders.length}>
                <NoDataFound>Uploaded Notification Not Found </NoDataFound>
              </td>
            </tr>
          ) : null}

          {!loading &&
            data.map((x, index) => (
              <tr key={x._id} className="fs-val-md">
                {/* SL No */}
                <td className="text-center">
                  {paginationConfig.startSlNo + index}
                </td>

                {/* Batch ID */}
                <td>{x._id}</td>

                {/* Batch Name */}
                <td> {x?.batchName || "N/A"}</td>

                {/* No. of RF's */}
                <td className="text-center">{x?.rfCount}</td>

                {/* No. of Records */}
                <td className="text-center">{x?.totalRecords}</td>

                {/* Download */}
                <td className="text-center">
                  <button
                    className="btn btn-sm btn-outline-primary me-1 fs-val-xs"
                    onClick={() => onClickDownload(x)}
                  >
                    Download
                  </button>
                </td>

                {/* Mail */}
                <td className="text-center">
                  <button
                    className="btn btn-sm btn-outline-success me-1 fs-val-xs"
                    onClick={() => sendMail(x)}
                  >
                    Send Mail
                  </button>
                </td>

                {/* Uploaded By */}
                <td className="text-center">{x?._createdBy?.name}</td>

                {/* Approved By */}
                <td className="text-center">{x?._approvedBy?.name}</td>

                {/* Status */}
                <td className="text-center">
                  {<HighlightText status={x.status} />}
                </td>

                {/* Action */}
                <td className="text-center">
                  {x.status == "PENDING" ? (
                    <>
                      <Rbac roles={rbac.approveButton}>
                        <button
                          className="btn btn-sm btn-outline-success me-1 fs-val-xs"
                          onClick={() => {
                            approve(x);
                          }}
                        >
                          Approve
                        </button>
                      </Rbac>
                      <Rbac roles={rbac.rejectButton}>
                        <button
                          className="btn btn-sm btn-outline-danger me-1 ms-1 fs-val-xs"
                          onClick={() => {
                            reject(x);
                          }}
                        >
                          Reject
                        </button>
                      </Rbac>
                    </>
                  ) : null}
                </td>
              </tr>
            ))}
        </tbody>
      </table>

      <BusyLoader message={busyLoader.message} show={busyLoader.loading} />

      <PaginationBlock
        paginationConfig={paginationConfig}
        paginationCb={paginationCb}
        loadingTotalRecords={loadingTotalRecords}
      />

      <UploadNotificationApproveRejectModal
        show={showApproveRejectModal}
        id={modalInfo.current.id}
        type={modalInfo.current.type}
        callback={modalCb}
        creditOptions={creditOptions}
      />
    </>
  );
};

export default memo(UploadNotificationTable);
