import { yupResolver } from "@hookform/resolvers/yup";
import { AjaxService, DigitalPartnerService } from "@sk/services";
import {
  Alert,
  AppTitle,
  PageLoader,
  SelectInput,
  Spinner,
  TextInput,
  Toaster,
} from "@sk/uis";
import { memo, useCallback, useEffect, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";
import { manageView } from "../../constantService";

const validationSchema = yup.object().shape({
  tierName: yup.string().label("Tier Name").required(),
  from: yup
    .number()
    .nullable()
    .transform((v) => (isFinite(v) ? v : null))
    .lessThan(
      yup.ref("to"),
      "Maximum value of From should be less than To value"
    )
    .min(1)
    .max(100000)
    .label("From")
    .required(),
  to: yup
    .number()
    .nullable()
    .transform((v) => (isFinite(v) ? v : null))
    .moreThan(
      yup.ref("from"),
      "Minimum value of To should be more than From value"
    )
    .min(1)
    .max(100000)
    .label("To")
    .required(),
  rent: yup
    .number()
    .nullable()
    .transform((v) => (isFinite(v) ? v : null))
    .min(1)
    .max(100000)
    .label("Rent")
    .required(),
  skShare: yup
    .number()
    .nullable()
    .transform((v) => (isFinite(v) ? v : null))
    .min(0)
    .max(100)
    .label("Share")
    .required(),
});

const canvasStyle = { width: "30%" };

const SmartMiniStoreManageModal = ({ show, id, callback, type }) => {
  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: { ...manageView.defaultFormData },
  });

  const [display, setDisplay] = useState("loading");

  const [title, setTitle] = useState("Add Smart Mini Store Config");

  const closeModal = () => {
    methods.reset();
    callback({ status: "closed" });
  };

  useEffect(() => {
    if (show && id && type == "Edit") {
      let t = id ? id : "";
      setTitle(type + " Smart Mini Store Config #" + t);
      loadConfigDetails();
    } else {
      setDisplay("form");
    }
  }, [id, loadConfigDetails, show, type]);

  const loadConfigDetails = useCallback(async () => {
    setDisplay("loading");
    const r = await DigitalPartnerService.getTierConfigList({
      filter: { _id: id },
    });
    const d = Array.isArray(r.resp) ? (r.resp.length ? r.resp[0] : {}) : {};

    if (d._id) {
      methods.setValue("from", d.from);
      methods.setValue("to", d.to);
      methods.setValue("rent", d.rent);
      methods.setValue("skShare", d.skShare);
      methods.setValue("tierName", d.tierName);
      setDisplay("form");
    } else {
      setDisplay("notFound");
    }
  }, [id, methods]);

  const onSubmit = async () => {
    let errMsg = "";
    const f = methods.getValues();

    try {
      await validationSchema.validate({
        ...f,
      });
      errMsg = "";
    } catch (error) {
      errMsg = error.message || "";
    }

    if (errMsg) {
      Toaster.error(errMsg);
      return;
    }

    let res = await Alert.confirm({
      title: "Please confirm",
      text: "Do you want to proceed?",
      icon: "info",
      okText: "Yes",
      cancelText: "No",
    });

    if (!res.isConfirmed) {
      return;
    }

    setDisplay("submit");

    let params = {
      from: f.from,
      to: f.to,
      rent: f.rent,
      skShare: f.skShare,
      tierName: f.tierName,
    };

    let r = "";
    let msg = "";
    if (type == "Edit") {
      msg = "Smart Mini Store Config Updated Successfully";
      r = await DigitalPartnerService.updateTierConfig(id, params);
    } else {
      msg = "Created Smart Mini Store Config Successfully";
      r = await DigitalPartnerService.createTierConfig(params);
    }

    setDisplay("details");

    if (r.statusCode == 200) {
      Toaster.success(msg);
      callback({ status: "success" });
    } else {
      let error = AjaxService.parseError(r.resp);
      Toaster.error(error.msg[0]);
    }
  };

  return (
    <>
      <Offcanvas
        show={show}
        onHide={closeModal}
        placement="end"
        style={canvasStyle}
      >
        <Offcanvas.Header closeButton className="bg-primary">
          {/* Modal Title */}
          <Offcanvas.Title>
            <AppTitle title={title} />
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="p-4  border-top">
          <div>
            <FormProvider {...methods}>
              {display == "loading" ? <PageLoader animation="border" /> : null}
              {["form", "submit"].indexOf(display) != -1 ? (
                <>
                  <form
                    onSubmit={methods.handleSubmit(onSubmit)}
                    autoComplete="off"
                  >
                    <div className="row">
                      <div className="col-12">
                        <div className="fs-val-md mt-3 mb-3 fw-bold">
                          Config
                        </div>
                      </div>

                      {/* From */}
                      <div className="col-6">
                        <TextInput
                          type="number"
                          name="from"
                          label="From"
                          placeholder="Rs."
                          register={methods.register}
                          isMandatory={true}
                          error={methods.error?.from?.message}
                        />
                      </div>

                      {/* To */}
                      <div className="col-6">
                        <TextInput
                          type="number"
                          name="to"
                          label="To"
                          placeholder="Rs."
                          register={methods.register}
                          isMandatory={true}
                          error={methods.error?.to?.message}
                        />
                      </div>

                      {/* Rent */}
                      <div className="col-6">
                        <TextInput
                          type="number"
                          name="rent"
                          label="Rent"
                          placeholder="Rs."
                          register={methods.register}
                          isMandatory={true}
                          error={methods.error?.rent?.message}
                        />
                      </div>

                      {/* Share */}
                      <div className="col-6">
                        <TextInput
                          type="number"
                          name="skShare"
                          label="Share"
                          placeholder="Rs."
                          register={methods.register}
                          isMandatory={true}
                          error={methods.error?.share?.message}
                        />
                      </div>

                      <div className="col-6 mb-3 ">
                        <SelectInput
                          label="Tier Name"
                          register={methods.register}
                          name="tierName"
                          isMandatory={true}
                          options={manageView.tierOptions}
                          error={methods.error?.tierName?.message}
                        />
                      </div>
                    </div>

                    <div className="mt-3 text-end">
                      <button
                        className="btn  btn-primary py-2"
                        type="button"
                        onClick={onSubmit}
                        disabled={display == "submit"}
                      >
                        Submit
                        {display == "submit" ? (
                          <Spinner isSmall={true} />
                        ) : null}
                      </button>
                    </div>
                  </form>
                </>
              ) : null}
              {display === "notFound" && (
                <NoDataFound>No Data Found</NoDataFound>
              )}
            </FormProvider>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
      {/* <BusyLoader message={busyLoader.message} show={busyLoader.loading} /> */}
    </>
  );
};

export default memo(SmartMiniStoreManageModal);
