import { yupResolver } from "@hookform/resolvers/yup";
import { AccountService, AjaxService, UtilityService } from "@sk/services";
import {
  Alert,
  AppTitle,
  AutoCompleteInput,
  NoDataFound,
  PageLoader,
  SelectInput,
  Spinner,
  SwitchInput,
  TableHeader,
  TextInput,
  Toaster,
} from "@sk/uis";
import { memo, useCallback, useEffect, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import { Controller, FormProvider, useForm, useWatch } from "react-hook-form";
import * as yup from "yup";
import { manageView } from "../constantService";

const validationSchema = yup.object({
  bank: yup.array().min(1).label("Bank").required(),
  isActive: yup.boolean(),
  slab: yup.array().min(1, "Mini 1 Slab is Required").label("Slab").required(),
});

const slabValidation = yup.object().shape({
  value: yup
    .number()
    .nullable()
    .transform((v) => (isFinite(v) ? v : null))
    .max(100000)
    .min(0.1)
    .label("Charge Value")
    .required(),
  maxValue: yup
    .number()
    .nullable()
    .transform((v) => (isFinite(v) ? v : null))
    .max(100000)
    .when("type", {
      is: (type) => type == "perc",
      then: (schema) => schema.max(100000).min(1).label("From"),
    }),
  type: yup.string().label("Charge Type").required(),
  from: yup
    .number()
    .nullable()
    .transform((v) => (isFinite(v) ? v : null))
    .max(1000000)
    .min(0.1)
    .lessThan(
      yup.ref("to"),
      "Maximum value of From should be less than To value"
    )
    .label("From")
    .required(),
  to: yup
    .number()
    .nullable()
    .max(1000000)
    .min(0.1)
    .transform((v) => (isFinite(v) ? v : null))
    .moreThan(
      yup.ref("from"),
      "Minimum value of To should be more than From value"
    )
    .min(1)
    .label("To")
    .required(),
});

const ChargeConfigManageModal = ({ show, dataId, bankId, callback, type }) => {
  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: { ...manageView.defaultFormData },
  });

  const canvasStyle = { width: "50%" };

  const [display, setDisplay] = useState("loading");

  const [title, setTitle] = useState("Add Charge Config");

  const slabList = useWatch({ control: methods.control, name: "slab" });

  const chargeType = useWatch({ control: methods.control, name: "type" });

  const [edit, setEdit] = useState({ isEditing: false, index: -1 });

  const closeModal = () => {
    callback({ status: "closed" });
  };

  useEffect(() => {
    methods.reset();
    if (show && dataId && type == "Edit") {
      setTitle("Edit Charge Config #" + bankId);
      loadConfigDetails();
    } else {
      setDisplay("form");
    }
  }, [bankId, dataId, loadConfigDetails, methods, show, type]);

  const loadConfigDetails = useCallback(async () => {
    setDisplay("loading");
    const r = await AccountService.getChargeConfigList({
      filter: { _id: dataId },
    });
    const d = Array.isArray(r.resp) ? (r.resp.length ? r.resp[0] : {}) : {};

    if (d._id) {
      methods.setValue("bank", [
        { label: d.bank, value: { name: d.bank, _id: d.bankId } },
      ]);
      methods.setValue("isActive", d.isActive);
      let list = (d.slab || []).map((e) => {
        return {
          from: e.from,
          to: e.to,
          type: e.chargeAmount.type,
          maxValue: e.chargeAmount.maxValue,
          value: e.chargeAmount.value,
        };
      });
      methods.setValue("slab", list);
      setDisplay("form");
    } else {
      setDisplay("notFound");
    }
  }, [dataId, methods]);

  const onSubmit = async () => {
    let errMsg = "";
    const f = methods.getValues();

    try {
      await validationSchema.validate({
        ...f,
      });
      errMsg = "";
    } catch (error) {
      errMsg = error.message || "";
    }

    if (errMsg) {
      Toaster.error(errMsg);
      return;
    }

    let res = await Alert.confirm({
      title: "Please confirm",
      text: "Do you want to proceed?",
      icon: "info",
      okText: "Yes",
      cancelText: "No",
    });

    if (!res.isConfirmed) {
      return;
    }

    setDisplay("submit");
    let params = {
      bank: f.bank?.[0].value.name,
      bankId: f.bank?.[0].value._id,
      isActive: f.isActive,
      slab: slabList.map((e) => {
        return {
          from: e.from,
          to: e.to,
          chargeAmount: {
            type: e.type,
            maxValue: e.maxValue || 0,
            value: e.value,
          },
        };
      }),
    };

    let r = "";
    let msg = "";
    if (type == "Edit") {
      msg = "Charge Config Updated Successfully";
      r = await AccountService.updateChargeConfig(dataId, params);
    } else {
      msg = "Created Charge Config  Successfully";
      r = await AccountService.createChargeConfig(params);
    }

    setDisplay("form");

    if (r.statusCode == 200) {
      Toaster.success(msg);
      callback({ status: "success" });
      methods.reset();
    } else {
      let error = AjaxService.parseError(r.resp);
      Toaster.error(error.msg);
    }
  };

  const onBankSearch = useCallback(async (val, callback) => {
    let filter = {};

    if (val) {
      let v = "/" + val + "/";
      filter = { $or: [{ name: v }, { _id: v }] };
    }

    let p = {
      page: 1,
      count: 10,
      select: "name,_id",
      filter: filter,
    };

    const r = await UtilityService.getBankList(p);
    callback((r?.resp || []).map((x) => ({ label: x.name, value: x })));
  }, []);

  const addConfig = async () => {
    let errMsg = "";
    const f = methods.getValues();

    try {
      await slabValidation.validate({
        ...f,
      });

      errMsg = "";
    } catch (error) {
      errMsg = error.message || "";
    }

    if (errMsg) {
      Toaster.error(errMsg);
      return;
    }

    let list = methods.getValues("slab") || [];
    const isDuplicate = (list || []).some(
      (item) =>
        (f.from >= item.from && f.from <= item.to) ||
        (f.to >= item.from && f.to <= item.to) ||
        (f.from <= item.from && f.to >= item.from) ||
        (f.from <= item.to && f.to >= item.from)
    );

    if (isDuplicate && !edit.isEditing) {
      Toaster.error("Enter Slab Range is Overlapping");
      return;
    }
    let data = {
      value: f.value,
      from: f.from,
      to: f.to,
      type: f.type,
      maxValue: f.maxValue,
    };
    if (edit.isEditing) {
      list[edit.index] = data;
    } else {
      list.push(data);
    }

    methods.setValue("slab", list);
    setEdit({ isEditing: false, index: -1 });
    resetSlabForm();
  };

  const editSlab = (index) => {
    setEdit({ isEditing: true, index: index });
    const val = slabList.find((x, i) => i === index);
    methods.setValue("from", val.from);
    methods.setValue("to", val.to);
    methods.setValue("value", val.value);
    methods.setValue("type", val.type);
    methods.setValue("maxValue", val.maxValue);
  };

  const resetSlabForm = () => {
    methods.setValue("from", null);
    methods.setValue("to", null);
    methods.setValue("value", "");
    methods.setValue("type", null);
    methods.setValue("maxValue", null);
  };

  const deleteSlab = (index) => {
    if (edit.isEditing && edit.index == index) {
      resetSlabForm();
    }
    const list = methods.getValues("slab");
    list.splice(index, 1);
    methods.setValue("slab", list);
  };

  return (
    <>
      <Offcanvas
        show={show}
        onHide={closeModal}
        placement="end"
        style={canvasStyle}
      >
        <Offcanvas.Header closeButton className="bg-primary">
          {/* Modal Title */}
          <Offcanvas.Title>
            <AppTitle title={title} />
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="p-4  border-top">
          {display == "loading" ? <PageLoader animation="border" /> : null}
          {["submit", "form"].indexOf(display) != -1 ? (
            <>
              <FormProvider {...methods}>
                <form
                  onSubmit={methods.handleSubmit(onSubmit)}
                  autoComplete="off"
                >
                  <div className="row">
                    <div className="col-6">
                      <Controller
                        control={methods.control}
                        name="bank"
                        render={({ field: { onChange, value } }) => (
                          <AutoCompleteInput
                            label="Select Bank"
                            placeholder="Search By Bank  Here "
                            value={value}
                            onSearch={onBankSearch}
                            callback={onChange}
                            error={methods.error?.bank?.message}
                            isMandatory={true}
                            uid="bank"
                          />
                        )}
                      />
                    </div>

                    {/* Is Active  for Event */}
                    <div className="col-6">
                      <Controller
                        control={methods.control}
                        name="isActive"
                        render={({ field: { onChange, value } }) => (
                          <SwitchInput
                            label="Is Active"
                            isMandatory={false}
                            value={value}
                            callback={onChange}
                          />
                        )}
                      />
                    </div>

                    {/* Slab starts here */}
                    <div className="row">
                      <div className="col-12">
                        <div className="fs-md mt-3 mb-3 fw-bold">
                          Slab Details
                        </div>
                      </div>
                      {/* From */}
                      <div className="col">
                        <TextInput
                          type="number"
                          name="from"
                          label="From"
                          placeholder="Rs."
                          register={methods.register}
                          isMandatory={true}
                          error={methods.error?.from?.message}
                        />
                      </div>

                      {/* To */}
                      <div className="col">
                        <TextInput
                          type="number"
                          name="to"
                          label="To"
                          placeholder="Rs."
                          register={methods.register}
                          isMandatory={true}
                          error={methods.error?.to?.message}
                        />
                      </div>

                      {/* Charge Type */}
                      <div className="col mb-3 ">
                        <SelectInput
                          label="Charge Type"
                          register={methods.register}
                          name="type"
                          isMandatory={true}
                          options={manageView.chargeTypeOptions}
                          error={methods.error?.type?.message}
                        />
                      </div>

                      {/* Charge Value */}
                      <div className="col">
                        <TextInput
                          type="number"
                          name="value"
                          label="Charge Value"
                          placeholder="Charge Value"
                          register={methods.register}
                          isMandatory={true}
                          error={methods.error?.value?.message}
                        />
                      </div>

                      {/* Max Value */}
                      {chargeType == "perc" ? (
                        <div className="col">
                          <TextInput
                            type="number"
                            name="maxValue"
                            label="Max Value"
                            placeholder="Max Value"
                            register={methods.register}
                            isMandatory={true}
                            error={methods.error?.maxValue?.message}
                          />
                        </div>
                      ) : null}

                      <div className="col pt-4">
                        <button
                          className="btn  btn-primary py-2"
                          type="button"
                          onClick={addConfig}
                        >
                          {edit.isEditing ? "Update" : "Add"}
                        </button>
                      </div>
                    </div>

                    {/* Slab List Table */}
                    {slabList?.length ? (
                      <div className="row">
                        <table className="table table-bordered bg-white">
                          <TableHeader data={manageView.defaultTableHeader} />
                          <tbody>
                            {(slabList || []).map((k, index) => (
                              <tr key={index}>
                                {/* Sl No */}
                                <td className="text-center">{index + 1}</td>

                                {/* From */}
                                <td className="text-center">{k?.from}</td>

                                {/* To */}
                                <td className="text-center">{k?.to}</td>

                                {/* Charge Type */}
                                <td className="text-center">{k?.type}</td>

                                {/* Charge Value */}
                                <td className="text-center">{k?.value}</td>

                                {/* Max Value */}
                                <td className="text-center">
                                  {k?.maxValue || "N/A"}
                                </td>

                                <td className="text-center">
                                  <span
                                    className="me-3"
                                    role="button"
                                    tabIndex={0}
                                    onClick={() => editSlab(index)}
                                  >
                                    <i className="bi bi-pencil-square text-primary cursor-pointer"></i>
                                  </span>

                                  <span
                                    onClick={() => deleteSlab(index)}
                                    role="button"
                                    tabIndex={0}
                                  >
                                    <i className="bi bi-trash3-fill font-18 text-danger cursor-pointer"></i>
                                  </span>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    ) : null}
                  </div>

                  <div className="mt-3 text-end">
                    <button
                      className="btn  btn-primary py-2"
                      type="button"
                      onClick={onSubmit}
                      disabled={display == "submit"}
                    >
                      Submit
                      {display == "submit" ? <Spinner isSmall={true} /> : null}
                    </button>
                  </div>
                </form>
              </FormProvider>
            </>
          ) : null}
          {display === "notFound" && <NoDataFound>No Data Found</NoDataFound>}
        </Offcanvas.Body>
      </Offcanvas>
      {/* <BusyLoader message={busyLoader.message} show={busyLoader.loading} /> */}
    </>
  );
};

export default memo(ChargeConfigManageModal);
