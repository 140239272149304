import { AccountService, AjaxService, AuthService } from "@sk/services";
import {
  Alert,
  Amount,
  BusyLoader,
  DateFormatter,
  HighlightText,
  NoDataFound,
  PaginationBlock,
  Rbac,
  TableHeader,
  TableSkeletonLoader,
  Toaster,
} from "@sk/uis";
import { memo, useCallback, useRef, useState } from "react";
import listView from "../../constantService";
import CreditDebitRemarksModal from "../modals/CreditDebitRemarksModal";

const rbac = {
  viewButton: ["ViewCreditNote", "ViewDebitNote"],
};

const loginUser = AuthService.getLoggedInEmp();

const CreditDebitTable = ({
  data,
  sort,
  sortCb,
  loading,
  paginationConfig,
  paginationCb,
  loadingTotalRecords,
  viewCb,
  callback,
}) => {
  const [busyLoader, setBusyLoader] = useState({ msg: "", loading: false });

  const [approveRejectType, setApproveRejectType] = useState("");

  const [selectedId, setSelectedId] = useState("");

  const [showRemarksModal, setShowRemarksModal] = useState(false);

  const actionCb = async (x, type) => {
    setApproveRejectType(type);
    // asking confirm to submit
    let res = await Alert.confirm({
      title: "Please confirm",
      text: "Do you want to " + type + " Note",
      icon: "info",
      okText: "Yes",
      cancelText: "No",
    });

    setSelectedId(x._id);

    if (!res.isConfirmed) {
      return;
    }
    setShowRemarksModal(true);
  };

  const remarksModalCb = useCallback(
    async (d) => {
      if (d.status == "success") {
        setBusyLoader({
          msg: "",
          loading: true,
        });
        let p = {
          status: approveRejectType == "Approve" ? "Approved" : "Rejected",
        };

        if (approveRejectType == "Approve") {
          p.approvedRemarks = d.remarks;
        } else {
          p.rejectedRemarks = d.remarks;
        }

        let r = await AccountService.bulkUpdateCreditDebit(selectedId, p);

        setBusyLoader({ msg: "", loading: false });
        if (r.statusCode == 200) {
          let txt = approveRejectType == "Approve" ? "Approved" : "Rejected";
          Toaster.success(
            selectedId + " Note has been  " + txt + " Successfully"
          );
          setShowRemarksModal(false);
          setSelectedId("");
          callback({ status: "success" });
        } else {
          let error = AjaxService.parseError(r.resp);
          Toaster.error(error.msg[0]);
        }
      } else {
        setShowRemarksModal(false);
      }
    },
    [callback, selectedId, approveRejectType]
  );

  return (
    <>
      <table className="table table-bordered bg-white">
        <TableHeader
          data={listView.defaultHeaders}
          sort={sort}
          sortCb={sortCb}
        />
        <tbody>
          {loading && (
            <TableSkeletonLoader
              rows={10}
              cols={listView.defaultHeaders?.length}
              height={40}
            />
          )}

          {!loading && !data.length ? (
            <tr>
              <td colSpan={listView.defaultHeaders.length}>
                <NoDataFound>Data Not Found </NoDataFound>
              </td>
            </tr>
          ) : null}

          {!loading &&
            data.map((x, index) => (
              <tr key={x._id} className="fs-val-md">
                {/* SL No */}
                <td className="text-center">
                  {paginationConfig.startSlNo + index}
                </td>

                {/*  ID */}
                <td>{x._id}</td>

                {/* Type */}
                <td> {x?.type || "N/A"}</td>

                {/* reason */}
                <td className="text-center">{x?.reasonForCredit}</td>

                {/* Franchise */}
                <td>
                  {x?._franchise?._id ? (
                    <>
                      <div className="text-primary">
                        {x?._franchise?.name} - ({x?._franchise?._id})
                      </div>
                      <div className="fs-val-sm text-muted">
                        {x?._franchise?.district} - {x?._franchise?.state}
                      </div>
                    </>
                  ) : (
                    "N/A"
                  )}
                </td>

                {/* Batch ID */}
                <td className="text-center">{x?.batchId || "N/A"}</td>

                {/* Wallet Type */}
                <td className="text-center">{x?.walletType}</td>

                {/* Amount */}
                <td className="text-center">
                  <Amount value={x?.amount} />
                </td>

                {/*  Created At */}
                <td>
                  <DateFormatter date={x.createdAt} />
                  <div>by {x?._createdBy?.name || "N/A"}</div>
                </td>

                {/* Status */}
                <td className="text-center">
                  {<HighlightText status={x.status} />}
                </td>

                {/* Action */}
                <td className="text-center">
                  <div className="mb-2">
                    <Rbac roles={rbac.viewButton}>
                      <button
                        className="btn btn-sm w-100
                         btn-outline-primary btn-block me-1 fs-val-xs"
                        onClick={() => {
                          viewCb(x);
                        }}
                      >
                        View
                      </button>
                    </Rbac>
                  </div>

                  {x.status == "Pending Approval" &&
                  loginUser.userId != x.createdBy ? (
                    <div className="mb-2">
                      <Rbac roles={rbac.viewButton}>
                        <button
                          className="btn w-100 btn-sm btn-outline-success me-1 fs-val-xs"
                          onClick={() => {
                            actionCb(x, "Approve");
                          }}
                        >
                          Approve
                        </button>
                      </Rbac>
                    </div>
                  ) : null}

                  {x.status == "Pending Approval" &&
                  loginUser.userId != x.createdBy ? (
                    <div>
                      <Rbac roles={rbac.viewButton}>
                        <button
                          className="btn btn-sm w-100 btn-block btn-outline-danger me-1 fs-val-xs"
                          onClick={() => {
                            actionCb(x, "Reject");
                          }}
                        >
                          Reject
                        </button>
                      </Rbac>
                    </div>
                  ) : null}
                </td>
              </tr>
            ))}
        </tbody>
      </table>

      <PaginationBlock
        paginationConfig={paginationConfig}
        paginationCb={paginationCb}
        loadingTotalRecords={loadingTotalRecords}
      />

      <CreditDebitRemarksModal
        show={showRemarksModal}
        type={approveRejectType}
        creditNoteId={selectedId}
        callback={remarksModalCb}
      />

      <BusyLoader message={busyLoader.message} show={busyLoader.loading} />
    </>
  );
};

export default memo(CreditDebitTable);
