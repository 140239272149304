import { AccountService } from "@sk/services";
import { useAttachAdditionalData } from "@sk/hooks";
import {
  AppTitle,
  DateFormatter,
  KeyVal,
  NoDataFound,
  PageLoader,
  HighlightText,
} from "@sk/uis";
import { useCallback, useEffect, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import listView from "../../constantService";
import HostToHostRawDataModal from "./HostToHostRawDataModal";

const canvasStyle = { width: "60%" };

const HostToHostViewModal = ({ show, dataId, callback }) => {
  const [display, setDisplay] = useState("loading");

  const [setAdditionalData, attachAllData] = useAttachAdditionalData();

  const [data, setData] = useState({});

  const [showRawData, setShowRawData] = useState(false);

  // To Close Filter Modal
  const closeModal = () => {
    callback({ status: "closed" });
  };

  useEffect(() => {
    if (show && dataId) {
      loadDetails(dataId);
    } else {
      setDisplay("noDataFound");
    }
  }, [loadDetails, dataId, show]);

  const loadDetails = useCallback(async () => {
    setDisplay("loading");
    const r = await AccountService.getHostToHostList({
      filter: { _id: dataId },
    });
    const d = Array.isArray(r.resp) ? (r.resp.length ? r.resp[0] : {}) : {};

    setData(d);
    if (d._id) {
      let tmp = [];
      // Attach User Info
      setAdditionalData([d], listView.additionalTableDataConfig, (x) => {
        tmp.push(x);
        if (tmp.length == listView.additionalTableDataConfig.length) {
          const t = [...attachAllData([d], tmp)][0];
          setData((v) => ({ ...v, ...t }));
        }
      });
    }

    if (d?._id) {
      setDisplay("details");
    } else {
      setDisplay("noDataFound");
    }
  }, [attachAllData, dataId, setAdditionalData]);

  const viewRawData = useCallback(() => {
    setShowRawData(true);
  }, []);

  const closeRawDataModal = useCallback(() => {
    setShowRawData(false);
  }, []);

  return (
    <>
      <Offcanvas
        show={show}
        onHide={closeModal}
        placement="end"
        style={canvasStyle}
      >
        <Offcanvas.Header closeButton className="bg-primary">
          {/* Modal Title */}
          <Offcanvas.Title>
            <AppTitle title={"Host To Host Details #" + dataId} />
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="p-0">
          {display == "loading" && <PageLoader />}
          {display == "noDataFound" && (
            <NoDataFound> No Data Found</NoDataFound>
          )}
          {display == "details" && (
            <div>
              {/* Franchise Details */}
              <div className="p-4 bg-light">
                <div className="fw-semibold fs-val-lg mb-2">Franchise</div>
                <div className="text-primary fs-val-lg mb-1">
                  {data?._franchise?.name || "N/A"} - ({data?.franchiseId})
                </div>
                {data?._franchise?.length ? (
                  <div className="text-primary ">
                    {data?._franchise?.district}
                    {data?._franchise?.district ? ", " : null}
                    {data?._franchise?.state} - {data?._franchise?.pincode}
                  </div>
                ) : null}
              </div>

              {/* Basic Details */}
              <div className="p-4 ">
                <div className="fw-semibold fs-val-lg mb-2">Basic Details</div>

                <div className="row mb-4">
                  <div className="col-3 mb-4">
                    <KeyVal label="Amount(Rs.)" template="col">
                      {data.amount}
                    </KeyVal>
                  </div>

                  <div className="col-3 mb-4">
                    <KeyVal label="UTR Number" template="col">
                      {data?.trans_id}
                    </KeyVal>
                  </div>

                  <div className="col-3 mb-4">
                    <KeyVal label="Mode" template="col">
                      {data?.mode}
                    </KeyVal>
                  </div>

                  <div className="col-3 mb-4">
                    <KeyVal label="Virtual Acc no:" template="col">
                      {data?.ben_name}
                    </KeyVal>
                  </div>

                  <div className="col-3">
                    <KeyVal label="Created On:" template="col">
                      <DateFormatter date={data.createdAt} />
                    </KeyVal>
                  </div>

                  <div className="col-3">
                    <KeyVal label="Last Update On:" template="col">
                      <DateFormatter date={data.modifiedDate} />
                    </KeyVal>
                  </div>

                  <div className="col-3">
                    <KeyVal label="Status" template="col">
                      <HighlightText status={data.status} />
                    </KeyVal>
                  </div>

                  <div className="col-3">
                    <KeyVal label="MIS Status" template="col">
                      <HighlightText status={data.dump} />
                    </KeyVal>
                  </div>
                </div>
              </div>

              {/* Remitter Details */}
              <div className="p-4  bg-light ">
                <div className="fw-semibold fs-val-lg mb-2">
                  Remitter Details
                </div>

                <div className="row mb-4">
                  <div className="col-3">
                    <KeyVal label="Account Name" template="col">
                      {data.rem_name || "N/A"}
                    </KeyVal>
                  </div>

                  <div className="col-3">
                    <KeyVal label="Account Number" template="col">
                      {data?.rem_acc_no || "N/A"}
                    </KeyVal>
                  </div>

                  <div className="col-3">
                    <KeyVal label="IFSC Code" template="col">
                      {data?.rem_ifsc_no || "N/A"}
                    </KeyVal>
                  </div>

                  <div className="col-3">
                    <KeyVal label="Mobile Number" template="col">
                      {data?.rem_mob_no || "N/A"}
                    </KeyVal>
                  </div>
                </div>
              </div>

              {/* Beneficiary Details  */}
              <div className="p-4 ">
                <div className="fw-semibold fs-val-lg mb-2">
                  Beneficiary Details
                </div>

                <div className="row mb-4">
                  <div className="col-3">
                    <KeyVal label="Account Name" template="col">
                      {data.ben_name}
                    </KeyVal>
                  </div>

                  <div className="col-3">
                    <KeyVal label="Account Number" template="col">
                      {data?.ben_account_num || "N/A"}
                    </KeyVal>
                  </div>

                  <div className="col-3">
                    <KeyVal label="IFSC Code" template="col">
                      {data?.ben_ifsc_no || "N/A"}
                    </KeyVal>
                  </div>

                  <div className="col-3">
                    <KeyVal label="Mobile Number" template="col">
                      {data?.ben_mob_no || "N/A"}
                    </KeyVal>
                  </div>
                </div>
              </div>

              {/* Remarks No */}
              <div className="p-4 bg-light">
                <div className="fw-semibold fs-val-lg mb-2">
                  Remarks Details
                </div>
                <div className="row">
                  <div className="col-12">
                    <KeyVal label="Remarks" template="col">
                      {data.remarks || "N/A"}
                    </KeyVal>
                  </div>
                </div>
              </div>

              {/* View Button */}
              <div className="p-2 text-end">
                <button className="btn btn-primary" onClick={viewRawData}>
                  View
                </button>
              </div>

              <HostToHostRawDataModal
                show={showRawData}
                data={data}
                callback={closeRawDataModal}
              />
            </div>
          )}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default HostToHostViewModal;
