import {
  Amount,
  NoDataFound,
  PaginationBlock,
  TableHeader,
  TableSkeletonLoader,
} from "@sk/uis";

const headers = [
  { label: "Sl.no", width: "10%", isCentered: true },
  { label: "Order ID" },
  { label: "Payment Mode" },
  { label: "Amount" },
  { label: "Log ID" },
];

function ReconcileTable({
  data,
  loading,
  paginationConfig,
  paginationCb,
  loadingTotalRecords,
}) {
  return (
    <>
      <table className="table table-sm table-striped">
        <TableHeader data={headers} noBg />
        <tbody className="fs-val-md">
          {/* loader */}
          {loading && (
            <TableSkeletonLoader rows={2} cols={headers.length} height={40} />
          )}

          {/* no data */}
          {!loading && !data.length ? (
            <tr>
              <td colSpan={headers.length}>
                <NoDataFound>No orders found </NoDataFound>
              </td>
            </tr>
          ) : null}

          {/* list of data */}
          {data.map((x, i) => (
            <tr key={x._id}>
              <td className="text-center fs-val-md">
                {i + paginationConfig.startSlNo}
              </td>
              <td className="fs-val-md">{x.orderId}</td>
              <td className="fs-val-md">{x._paymentMode}</td>
              <td className="fs-val-md">
                <Amount value={x.totalAmount} decimalPlace={2} />
              </td>

              <td className="fs-val-md">{x._id}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <PaginationBlock
        paginationConfig={paginationConfig}
        paginationCb={paginationCb}
        loadingTotalRecords={loadingTotalRecords}
      />
    </>
  );
}

export default ReconcileTable;
