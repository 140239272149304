import totalImg from "../../assets/imgs/total.svg";
import activeImg from "../../assets/imgs/active.svg";
import inactiveImg from "../../assets/imgs/inactive.svg";

// LIST VIEW START
const listView = {
  breadcrumb: [
    {
      name: "Home",
      link: "/auth/init",
    },
    {
      name: "KingCoins Credit",
    },
  ],
  // Breadcrumbs Ends Here

  summaryData: [
    {
      label: "Total",
      value: 0,
      loading: true,
      filter: {},
      color: "primary",
      img: totalImg,
    },
    {
      label: "Pending",
      value: 0,
      loading: true,
      filter: { status: "Pending" },
      color: "warning",
      img: activeImg,
    },
    {
      label: "Approved",
      value: 0,
      loading: true,
      filter: { status: "Approved" },
      color: "warning",
      img: activeImg,
    },
    {
      label: "Cancelled",
      value: 0,
      loading: true,
      filter: { status: "Cancelled" },
      color: "warning",
      img: inactiveImg,
    },
    {
      label: "Rejected",
      value: 0,
      loading: true,
      filter: { status: "Rejected" },
      color: "warning",
      img: inactiveImg,
    },
  ],
  //Summary Card Data Ends Here

  additionalTableDataConfig: [
    {
      key: "franchiseId",
      api: "franchise",
      loadingKey: "franchiseLoading",
      dataKey: "_franchise",
      filter: (ids) => ({
        page: 1,
        count: ids.length,
        filter: { _id: { $in: ids } },
        select: "name,state,district,town,pincode",
      }),
    },
    {
      key: "createdBy",
      api: "user",
      loadingKey: "createdLoading",
      dataKey: "_createdBy",
      filter: (ids) => ({
        page: 1,
        count: ids.length,
        filter: { _id: { $in: ids } },
        select: "name",
      }),
    },
    {
      key: "modifiedBy",
      api: "user",
      loadingKey: "modifiedLoading",
      dataKey: "_modifiedBy",
      filter: (ids) => ({
        page: 1,
        count: ids.length,
        filter: { _id: { $in: ids } },
        select: "name",
      }),
    },
  ],

  // Additional Table Data Ends Here

  pagination: {
    totalRecords: 0,
    rowsPerPage: 10,
    activePage: 1,
    startSlNo: 1,
    endSlNo: 10,
  },
  // Pagination Ends Here

  formLabels: {
    status: {
      label: "Status",
    },
    createdAt: {
      label: "Created on",
      type: "dateRange",
    },
    franchise: {
      label: "Franchise",
      valuePath: "[0].value.name",
    },
  },

  // Form Labels Ends Here

  filterFormData: {
    status: "",
    franchise: [],
    createdAt: [],
  },

  defaultHeaders: [
    { label: "Sl No", width: "5%", isCentered: true },
    { label: "ID" },
    { label: "Batch ID" },
    { label: "Customer ID" },
    { label: "Paid For" },
    { label: "Amount", isCentered: true },
    { label: "Franchise" },
    { label: "Created On" },
    { label: "Modified On" },
    { label: "Status", isCentered: true },
    { label: "Actions", isCentered: true },
  ],
};
// LIST VIEW EDS HERE

export const filterView = {
  statusOptions: [
    { label: "All", value: "" },
    { label: "Approved", value: "Approved" },
    { label: "Pending", value: "Pending" },
    { label: "Cancelled", value: "Cancelled" },
    { label: "Rejected", value: "Rejected" },
  ],

  // Form Labels Ends Here

  filterFormData: {
    status: "",
    franchise: [],
    createdAt: [],
  },
};

export const formView = {
  additionalTableDataConfig: [
    {
      key: "franchiseId",
      api: "franchise",
      loadingKey: "franchiseLoading",
      dataKey: "_franchise",
      filter: (ids) => ({
        page: 1,
        count: ids.length,
        filter: { _id: { $in: ids } },
        select: "name,state,district,town",
      }),
    },
    {
      key: "createdBy",
      api: "user",
      loadingKey: "createdLoading",
      dataKey: "_createdBy",
      filter: (ids) => ({
        page: 1,
        count: ids.length,
        filter: { _id: { $in: ids } },
        select: "name",
      }),
    },
    {
      key: "modifiedBy",
      api: "user",
      loadingKey: "modifiedLoading",
      dataKey: "_modifiedBy",
      filter: (ids) => ({
        page: 1,
        count: ids.length,
        filter: { _id: { $in: ids } },
        select: "name",
      }),
    },
  ],
};

export default listView;
