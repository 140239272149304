import { yupResolver } from "@hookform/resolvers/yup";
import { AccountService, AjaxService, UtilityService } from "@sk/services";
import {
  Alert,
  Amount,
  AppTitle,
  BusyLoader,
  EntitySearchInput,
  KeyVal,
  SelectInput,
  Spinner,
  TextInput,
  TextareaInput,
  Toaster,
} from "@sk/uis";
import { useCallback, useEffect, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import { Controller, useForm, useWatch } from "react-hook-form";
import * as yup from "yup";
import { editView } from "../../constantService";
import { orderBy } from "lodash";

const validationSchema = yup.object({
  franchise: yup.array().min(1).default("Franchise").required(),
  walletType: yup.string().trim().label("Wallet Type").required(),
  reasonForCredit: yup.string().label("Reason For Credit").required(),
  amount: yup
    .number()
    .nullable()
    .transform((v) => (isFinite(v) ? v : null))
    .max(1000000)
    .min(0.1)
    .label("Amount")
    .required(),
  remarks: yup.string().trim().label("Remarks").required(),
});

const canvasStyle = { width: "35%" };

const CreditDebitViewModal = ({ show, type, callback }) => {
  const {
    register,
    getValues,
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: { ...editView.defaultFormData },
  });

  const [reasonsOptions, setReasonsOptions] = useState({
    loading: false,
    list: [],
  });

  const [busyLoader, setBusyLoader] = useState({ msg: "", loading: false });

  const [franData, setFranData] = useState({});

  const [walletTypeOptions, setWalletTypeOptions] = useState([]);

  const [franBalance, setFranBalance] = useState({ loading: false, value: 0 });

  const franchise = useWatch({
    name: "franchise",
    control: control,
  });

  const closeModal = () => {
    callback({ status: "closed" });
  };

  useEffect(() => {
    if (show && type) {
      reset();
      setFranData({});
      setFranBalance({ loading: false, value: 0 });
      loadReasonOptions();
    }
  }, [loadReasonOptions, reset, show, type]);

  const loadReasonOptions = useCallback(async () => {
    setReasonsOptions({ loading: true, list: [] });
    let obs = "";
    if (type == "Credit") {
      obs = await UtilityService.getCreditReason();
    } else {
      obs = await UtilityService.getDebitReason();
    }
    let d = Array.isArray(obs.resp) ? obs.resp : [];
    d = orderBy(d, ["name"], ["asc"]);
    let opt = d.map((e) => {
      return { label: e.name, value: e.name };
    });
    opt.unshift({ label: "Select", value: "" });
    setReasonsOptions({ loading: false, list: opt });
  }, [type]);

  const onSubmit = async () => {
    let errMsg = "";
    const f = getValues();

    try {
      await validationSchema.validate({
        ...f,
      });
      errMsg = "";
    } catch (error) {
      errMsg = error.message || "";
    }

    if (errMsg) {
      Toaster.error(errMsg);
      return;
    }

    const alrt = await Alert.confirm({
      title: "Please confirm",
      text: "Do you want to proceed " + type + " Note ?",
      icon: "info",
      okText: "Yes",
      cancelText: "No",
    });

    if (!alrt.isConfirmed) {
      return;
    }

    setBusyLoader({
      msg: "",
      loading: true,
    });

    let params = {
      accountNo: franData.accountId,
      amount: f.amount,
      franchiseId: franData._id,
      franchiseName: franData.name,
      reasonForCredit: f.reasonForCredit,
      remarks: f.remarks,
      type: type,
      walletType: f.walletType,
    };

    let r = await AccountService.createCreditDebit(params);
    setBusyLoader({ msg: "", loading: false });
    if (r.statusCode == 200) {
      Toaster.success("Created " + type + " Note Successfully");
      callback({ status: "success" });
    } else {
      let error = AjaxService.parseError(r.resp);
      Toaster.error(error.msg[0]);
    }
  };

  const onFranchiseSelect = useCallback(
    (chngFn) => {
      return (e) => {
        chngFn(e);

        if (e.length) {
          let fid = e.length ? e[0].value._id : "";

          setFranData(e[0].value);

          loadFranchiseBalance(fid);

          setWalletTypeOptions([]);

          loadWalletOptions(fid);
        } else {
          setFranData({});
        }
      };
    },
    [loadFranchiseBalance, loadWalletOptions]
  );

  const loadFranchiseBalance = useCallback(async (fid) => {
    setFranBalance({ loading: true, value: 0 });
    let p = { page: 1, count: 1, filter: { owner: fid } };
    const r = await AccountService.getFranBalance(p);
    const d = Array.isArray(r.resp) ? (r.resp.length ? r.resp[0] : {}) : {};
    setFranBalance({ loading: false, value: d.actualBalance || 0 });
  }, []);

  const loadWalletOptions = useCallback(async (fid) => {
    let p = { page: 1, count: 50, filter: { owner: fid } };
    const r = await AccountService.getFranBalance(p);
    const d = Array.isArray(r.resp) ? r.resp : [];
    let opt = [{ label: "Select", value: "" }];
    d.forEach((e) => {
      if (e.accountSubType == "Credit") {
        opt.push({ label: "Credit Wallet", value: "creditWallet" });
      } else if (e.accountSubType == undefined) {
        opt.push({ label: "SK Advance Wallet", value: "skWallet" });
      }
    });
    setWalletTypeOptions(opt);
  }, []);

  return (
    <>
      <Offcanvas
        show={show}
        onHide={closeModal}
        placement="end"
        style={canvasStyle}
      >
        <Offcanvas.Header closeButton className="bg-primary">
          {/* Modal Title */}
          <Offcanvas.Title>
            <AppTitle title={"Create " + type + " Note"} />
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="p-4  border-top">
          <div>
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              {/* Template Add flow */}
              <div className="row">
                <div className="col-12 mb-2">
                  <Controller
                    control={control}
                    name="franchise"
                    render={({ field: { onChange, value } }) => (
                      <EntitySearchInput
                        type={"franchise"}
                        label="Search for Franchise"
                        placeholder="Search By Name/ID"
                        isMandatory={true}
                        value={value}
                        callback={onFranchiseSelect(onChange)}
                        uid="franchise-search"
                        isMultiple={false}
                      />
                    )}
                  />
                </div>

                {/* Franchise Details */}
                {franData?._id?.length ? (
                  <div className="col-12  mb-2">
                    <div className="p-3 mb-4 bg-light border rounded-2">
                      <div className="fw-semibold fs-val-md mb-2">
                        Franchise Details
                      </div>

                      <KeyVal label="Name" labelCol="col-3" contentCol="col-9">
                        <span className="fw-semibold">
                          : {franData?.name || "N/A"}
                        </span>
                      </KeyVal>

                      <KeyVal label="ID" labelCol="col-3" contentCol="col-9">
                        <span>: {franData?._id || "N/A"}</span>
                      </KeyVal>

                      <KeyVal
                        label="Location"
                        labelCol="col-3"
                        contentCol="col-9"
                      >
                        <span>
                          : {franData?.city || franData?.town || "N/A"} ,{" "}
                          {franData?.district} , {franData?.state} -
                          {franData?.pincode || "N/A"}
                        </span>
                      </KeyVal>

                      <KeyVal
                        label="Balance"
                        labelCol="col-3"
                        contentCol="col-9"
                        className="text-primary"
                      >
                        <span className="fw-semibold">
                          :
                          {franBalance.loading ? (
                            <Spinner type="dots" />
                          ) : (
                            <span className="ps-1">
                              <Amount
                                value={franBalance.value}
                                decimalPlace="2"
                              />
                            </span>
                          )}
                        </span>
                      </KeyVal>
                    </div>
                  </div>
                ) : null}

                {/* Wallet Type Option */}
                <div className="col-6 mb-3">
                  <SelectInput
                    label="Wallet Type"
                    register={register}
                    name="walletType"
                    error={errors?.walletType?.message}
                    isMandatory={true}
                    options={walletTypeOptions}
                    disabled={!franchise?.length}
                  />
                </div>

                {/* Amount */}
                <div className="col-6 mb-3">
                  <TextInput
                    type="number"
                    name="amount"
                    label="Amount"
                    max={1000000}
                    min={0}
                    placeholder="Rs."
                    register={register}
                    isMandatory={true}
                    error={errors?.amount?.message}
                  />
                </div>

                {/* Reason For Credit */}
                <div className="col-12 mb-3">
                  <SelectInput
                    label={
                      type == "Credit"
                        ? "Reason for Credit"
                        : "Reason for Debit"
                    }
                    register={register}
                    name="reasonForCredit"
                    error={errors?.reasonForCredit?.message}
                    isMandatory={true}
                    disabled={reasonsOptions.loading}
                    options={reasonsOptions.list}
                  />
                </div>

                {/* Remarks */}
                <div className="col-12 pe-3">
                  <TextareaInput
                    name="remarks"
                    placeholder=""
                    error={errors?.remarks?.message}
                    label="Remarks"
                    register={register}
                    isMandatory={true}
                    maxLength="50"
                    rows="3"
                  />
                </div>
              </div>

              <div className="mt-3 text-end">
                <button className="btn  btn-primary py-2" type="submit">
                  Submit
                </button>
              </div>
            </form>

            <BusyLoader
              message={busyLoader.message}
              show={busyLoader.loading}
            />
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default CreditDebitViewModal;
