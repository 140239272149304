import { useAttachAdditionalData } from "@sk/hooks";
import { CommonService, OmsService } from "@sk/services";
import {
  AppCard,
  AppliedFilterLabel,
  DateInput,
  DatePickerInput,
  PageInfo,
  PaginationSummary,
  SelectInput,
  SummaryCard,
  TextInput,
} from "@sk/uis";

import { produce } from "immer";

import merge from "lodash/merge";

import { memo, useCallback, useEffect, useRef, useState } from "react";

import { Controller, useForm } from "react-hook-form";

import { useNavigate } from "react-router-dom";

import Table from "./components/Table";

import { set } from "date-fns";
import listView from "./constantService";

const statusOptions = [
  { label: "All", value: "" },
  { label: "Created", value: "Created" },
  { label: "Pending Approval", value: "PendingApproval" },
  { label: "Proof Pending", value: "ProofPending" },
  { label: "Approved", value: "Approved" },
];

const paymentOptions = [
  { label: "All", value: "" },
  { label: "In Store Purchase", value: "PURCHASE" },
  { label: "Cash", value: "CASH" },
  { label: "UPI", value: "UPI" },
];

const getData = async (params) => {
  const r = await OmsService.getCashDepositList(params);
  return Array.isArray(r.resp)
    ? r.resp.map((x) => {
        x.userLoading = true;

        return x;
      })
    : [];
};

// To Get Count
const getCount = async (params) => {
  try {
    let p = {
      ...params,
      outputType: "count",
    };
    const r = await OmsService.getCashDepositList(p);
    return {
      count: r.statusCode == 200 && r.resp[0] ? r.resp[0].total : 0,
      totalAmt: r.statusCode == 200 && r.resp[0] ? r.resp[0].totalAmt : 0,
    };
  } catch (error) {
    return new Promise((resolve) => resolve({ count: 0 }));
  }
};

// Preparing Filter Params
const prepareFilterParams = (pagination = {}, filter = {}, sort = {}) => {
  let p = {
    page: pagination.activePage,
    count: pagination.rowsPerPage,
    filter: {},
  };

  let d = filter || {};

  const search = d?.id?.trim();
  const searchRegex = { $regex: search, $options: "gi" };
  if (search) {
    p.filter.$or = [
      { _id: searchRegex },
      { "franchiseInfo.id": searchRegex },
      { "franchiseInfo.name": searchRegex },
      { bankName: searchRegex },
    ];
  }

  let status = filter.status;

  p.filter.status = status;

  // if (sort.key) {
  //   p.sort = sort.value == "desc" ? `-${sort.key}` : sort.key;
  // }

  if (filter.paymentMode) {
    p.filter.paymentMode = filter.paymentMode;
  }

  if (filter?.createdAt?.length) {
    p.filter.createdAt = {
      $gte: set(filter.createdAt[0], {
        hours: 0,
        minutes: 0,
        seconds: 0,
      }),
      $lte: set(filter.createdAt[1], {
        hours: 23,
        minutes: 59,
        seconds: 59,
      }),
    };
  }

  if (!p.filter.status) {
    delete p.filter.status;
  }

  return p;
};

const defaultSortOpt = { key: "lastUpdated", value: "desc" };

const createdOnDateConfig = { mode: "range", maxDate: new Date() };

const ListCashDepositReconcile = () => {
  const [data, setData] = useState([]);

  const [loadingData, setLoadingData] = useState(true);

  const [loadingTotalRecords, setLoadingTotalRecords] = useState(true);

  const [summaryCard, setSummaryCard] = useState([...listView.summaryData]);
  const [purchaseSummaryCard, setPurchaseSummaryCard] = useState([
    ...listView.purchaseSummaryData,
  ]);

  const [filterLabels, setFilterLabels] = useState([]);

  const [setAdditionalData, attachAllData] = useAttachAdditionalData();

  const filterDataRef = useRef({ ...listView.filterFromData });

  const paginationRef = useRef({ ...listView.pagination });

  const sortRef = useRef({ ...defaultSortOpt });

  const { control, register, getValues, reset, setValue } = useForm({
    defaultValues: listView.filterFromData,
  });

  const router = useNavigate();

  useEffect(() => {
    init();
  }, [init]);

  const loadList = useCallback(async () => {
    setLoadingData(true);

    const p = getFilterParams();
    const d = await getData(p);

    let tmp = [];
    setAdditionalData(d, listView.additionalTableDataConfig, (x) => {
      tmp.push(x);
      if (tmp.length == listView.additionalTableDataConfig.length) {
        setData([...attachAllData(d, tmp)]);
      }
    });
    setData(d);
    setLoadingData(false);
  }, [attachAllData, getFilterParams, setAdditionalData]);

  const getFilterParams = useCallback(() => {
    return prepareFilterParams(
      paginationRef.current,
      filterDataRef.current,
      sortRef.current
    );
  }, []);

  const loadSummary = useCallback(async () => {
    // Setting loading all card to Loading State
    setSummaryCard(
      produce((draft) => {
        draft.forEach((x) => {
          x.loading = true;
        });
      })
    );
    setPurchaseSummaryCard(
      produce((draft) => {
        draft.forEach((x) => {
          x.loading = true;
        });
      })
    );

    const filterParams = getFilterParams();
    let summaryPromises = [];
    let purchasePromises = [];

    summaryCard.forEach((e) => {
      const params = merge({}, filterParams, { filter: e.filter });
      if (e.key === "total") {
        delete params.filter.status;
      }
      delete params.count;
      delete params.page;
      delete params.select;
      summaryPromises.push(getCount(params));
    });

    purchaseSummaryCard.forEach((e) => {
      const params = merge({}, filterParams, { filter: e.filter });
      delete params.count;
      delete params.page;
      delete params.select;
      purchasePromises.push(
        getData({
          ...params,
          groupbycond: "status",
          filter: {
            ...params.filter,
            paymentMode: "PURCHASE",
            ...(e.filter.status && { status: e.filter.status }),
          },
        })
      );
    });

    const [summaryResults, purchaseResults] = await Promise.all([
      Promise.all(summaryPromises),
      Promise.all(purchasePromises),
    ]);

    setSummaryCard(
      produce((draft) => {
        draft.forEach((x, k) => {
          x.loading = false;
          x.value = summaryResults[k].count || 0;
        });
      })
    );

    setPurchaseSummaryCard(
      produce((draft) => {
        draft.forEach((x, k) => {
          x.loading = false;
          x.value = purchaseResults[k].reduce(
            (acc, curr) => acc + (curr.totalAmt || 0),
            0
          );
        });
      })
    );
  }, [getFilterParams, summaryCard, purchaseSummaryCard]);

  const paginationCb = useCallback(
    (data) => {
      paginationRef.current.startSlNo = data.startSlNo;
      paginationRef.current.endSlNo = data.endSlNo;
      paginationRef.current.activePage = data.activePage;
      loadList();
    },
    [loadList]
  );

  const filterCb = useCallback(() => {
    filterDataRef.current = { ...getValues() };
    applyFilter(true);
  }, [applyFilter, getValues]);

  const prepareFilterLabels = useCallback(() => {
    const v = { ...(filterDataRef.current || {}) };
    const l = CommonService.prepareAppliedFilterLabels(listView.formLabels, v);
    setFilterLabels(l);
  }, []);

  const loadCount = useCallback(async () => {
    setLoadingTotalRecords(true);

    const p = getFilterParams();
    const c = await getCount(p);

    paginationRef.current.totalRecords = c.count;

    setLoadingTotalRecords(false);
  }, [getFilterParams]);

  const applyFilter = useCallback(
    async (isSummaryRequired = false) => {
      // Resetting pagination
      paginationRef.current = { ...listView.pagination };

      loadList();

      loadCount();

      if (isSummaryRequired) {
        console.log("summery req triggered");
        loadSummary();
      }

      prepareFilterLabels();
    },
    [loadList, loadSummary, prepareFilterLabels, loadCount]
  );

  const init = useCallback(async () => {
    applyFilter(true);
  }, [applyFilter]);

  const sortCb = useCallback(
    (data) => {
      sortRef.current = { ...data };
      applyFilter();
    },
    [applyFilter]
  );

  const onSummaryCardClick = useCallback(
    (data) => {
      if (!data?.isClickable) {
        return;
      }

      // Reset both status and payment mode
      setValue("status", "");
      setValue("paymentMode", "");

      // Apply filters based on the card type
      if (data.key === "purchase") {
        setValue("paymentMode", "PURCHASE");
        if (data.filter.status) {
          setValue("status", data.filter.status);
        }
      } else if (data.filter.status) {
        setValue("status", data.filter.status);
      }

      filterDataRef.current = { ...getValues() };
      applyFilter();
    },
    [applyFilter, setValue, getValues]
  );

  const resetFilter = useCallback(() => {
    reset({ ...listView.filterFromData });
    filterCb();
  }, [reset, filterCb]);

  return (
    <>
      <div className="row align-items-center">
        <div className="col">
          <PageInfo
            title="Deposit Reconcile"
            breadcrumbs={listView.breadcrumb}
            navigate={router}
          />
        </div>
      </div>

      <div className="row g-4">
        {/* Regular Summary Section */}
        <div className="col-6">
          <div className="fs-val-normal mb-2 fw-semibold">Cash/UPI Summary</div>
          <div className="row">
            {summaryCard.map((e, k) => (
              <div className="col-4" key={k}>
                <SummaryCard
                  value={e.value}
                  title={e.label}
                  loading={e.loading}
                  valueColor={e.color}
                  img={e.img}
                  isClickable={e.isClickable}
                  onSummaryCardClick={onSummaryCardClick}
                  data={e}
                  template={3}
                  isValueAmt={e.isAmount}
                />
              </div>
            ))}
          </div>
        </div>

        {/* Purchase Summary Section */}
        <div className="col-6">
          <div className="fs-val-normal mb-2 fw-semibold">
            In Store Purchase Summary
          </div>
          <div className="row">
            {purchaseSummaryCard.map((e, k) => (
              <div className="col-6" key={k}>
                <SummaryCard
                  value={e.value}
                  title={e.label}
                  loading={e.loading}
                  valueColor={e.color}
                  img={e.img}
                  isClickable={e.isClickable}
                  onSummaryCardClick={onSummaryCardClick}
                  data={e}
                  template={3}
                  isValueAmt={e.isAmount}
                />
              </div>
            ))}
          </div>
        </div>
      </div>

      <AppCard>
        {/* Filter  Block  */}
        <div className="row">
          <div className="col-12">
            <div className="row">
              <div className="col-3">
                <TextInput
                  name="id"
                  type="text"
                  label="Search"
                  register={register}
                  placeholder="Search By ID / Franchise / Bank"
                />
              </div>

              <div className="col-2">
                <SelectInput
                  name="status"
                  register={register}
                  label="Status"
                  options={statusOptions}
                />
              </div>

              <div className="col-2">
                <SelectInput
                  name="paymentMode"
                  register={register}
                  label="Payment Mode"
                  options={paymentOptions}
                />
              </div>

              <div className="col-3">
                <label className="mb-1 fs-val-md">Created On</label>
                <Controller
                  name="createdAt"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <DateInput
                      placeholder="Choose"
                      value={value}
                      callback={onChange}
                      config={createdOnDateConfig}
                      template={2}
                    />
                  )}
                />
              </div>
              <div className="col-auto ms-5 align-self-center mt-2">
                <button
                  className="btn btn-primary fs-val-md me-2 "
                  onClick={filterCb}
                >
                  Apply
                </button>
                <button
                  className="btn btn-outline-warning fs-val-md "
                  onClick={resetFilter}
                >
                  Reset
                </button>
              </div>
            </div>
          </div>
        </div>
      </AppCard>

      {/* Table */}

      <AppCard>
        {/* Filter selected Label */}
        <div className="row">
          <div className="col-auto">
            <PaginationSummary
              paginationConfig={paginationRef.current}
              loadingTotalRecords={loadingTotalRecords}
            />
          </div>
          <div className="col-auto">
            {filterLabels.length > 0 ? (
              <AppliedFilterLabel labels={filterLabels} />
            ) : null}
          </div>
        </div>
        <div className="my-3"></div>

        <Table
          data={data}
          sort={sortRef.current}
          sortCb={sortCb}
          loading={loadingData}
          paginationConfig={paginationRef.current}
          paginationCb={paginationCb}
          loadingTotalRecords={loadingTotalRecords}
        />
      </AppCard>
    </>
  );
};

export default memo(ListCashDepositReconcile);
