import { AccountService } from "@sk/services";
import { SummaryCard, AppCard, Spinner } from "@sk/uis";
import { memo, useEffect, useState } from "react";
import classNames from "classnames";

const HostToHostSummaryCard = ({ label, filterParams, color, img }) => {
  const [loading, setLoading] = useState(true);
  const [value, setValue] = useState(0);

  const [dumpLoading, setDumpLoading] = useState(true);
  const [dumpValue, setDumpValue] = useState(0);

  useEffect(() => {
    loadCount(filterParams);
    loadDumpApprovedCount(filterParams);
  }, [filterParams]);

  const loadCount = async (filter) => {
    setLoading(true);
    const r = await AccountService.getHostToHostListCount({ filter });
    setValue(!isNaN(Number(r?.resp)) ? r?.resp : 0);
    setLoading(false);
  };

  const loadDumpApprovedCount = async (filter) => {
    let f = { filter: { ...filter, dump: "Approved" } };
    setDumpLoading(true);
    const r = await AccountService.getHostToHostListCount(f);
    setDumpValue(!isNaN(Number(r?.resp)) ? r?.resp : 0);
    setDumpLoading(false);
  };

  return (
    <>
      <AppCard>
        <div className="row d-flex justify-content-center">
          <div className="col-9">
            <p className="text-dark mb-0 fw-semibold">{label}</p>
            <h3
              className={classNames(
                "my-1 font-20 fw-bold",
                color ? "text-" + color : "text-muted"
              )}
            >
              {dumpLoading ? <Spinner type="dots" /> : <>{dumpValue}</>}/{" "}
              {loading ? <Spinner type="dots" /> : <>{value}</>}
            </h3>
          </div>
          {/*end col*/}
          <div className="col-3 align-self-center">
            {<img src="" alt="" srcset="" /> ? (
              <div className="d-flex justify-content-center align-items-center thumb-md bg-light-alt rounded-circle mx-auto">
                <i
                  className={classNames(
                    "font-24 align-self-center bi text-muted",
                    img
                  )}
                />
              </div>
            ) : null}
          </div>
          {/*end col*/}
        </div>
        {/*end row*/}
      </AppCard>
    </>
  );
};

export default memo(HostToHostSummaryCard);
