import totalImg from "../../assets/imgs/total.svg";
import activeImg from "../../assets/imgs/active.svg";
import inactiveImg from "../../assets/imgs/inactive.svg";

// LIST VIEW START
const listView = {
  breadcrumb: [
    {
      name: "Home",
      link: "/auth/init",
    },
    {
      name: "Smart Mini Store",
    },
  ],
  // Breadcrumbs Ends Here

  summaryData: [
    {
      label: "Total",
      value: 0,
      loading: true,
      filter: {},
      color: "primary",
      img: totalImg,
    },
    {
      label: "Active",
      value: 0,
      loading: true,
      filter: { status: "Active" },
      color: "warning",
      img: activeImg,
    },
    {
      label: "Inactive",
      value: 0,
      loading: true,
      filter: { status: "Inactive" },
      color: "warning",
      img: activeImg,
    },
  ],
  //Summary Card Data Ends Here

  additionalTableDataConfig: [
    {
      key: "createdBy",
      api: "user",
      loadingKey: "createdLoading",
      dataKey: "_createdBy",
      filter: (ids) => ({
        page: 1,
        count: ids.length,
        filter: { _id: { $in: ids } },
        select: "name",
      }),
    },
    {
      key: "modifiedBy",
      api: "user",
      loadingKey: "modifiedLoading",
      dataKey: "_modifiedBy",
      filter: (ids) => ({
        page: 1,
        count: ids.length,
        filter: { _id: { $in: ids } },
        select: "name",
      }),
    },
  ],

  // Additional Table Data Ends Here

  pagination: {
    totalRecords: 0,
    rowsPerPage: 10,
    activePage: 1,
    startSlNo: 1,
    endSlNo: 10,
  },
  // Pagination Ends Here

  formLabels: {
    status: {
      label: "Status",
    },
    createdAt: {
      label: "Created on",
      type: "dateRange",
    },
    tierName: {
      label: "Tier Name",
    },
  },

  // Form Labels Ends Here

  filterFormData: {
    status: "",
    tierName: "",
    createdAt: [],
  },

  defaultHeaders: [
    { label: "Sl No", width: "5%", isCentered: true },
    { label: "ID", isCentered: true },
    { label: "Tier Name", isCentered: true },
    { label: "From", isCentered: true },
    { label: "To", isCentered: true },
    { label: "Achieved From", isCentered: true },
    { label: "Achieved To", isCentered: true },
    { label: "SK Rent", isCentered: true },
    { label: "SK Share (%)", isCentered: true },
    { label: "Created On" },
    { label: "Modified On" },
    { label: "Status", isCentered: true },
    { label: "Actions", isCentered: true },
  ],
};
// LIST VIEW EDS HERE

export const filterView = {
  statusOptions: [
    { label: "All", value: "" },
    { label: "Active", value: "Active" },
    { label: "Inactive", value: "Inactive" },
  ],

  tierOptions: [
    { label: "All", value: "" },
    { label: "Tier 1", value: "Tier 1" },
    { label: "Tier 2", value: "Tier 2" },
    { label: "Tier 3", value: "Tier 3" },
    { label: "Tier 4", value: "Tier 4" },
    { label: "Tier 5", value: "Tier 5" },
    { label: "Tier 6", value: "Tier 6" },
    { label: "Tier 7", value: "Tier 7" },
    { label: "Tier 8", value: "Tier 8" },
    { label: "Tier 9", value: "Tier 9" },
    { label: "Tier 10", value: "Tier 10" },
    { label: "Tier BPCL", value: "Tier BPCL" },
    { label: "Tier BPCL New", value: "Tier BPCL New" },
    { label: "Tier GPAY", value: "Tier GPAY" },
    { label: "Tier MP", value: "Tier MP" },
    { label: "Metro", value: "Metro" },
  ],

  // Form Labels Ends Here

  filterFormData: {
    status: "",
    tierName: "",
    createdAt: [],
  },
};

export const manageView = {
  tierOptions: [
    { label: "Select", value: "" },
    { label: "Tier 1", value: "Tier 1" },
    { label: "Tier 2", value: "Tier 2" },
    { label: "Tier 3", value: "Tier 3" },
    { label: "Tier 4", value: "Tier 4" },
    { label: "Tier 5", value: "Tier 5" },
    { label: "Tier 6", value: "Tier 6" },
    { label: "Tier 7", value: "Tier 7" },
    { label: "Tier 8", value: "Tier 8" },
    { label: "Tier 9", value: "Tier 9" },
    { label: "Tier 10", value: "Tier 10" },
    { label: "Tier BPCL", value: "Tier BPCL" },
    { label: "Tier BPCL New", value: "Tier BPCL New" },
    { label: "Tier GPAY", value: "Tier GPAY" },
    { label: "Tier MP", value: "Tier MP" },
    { label: "Metro", value: "Metro" },
  ],
};
export default listView;
