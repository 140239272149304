import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { AccountService, AjaxService } from "@sk/services";
import {
  Amount,
  AppCard,
  AppTitle,
  DateFormatter,
  KeyVal,
  NoDataFound,
  PageLoader,
  Spinner,
  TextInput,
  TextareaInput,
  Toaster,
} from "@sk/uis";
import { useCallback, useEffect, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import { useForm } from "react-hook-form";
import * as yup from "yup";

const canvasStyle = { width: "40%" };

const validationSchema = yup.object({
  newBeneficiaryNo: yup
    .string()
    .max(25)
    .label("New Beneficiary No.")
    .required(),
  remarks: yup.string().max(100).label("Remarks"),
});

const getData = async (params) => {
  const r = await AccountService.getHostToHostList(params);
  return Array.isArray(r.resp) ? r.resp : [];
};

const BeneficiaryFormModal = ({ show, callback, details }) => {
  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      newBeneficiaryNo: "",
      remarks: "",
    },
  });

  const [display, setDisplay] = useState("loading");

  const [data, setData] = useState({});

  useEffect(() => {
    if (show && details._id) {
      loadDetails();
    } else {
      setDisplay("noDataFound");
    }
  }, [loadDetails, show, details._id]);

  const loadDetails = useCallback(async () => {
    setDisplay("loading");
    const d = await getData({ filter: { _id: details._id } });
    setData(d[0] || {});
    setDisplay("details");
  }, [details._id]);

  const closeModal = () => {
    methods.reset();
    callback({ status: "close" });
  };

  const onSubmit = async () => {
    let errMsg = "";
    const f = methods.getValues();

    try {
      await validationSchema.validate({
        ...f,
      });
      errMsg = "";
    } catch (error) {
      errMsg = error.message || "";
    }

    if (errMsg) {
      Toaster.error(errMsg);
      return;
    }

    setDisplay("submit");

    let params = {
      newVirtualAccountno: f.newBeneficiaryNo,
      oldVirtualAccountno: data.rem_virtual_account_number || "",
      remarks: f.remarks,
    };

    let r = await AccountService.updateHostToHostDetailsById(data._id, params);

    setDisplay("details");
    if (r.statusCode == 200) {
      Toaster.success("Updated Beneficiary Details Successfully");
      methods.reset();
      callback({ status: "success" });
    } else {
      let error = AjaxService.parseError(r.resp);
      Toaster.error(error.msg[0]);
    }
  };

  return (
    <>
      <Offcanvas
        show={show}
        onHide={closeModal}
        placement="end"
        style={canvasStyle}
      >
        <Offcanvas.Header closeButton className="bg-primary">
          {/* Modal Title */}
          <Offcanvas.Title>
            <AppTitle title={"Update Beneficiary Details #" + data?._id} />
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="p-4  border-top">
          {display == "loading" && <PageLoader />}
          {display == "noDataFound" && (
            <NoDataFound> No Data Found</NoDataFound>
          )}
          {["details", "submit"].indexOf(display) != -1 && (
            <>
              <div className="mb-2 row">
                <KeyVal
                  label="Transaction Id"
                  labelCol="col-3"
                  contentCol="col-auto"
                  labelClassName="fs-val-sm"
                >
                  : {data?._id}
                </KeyVal>
              </div>

              {/* Partner Name */}
              <div className="mb-2 row">
                <KeyVal
                  label="Partner Name"
                  labelCol="col-3"
                  contentCol="col-auto"
                  labelClassName="fs-val-sm"
                >
                  : {data?.partnerName || "N/A"}
                </KeyVal>
              </div>

              {/* Remitter Name */}
              <div className="mb-2 row">
                <KeyVal
                  label="Remitter Name"
                  labelCol="col-3"
                  contentCol="col-auto"
                  labelClassName="fs-val-sm"
                >
                  : {data?.rem_name || "N/A"}
                </KeyVal>
              </div>

              {/* Amount */}
              <div className="mb-2 row">
                <KeyVal
                  label="Amount (Rs.)"
                  labelCol="col-3"
                  contentCol="col-auto"
                  labelClassName="fs-val-sm"
                >
                  : <Amount value={data?.amount} />
                </KeyVal>
              </div>

              {/* UTR No */}
              <div className="mb-2 row">
                <KeyVal
                  label="UTR Number"
                  labelCol="col-3"
                  contentCol="col-auto"
                  labelClassName="fs-val-sm"
                >
                  : {data.trans_id}
                </KeyVal>
              </div>

              {/* created At */}
              <div className="mb-2 row">
                <KeyVal
                  label="Created At"
                  labelCol="col-3"
                  contentCol="col-auto"
                  labelClassName="fs-val-sm"
                >
                  : <DateFormatter date={data.createdAt} />
                </KeyVal>
              </div>

              {/* Old Beneficiary Number */}
              <div className="mb-4 row">
                <KeyVal
                  label="Old Beneficiary Number"
                  labelCol="col-3"
                  contentCol="col-auto"
                  labelClassName="fs-val-sm"
                >
                  : {data?.rem_virtual_account_number}
                </KeyVal>
              </div>

              <AppCard>
                <div className="mb-2">
                  <TextInput
                    type="text"
                    name="newBeneficiaryNo"
                    label="New Beneficiary Number"
                    register={methods.register}
                    isMandatory={true}
                    error={methods.error?.newBeneficiaryNo?.message}
                  />
                </div>

                <div className="mb-2">
                  <TextareaInput
                    type="text"
                    name="remarks"
                    label="Remarks"
                    register={methods.register}
                    isMandatory={false}
                    error={methods.error?.remarks?.message}
                    maxLength="50"
                    rows="2"
                  />
                </div>

                <div className="mt-3 text-end">
                  <button
                    className="btn  btn-primary py-2"
                    type="button"
                    onClick={onSubmit}
                    disabled={display == "submit"}
                  >
                    Submit
                    {display == "submit" ? <Spinner isSmall={true} /> : null}
                  </button>
                </div>
              </AppCard>
            </>
          )}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default BeneficiaryFormModal;
