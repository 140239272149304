import { AppTitle, AuditLogTable, BusyLoader } from "@sk/uis";
import { memo } from "react";
import { Offcanvas } from "react-bootstrap";

const ChargeConfigViewModal = ({ show, dataId, callback, bankId }) => {
  const closeModal = () => {
    callback({ status: "closed" });
  };

  const canvasStyle = { width: "50%" };

  return (
    <>
      <Offcanvas
        show={show}
        onHide={closeModal}
        placement="end"
        style={canvasStyle}
      >
        <Offcanvas.Header closeButton className="bg-primary">
          {/* Modal Title */}
          <Offcanvas.Title>
            <AppTitle title={" Audit Log #" + bankId} />
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="p-4  border-top">
          <div>
            <AuditLogTable entityType="ReceiptCharge" entityId={dataId} />
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default memo(ChargeConfigViewModal);
