import {
  Amount,
  DateFormatter,
  HighlightText,
  NoDataFound,
  PaginationBlock,
  TableHeader,
  TableSkeletonLoader,
} from "@sk/uis";
import { memo, useRef, useState } from "react";
import ViewCashDepositModal from "../modals/ViewCashDepoisteModal";

const headers = [
  { label: "Sl No", width: "5%", isCentered: true },
  { label: "Deposit ID " },
  { label: "Store Name" },
  { label: "Bank Name" },
  { label: "Deposit Amount" },
  { label: "Payment Mode" },
  { label: "Status" },
  { label: "Created on", enableSort: true, key: "createdAt" },
  { label: "Actions" },
];

const Table = ({
  data,
  loading,
  paginationConfig,
  paginationCb,
  loadingTotalRecords,
  sort,
  sortCb,
}) => {
  const [showViewModal, setShowViewModal] = useState(false);

  const viewModalRef = useRef({
    depositId: "",
  });

  const openViewModal = (depositId) => {
    viewModalRef.current.depositId = depositId;
    setShowViewModal(true);
  };
  const closeDepositModal = () => {
    viewModalRef.current.depositId = "";
    setShowViewModal(false);
  };

  return (
    <>
      <table className="table table-sm bg-white">
        <TableHeader data={headers} sort={sort} sortCb={sortCb} noBg={true} />

        {/* Table Body */}
        <tbody>
          {/*  When Table is Loading  */}
          {loading && (
            <TableSkeletonLoader rows={10} cols={headers.length} height={40} />
          )}

          {/* When No loading */}
          {!loading && !data.length ? (
            <tr>
              <td colSpan={headers.length}>
                <NoDataFound>No Cash Deposit Found </NoDataFound>
              </td>
            </tr>
          ) : null}
          {/* When Not Loading and Data is there  */}
          {!loading &&
            data.map((x, index) => (
              <tr key={x._id} className="fs-val-md">
                {/* Serial Number */}
                <td className="text-center">
                  {paginationConfig.startSlNo + index}
                </td>
                <td>{x._id}</td>
                <td>
                  <div className="mb-1">{x.franchiseName}</div>
                  <div className="text-muted fs-val-sm">
                    FID: {x.franchiseId}
                  </div>
                </td>
                <td> {x.bankName || "N/A"} </td>
                <td className="text-primary">
                  <Amount value={x.depositedAmount} decimalPlace={2} />
                </td>
                <td>
                  <HighlightText type={x.paymentModeStatusType} template={2}>
                    {x._paymentMode}
                  </HighlightText>
                </td>
                {/* <td className="text-center">{x.receipts.join(",") || "N/A"}</td> */}

                {/* Status */}
                <td>
                  <HighlightText status={x.status} isBadge size="sm" />
                </td>

                <td>
                  <DateFormatter date={x.createdAt} />
                </td>
                <td>
                  <button
                    className="btn btn-outline-primary btn-sm fs-val-md"
                    onClick={() => openViewModal(x._id)}
                  >
                    View
                  </button>
                </td>
              </tr>
            ))}
        </tbody>
      </table>

      {/* Pagination Block */}
      <PaginationBlock
        paginationConfig={paginationConfig}
        paginationCb={paginationCb}
        loadingTotalRecords={loadingTotalRecords}
      />

      <ViewCashDepositModal
        show={showViewModal}
        depositId={viewModalRef.current.depositId}
        callback={closeDepositModal}
      />
    </>
  );
};

export default memo(Table);
