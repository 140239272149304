import { useAttachAdditionalData } from "@sk/hooks";
import { AccountService, UtilityService } from "@sk/services";
import {
  AutoCompleteInput,
  DatePickerInput,
  PageInfo,
  Rbac,
  SelectInput,
} from "@sk/uis";
import { set } from "date-fns";
import { useCallback, useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import ChargeConfigTable from "./components/ChargeConfigTable";
import listView from "./constantService";
import ChargeConfigAdvanceFilterModal from "./modals/ChargeConfigAdvanceFilterModal";
import ChargeConfigManageModal from "./modals/ChargeConfigManageModal";
import ChargeConfigViewModal from "./modals/ChargeConfigViewModal";

const defaultFilterFormData = listView.filterFormData;

const defaultPagination = listView.pagination;

const defaultSort = listView.defaultSortOpt;

const createdOnDateConfig = { mode: "range", maxDate: new Date() };

const getData = async (params) => {
  const r = await AccountService.getChargeConfigList(params);
  return Array.isArray(r.resp) ? r.resp : [];
};

const rbac = {
  addButton: ["AddChargeConfig"],
};

const prepareFilterParams = (pagination = {}, filter = {}, sort = {}) => {
  let p = {
    page: pagination.activePage,
    count: pagination.rowsPerPage,
    filter: {},
  };

  let d = filter || {};
  if (d.bank?.length > 0) {
    p.filter.bankId = d.bank?.[0]?.value?._id;
  }

  if (d.createdAt?.length) {
    p.filter.createdAt = {
      $gte: set(filter.createdAt[0], {
        hours: 0,
        minutes: 0,
        seconds: 0,
      }),
      $lte: set(filter.createdAt[1], {
        hours: 23,
        minutes: 59,
        seconds: 59,
      }),
    };
  }

  if (d.status) {
    p.filter.isActive = d.status == "Active";
  }

  if (sort.key) {
    p.sort = sort.value == "desc" ? `-${sort.key}` : sort.key;
  }

  return p;
};

const ChargeConfigList = () => {
  const { register, getValues, control, reset } = useForm({
    defaultValues: listView.filterFormData,
  });

  const navigate = useNavigate();

  const [data, setData] = useState([]);

  const [loadingData, setLoadingData] = useState(true);

  const [setAdditionalData, attachAllData] = useAttachAdditionalData();

  const [showAdvFilterModal, setShowAdvFilterModal] = useState(false);

  const [showManageModal, setShowManageModal] = useState(false);

  const [showViewModal, setShowViewModal] = useState(false);

  const [showLoadMore, setShowLoadMore] = useState(true);

  const manageModalInfoRef = useRef({ mode: "Add", data: {} });

  const filterDataRef = useRef(defaultFilterFormData);

  const paginationRef = useRef(defaultPagination);

  const sortRef = useRef(defaultSort);

  useEffect(() => {
    init();
  }, [init]);

  const init = useCallback(async () => {
    setData([]);
    applyFilter();
  }, [applyFilter]);

  const applyFilter = useCallback(async () => {
    paginationRef.current = { ...defaultPagination };
    loadList();
  }, [loadList]);

  const loadList = useCallback(async () => {
    // for list
    setLoadingData(true);
    const p = getFilterParams();
    const d = await getData(p);
    d.forEach((e) => {
      e._showTable = false;
    });
    let tmp = [];

    // Attaching Additional Data
    setAdditionalData([...d], [...listView.additionalTableDataConfig], (x) => {
      tmp.push(x);
      if (tmp.length == listView.additionalTableDataConfig.length) {
        setData([...attachAllData(d, tmp)]);
      }
    });

    setShowLoadMore(d.length % 10 == 0 ? true : false);

    setData(d);

    setLoadingData(false);
  }, [attachAllData, setAdditionalData]);

  // Get Filter Params
  const getFilterParams = () => {
    return prepareFilterParams(
      paginationRef.current,
      filterDataRef.current,
      sortRef.current
    );
  };

  const onStatusChange = useCallback(() => {
    filterDataRef.current = { ...getValues() };
    applyFilter();
  }, [applyFilter, getValues]);

  // For Closing Advance Filer Modal
  const closeAdvFilterModal = useCallback(() => {
    setShowAdvFilterModal(false);
  }, []);

  // For Closing Advance Filer Modal
  const closeManageModal = useCallback(
    (data) => {
      setShowManageModal(false);
      if (data.status == "success") {
        init();
      }
    },
    [init]
  );

  // To Handle Advance Filter
  const advFilterCb = (data) => {
    if (["applied", "reset"].indexOf(data.status) != -1) {
      filterDataRef.current = { ...filterDataRef.current, ...data.formData };
      paginationRef.current = { ...defaultPagination };
      applyFilter();
    }
    closeAdvFilterModal(false);
  };

  const onBankSearch = useCallback(async (val, callback) => {
    let filter = {};

    if (val) {
      let v = "/" + val + "/";
      filter = { $or: [{ name: v }, { _id: v }] };
    }

    let p = {
      page: 1,
      count: 10,
      select: "name,_id",
      filter: filter,
    };

    const r = await UtilityService.getBankList(p);
    callback((r?.resp || []).map((x) => ({ label: x.name, value: x })));
  }, []);

  const onFilterChange = useCallback(
    (chngFn) => (val) => {
      chngFn(val);
      filterDataRef.current = { ...getValues() };

      applyFilter();
    },
    [applyFilter, getValues]
  );

  const loadMore = useCallback(() => {
    paginationRef.current.activePage++;
    loadList(true);
  }, [loadList]);

  const addConfig = useCallback(() => {
    manageModalInfoRef.current = { data: "", mode: "Add" };
    setShowManageModal(true);
  }, []);

  const editCb = useCallback((data) => {
    manageModalInfoRef.current = { mode: "Edit", data: data };
    setShowManageModal(true);
  }, []);

  const viewCb = useCallback((d) => {
    manageModalInfoRef.current = { mode: "", data: d };
    setShowViewModal(true);
  }, []);

  const closeViewModal = useCallback(() => {
    manageModalInfoRef.current = { mode: "", data: {} };
    setShowViewModal(false);
  }, []);

  const resetFormData = useCallback(() => {
    reset();
    filterDataRef.current = { ...getValues() };
    applyFilter();
  }, [applyFilter, getValues, reset]);

  return (
    <>
      <PageInfo
        title="Bank Charge Config"
        breadcrumbs={listView.breadcrumb}
        navigate={navigate}
      />

      {/* Filter  Block  */}
      <div className="row align-items-center mb-3">
        <div className="col-6">
          <div className="row">
            {/* Name/ID Search Input */}
            <div className="col-4 align-self-center">
              <Controller
                control={control}
                name="bank"
                render={({ field: { onChange, value } }) => (
                  <AutoCompleteInput
                    placeholder="Search By Bank ID/Name Here "
                    value={value}
                    onSearch={onBankSearch}
                    label="Search By Bank ID/Name "
                    callback={onFilterChange(onChange)}
                    uid="bank"
                  />
                )}
              />
            </div>
            <div className="col-4">
              <label className="mb-1 fs-val-md">Created On</label>
              <Controller
                name="createdAt"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <DatePickerInput
                    placeholder="Choose"
                    value={value}
                    inpChange={onFilterChange(onChange)}
                    config={createdOnDateConfig}
                  />
                )}
              />
            </div>

            {/* Status */}
            <div className="col-2">
              <SelectInput
                label="Status"
                register={register}
                name="status"
                isMandatory={false}
                options={listView.modalStatusOptions}
                callback={onStatusChange}
              />
            </div>

            <div className="col-auto mt-4">
              <button
                className="btn me-1 btn-warning btn-sm mt-1 fs-val-md"
                type="button"
                onClick={resetFormData}
              >
                Reset
              </button>
            </div>
          </div>
        </div>

        {/* Action Buttons */}
        <div className="col-auto ms-auto align-self-center">
          <Rbac roles={rbac.addButton}>
            <button
              className="btn btn-primary btn-sm fs-val-md me-1"
              onClick={addConfig}
            >
              +Add Charge Config
            </button>
          </Rbac>
        </div>
      </div>

      {/* Table Component */}
      <ChargeConfigTable
        data={data}
        loading={loadingData}
        editCb={editCb}
        viewCb={viewCb}
      />

      {showLoadMore ? (
        <div className="my-3 text-end">
          <button
            className="btn btn-primary btn-sm ms-auto fs-val-md me-1"
            onClick={loadMore}
          >
            Load More
          </button>
        </div>
      ) : null}

      {/* Advance Filter Modal  */}
      <ChargeConfigAdvanceFilterModal
        callback={advFilterCb}
        show={showAdvFilterModal}
      />

      <ChargeConfigManageModal
        callback={closeManageModal}
        show={showManageModal}
        type={manageModalInfoRef.current.mode}
        dataId={manageModalInfoRef.current.data._id || ""}
        bankId={manageModalInfoRef.current.data.bankId || ""}
      />

      <ChargeConfigViewModal
        callback={closeViewModal}
        show={showViewModal}
        dataId={manageModalInfoRef.current.data._id || ""}
        bankId={manageModalInfoRef.current.data.bankId || ""}
      />
    </>
  );
};

export default ChargeConfigList;
