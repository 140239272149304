import { useAttachAdditionalData } from "@sk/hooks";
import {
  DateFormatter,
  HighlightText,
  NoDataFound,
  TableHeader,
} from "@sk/uis";
import { memo, useCallback, useEffect, useState } from "react";
import { viewModal } from "../../constantService";

const CreditDebitAuditLog = ({ details }) => {
  const [data, setData] = useState([]);

  const [setAdditionalData, attachAllData] = useAttachAdditionalData();

  useEffect(() => {
    if (details.auditLogList.length) {
      loadAuditLog(details.auditLogList);
    } else {
      setData([]);
    }
  }, [details.auditLogList, loadAuditLog]);

  const loadAuditLog = useCallback(
    (d) => {
      if (d.length) {
        let tmp = [];
        setAdditionalData(
          d,
          viewModal.auditLogAdditionalTableDataConfig,
          (x) => {
            tmp.push(x);
            if (
              tmp.length == viewModal.auditLogAdditionalTableDataConfig.length
            ) {
              setData([...attachAllData(d, tmp)]);
            }
          }
        );
      }

      setData(d);
    },
    [attachAllData, setAdditionalData]
  );

  return (
    <>
      {details.auditLogList?.length > 0 ? (
        <div>
          <div className="fw-semibold fs-val-lg mb-2 mt-3">Audit Log</div>

          <table className="table table-bordered bg-white">
            <TableHeader data={viewModal.defaultHeaders} />
            <tbody>
              {!data?.length ? (
                <tr>
                  <td colSpan={viewModal.defaultHeaders.length}>
                    <NoDataFound>Data Not Found </NoDataFound>
                  </td>
                </tr>
              ) : null}

              {data.map((x, index) => (
                <tr key={x._id} className="fs-val-md">
                  {/* SL No */}
                  <td className="text-center">{1 + index}</td>

                  <td>
                    <DateFormatter date={x?.createdAt} />
                  </td>

                  <td>{x?._userCreatedBy?.name || "N/A"}</td>

                  <td>
                    <HighlightText status={x?.audit?.[0]?.oldStatus || "N/A"} />
                  </td>

                  <td>
                    <HighlightText status={x?.audit?.[0]?.newStatus || "N/A"} />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : null}
    </>
  );
};

export default memo(CreditDebitAuditLog);
