import { yupResolver } from "@hookform/resolvers/yup";
import { AccountService, AjaxService } from "@sk/services";
import {
  BusyLoader,
  SelectInput,
  TextInput,
  TextareaInput,
  Toaster,
} from "@sk/uis";
import { memo, useState } from "react";
import { Modal } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";

const validationSchema = yup.object().shape({
  reason: yup.string().label("Reason"),
  remarks: yup.string().label("Remarks").required(),
  amount: yup
    .number()
    .nullable()
    .transform((v) => (isFinite(v) ? v : null))
    .min(0)
    .label("Amount"),
});

const UploadNotificationApproveRejectModal = ({
  callback,
  show,
  creditOptions,
  type,
  id,
}) => {
  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: { remarks: "", reason: "", amount: null },
  });

  const [busyLoader, setBusyLoader] = useState({ msg: "", loading: false });

  const closeModal = () => {
    invokeCallback("close", {});
  };

  const invokeCallback = (action, data) => {
    if (callback) {
      callback({
        action: action,
        data: data || {},
      });
    }
  };

  const onHideCb = () => {
    closeModal();
  };

  const onSubmit = async () => {
    let f = methods.getValues();
    let msg = "";
    if (!f.remarks || !f.remarks.trim()) {
      msg = "Please Enter Remarks";
    } else if (type == "Approve" && !f.reason) {
      msg = "Please Select Reason For Approve";
    } else if (type == "Approve" && !f.amount) {
      msg = "Please enter Amount";
    } else if (type == "Approve" && f.amount < 0) {
      msg = "Please Provide Valid Amount";
    }

    if (msg) {
      Toaster.error(msg);
      return;
    }

    setBusyLoader({
      msg: "",
      loading: true,
    });

    let p = {
      status: type == "Approve" ? "APPROVED" : "REJECTED",
      statusPriority: 1,
    };

    if (type != "Approve") {
      p.rejectedReason = f.remarks;
      p.rejectedOn = new Date(Date.now());
    } else {
      p.approvedRemarks = f.remarks;
      p.creditReasons = f.reason;
      p.amount = f.amount;
      p.approvedOn = new Date(Date.now());
    }

    let r = await AccountService.updateUploadNotificationList(id, p);

    setBusyLoader({ msg: "", loading: false });

    if (r.statusCode == 200) {
      let k = id + "has been " + type + "ed Successfully";
      Toaster.success(type + "");
      callback({ status: "success" });
    } else {
      let error = AjaxService.parseError(r.resp);
      Toaster.error(error.msg[0]);
    }
  };

  return (
    <Modal show={show} backdrop={"static"} onHide={onHideCb}>
      <Modal.Header closeButton>
        <Modal.Title>
          <div className="fs-5">{type} Remarks</div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="bg-light">
        <div>
          <FormProvider {...methods}>
            <form autoComplete="off">
              <div className="row">
                {type == "Approve" ? (
                  <>
                    <div className="col mb-3 ">
                      <SelectInput
                        label="Reason For Credit"
                        register={methods.register}
                        name="reason"
                        isMandatory={true}
                        options={creditOptions}
                        error={methods.error?.reason?.message}
                      />
                    </div>

                    <div className="col-4 mb-3 ">
                      <TextInput
                        type="number"
                        label="Amount"
                        register={methods.register}
                        name="amount"
                        isMandatory={true}
                        error={methods.error?.mount?.message}
                      />
                    </div>
                  </>
                ) : null}

                {/* Remarks */}
                <div className="col-12 pe-3">
                  <TextareaInput
                    name="remarks"
                    placeholder=""
                    label="Remarks"
                    register={methods.register}
                    isMandatory={true}
                    maxLength="50"
                    rows="3"
                    error={methods.error?.remarks?.message}
                  />
                </div>
              </div>

              <div className="mt-3 text-end">
                <button
                  className="btn  btn-primary py-2"
                  type="button"
                  onClick={onSubmit}
                >
                  Submit
                </button>
              </div>
            </form>
          </FormProvider>
        </div>

        <BusyLoader message={busyLoader.message} show={busyLoader.loading} />
      </Modal.Body>
    </Modal>
  );
};

export default memo(UploadNotificationApproveRejectModal);
