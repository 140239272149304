import { sub } from "date-fns";
import completedImg from "../../../assets/imgs/completed.svg";
import inProgressImg from "../../../assets/imgs/in-progress.svg";
import pendingImg from "../../../assets/imgs/pending.svg";
import totalImg from "../../../assets/imgs/total.svg";
const listView = {
  breadcrumb: [
    { link: "/auth/init", name: "Home" },
    { name: "Cash Deposit List" },
  ],
  pagination: {
    totalRecords: 0,
    rowsPerPage: 50,
    activePage: 1,
    startSlNo: 1,
    endSlNo: 50,
  },
  formLabels: {
    status: {
      label: "Status",
    },
    jobType: {
      label: "Job Type",
    },
    _id: {
      label: "ID",
    },
    createdAt: {
      label: "Created on",
      type: "dateRange",
    },
  },
  additionalTableDataConfig: [
    {
      key: "createdBy",
      api: "user",
      loadingKey: "userLoading",
      dataKey: "_createdBy",
      filter: (ids) => ({
        page: 1,
        count: ids.length,
        filter: { _id: { $in: ids } },
        select: "name",
      }),
    },
  ],
  filterFromData: {
    status: "",
    jobType: "",
    id: "",
    createdAt: [sub(new Date(), { days: 30 }), new Date()],
  },
  summaryData: [
    {
      label: "Total",
      value: 0,
      loading: true,
      filter: {},
      color: "primary",
      img: totalImg,
      key: "total",
      isClickable: true,
    },
    {
      label: "Created",
      value: 0,
      loading: true,
      filter: { status: "Created" },
      color: "success",
      img: completedImg,
      isClickable: true,
    },
    {
      label: "Pending Approval",
      value: 0,
      loading: true,
      filter: { status: "PendingApproval" },
      color: "warning",
      img: inProgressImg,
      isClickable: true,
    },
    {
      label: "Proof Pending",
      value: 0,
      loading: true,
      filter: { status: "ProofPending" },
      color: "danger",
      img: pendingImg,
      isClickable: true,
    },
    {
      label: "Approved",
      value: 0,
      loading: true,
      filter: { status: "Approved" },
      color: "success",
      img: completedImg,
      isClickable: true,
    },
  ],
  purchaseSummaryData: [
    {
      label: "Total Purchase",
      value: 0,
      loading: true,
      filter: { paymentMode: "PURCHASE" },
      color: "primary",
      img: completedImg,
      isClickable: true,
      key: "purchase",
      isAmount: true,
    },
    {
      label: "Approved",
      value: 0,
      loading: true,
      filter: { paymentMode: "PURCHASE", status: "Approved" },
      color: "success",
      img: completedImg,
      isClickable: true,
      key: "purchase",
      isAmount: true,
    },
    {
      label: "Pending Approval",
      value: 0,
      loading: true,
      filter: { paymentMode: "PURCHASE", status: "PendingApproval" },
      color: "warning",
      img: inProgressImg,
      isClickable: true,
      key: "purchase",
      isAmount: true,
    },
    {
      label: "Rejected",
      value: 0,
      loading: true,
      filter: { paymentMode: "PURCHASE", status: "Rejected" },
      color: "danger",
      img: pendingImg,
      isClickable: true,
      key: "purchase",
      isAmount: true,
    },
  ],
};

export default listView;
