import { CommissionService } from "@sk/services";
import { useAttachAdditionalData } from "@sk/hooks";
import {
  AppTitle,
  DateFormatter,
  KeyVal,
  NoDataFound,
  PageLoader,
  HighlightText,
  Amount,
} from "@sk/uis";
import { useCallback, useEffect, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import listView from "../../constantService";

const KingCoinsCreditViewModal = ({ show, id, callback }) => {
  const canvasStyle = { width: "50%" };

  const [display, setDisplay] = useState("loading");

  const [setAdditionalData, attachAllData] = useAttachAdditionalData();

  const [data, setData] = useState({});

  // To Close Filter Modal
  const closeModal = () => {
    callback({ status: "closed" });
  };

  useEffect(() => {
    if (show && id) {
      loadDetails(id);
    } else {
      setDisplay("noDataFound");
    }
  }, [loadDetails, id, show]);

  const loadDetails = useCallback(async () => {
    setDisplay("loading");
    const r = await CommissionService.getManualCredits({ filter: { _id: id } });
    const d = Array.isArray(r.resp) ? (r.resp.length ? r.resp[0] : {}) : {};

    setData(d);
    if (d._id) {
      let tmp = [];
      // Attach User Info
      setAdditionalData([d], listView.additionalTableDataConfig, (x) => {
        tmp.push(x);
        if (tmp.length == listView.additionalTableDataConfig.length) {
          const t = [...attachAllData([d], tmp)][0];
          setData((v) => ({ ...v, ...t }));
        }
      });
    }

    if (d._id) {
      setDisplay("details");
    } else {
      setDisplay("noDataFound");
    }
  }, [attachAllData, id, setAdditionalData]);

  return (
    <>
      <Offcanvas
        show={show}
        onHide={closeModal}
        placement="end"
        style={canvasStyle}
      >
        <Offcanvas.Header closeButton className="bg-primary">
          {/* Modal Title */}
          <Offcanvas.Title>
            <AppTitle title={"KingCoins Credit Details #" + id} />
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="p-0 border-top">
          {display == "loading" && <PageLoader />}
          {display == "noDataFound" && (
            <NoDataFound> No Data Found</NoDataFound>
          )}
          {display == "details" && (
            <div>
              {/* Franchise Details */}
              <div className="p-4  bg-light">
                <div className="fw-semibold fs-val-lg mb-2">Franchise</div>
                <div className="text-primary fs-val-lg mb-1">
                  {data?._franchise?.name || "N/A"} - ({data?.franchiseId})
                </div>
                {data?._franchise?.name ? (
                  <div className="text-primary ">
                    {data?._franchise?.district}
                    {data?._franchise?.district ? ", " : null}
                    {data?._franchise?.state}- {data?._franchise?.pincode}
                  </div>
                ) : null}
              </div>

              {/* Basic Details */}
              <div className="p-4 pb-2">
                <div className="fw-semibold fs-val-lg mb-2">Basic Details</div>
                <div className="row">
                  <div className="col-4 mb-3">
                    <KeyVal label="Batch ID" template="col">
                      {data.batchId}
                    </KeyVal>
                  </div>

                  <div className="col-4 mb-4">
                    <KeyVal label="Paid For" template="col">
                      {data?.pointsFor}
                    </KeyVal>
                  </div>

                  <div className="col-4 mb-4">
                    <KeyVal label="Customer Id" template="col">
                      {data?.customerId}
                    </KeyVal>
                  </div>

                  <div className="col-4 mb-4">
                    <KeyVal label="Amount:" template="col">
                      <Amount value={data.amount} decimalPlace="2" />
                    </KeyVal>
                  </div>

                  <div className="col-4 mb-4">
                    <KeyVal label="Retailer Points" template="col">
                      {data?.loyaltyPoints?.retailerPoints}
                    </KeyVal>
                  </div>

                  <div className="col-4 mb-4">
                    <KeyVal label="Status" template="col">
                      <HighlightText status={data.status} />
                    </KeyVal>
                  </div>

                  <div className="col-4 mb-4">
                    <KeyVal label="Created On" template="col">
                      <DateFormatter date={data.createdAt} />
                    </KeyVal>
                  </div>

                  <div className="col-4 mb-4">
                    <KeyVal label="Created By" template="col">
                      {data?._createdBy?.name || "N/A"}
                    </KeyVal>
                  </div>

                  <div className="col-4 mb-4">
                    <KeyVal label="Last Update On" template="col">
                      <DateFormatter date={data.modifiedAt} />
                    </KeyVal>
                  </div>

                  <div className="col-4  mb-4">
                    <KeyVal label="Created By:" template="col">
                      {data?._modifiedBy?.name || "N/A"}
                    </KeyVal>
                  </div>

                  <div className="col-4 mb-4">
                    <KeyVal label="Approved On" template="col">
                      <DateFormatter date={data.approvedAt} />
                    </KeyVal>
                  </div>
                </div>
              </div>

              <div className="p-4 bg-light">
                <div className="fw-semibold fs-val-lg mb-2">Remarks </div>
                {/* Created Remarks  */}
                <div className="row">
                  <div className="col-12 mb-4 pb-4">
                    <KeyVal label="Created Remarks" template="col">
                      {data.remarks || "N/A"}
                    </KeyVal>
                  </div>

                  {/* Approve Remarks */}
                  <div className="row">
                    <div className="col-12 mb-4 ">
                      <KeyVal label="Approved Remarks" template="col">
                        {data.statusRemarks[0] || "N/A"}
                      </KeyVal>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default KingCoinsCreditViewModal;
