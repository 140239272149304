import { AppTitle } from "@sk/uis";
import { useEffect, useState } from "react";
import { Offcanvas } from "react-bootstrap";

const canvasStyle = { width: "60%" };

const HostToHostRawDataModal = ({ show, data, callback }) => {
  const [rawData, setRawData] = useState("");

  useEffect(() => {
    if (show && data) {
      setRawData(JSON.stringify(data.payload, {}, 4));
    }
  }, [data, show]);

  return (
    <>
      <Offcanvas
        show={show}
        onHide={callback}
        placement="end"
        style={canvasStyle}
      >
        <Offcanvas.Header closeButton className="bg-primary">
          {/* Modal Title */}
          <Offcanvas.Title>
            <AppTitle title={`Raw Data #${data._id}`} />
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="p-4  border-top">
          <div>
            <pre>{rawData}</pre>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default HostToHostRawDataModal;
