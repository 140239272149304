import { NavService } from "@sk/services";
import { Amount, AppCard, DateFormatter, TableHeader } from "@sk/uis";

const tableHeader = [
  { label: "P.O. ID", key: "pold" },
  { label: "P.O. Date", key: "poDate" },
  { label: "P.O. Amount", key: "poAmount" },
];

const ReconcilePoDetails = ({ purchaseInfo }) => {
  const viewPo = (poId) => {
    NavService.openInNewTab(`/purchase-order/pos/view?id=${poId}`);
  };

  return (
    <AppCard title="Purchase Details">
      {/* <div className="mb-2 text-muted fs-val-md">
        The store manager made a purchase of{" "}
        <Amount value={purchaseInfo.totalPurchaseAmount} decimalPlace={2} />,
        which has been deducted from the total sales amount. The remaining cash
        was deposited accordingly.
      </div> */}
      <table className="table table-sm">
        <TableHeader data={tableHeader} noBg />
        <tbody className="fs-val-md">
          {(purchaseInfo || []).map((order, index) => (
            <tr key={index}>
              <td>
                <button
                  className="btn btn-link fs-val-md p-0 text-dark text-start"
                  onClick={() => viewPo(order.poId)}
                >
                  {order.poId}
                </button>
              </td>
              <td>
                <DateFormatter date={order.poDate} />
              </td>
              <td>
                <Amount value={order.poAmount} decimalPlace={2} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </AppCard>
  );
};

export default ReconcilePoDetails;
