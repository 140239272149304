import { DatePickerInput, SelectInput } from "@sk/uis";
import { Offcanvas } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import listView from "../constantService";
import { memo } from "react";

const canvasStyle = { width: "30%" };

const defaultFormData = listView.filterFormData;

const createdOnDateConfig = { mode: "range" };

const ChargeConfigAdvanceFilterModal = ({ show, callback }) => {
  const { register, handleSubmit, reset, control } = useForm({
    defaultValues: defaultFormData,
  });

  const onSubmit = (data) => {
    callback({ formData: data, status: "applied" });
  };

  // To Reset Filter Form Data
  const resetFormData = () => {
    reset(defaultFormData);
    callback({ formData: defaultFormData, status: "reset" });
  };

  // To Close Filter Modal
  const closeModal = () => {
    callback({ status: "closed" });
  };

  return (
    <>
      <Offcanvas
        show={show}
        onHide={closeModal}
        placement="end"
        style={canvasStyle}
      >
        <Offcanvas.Header closeButton className="bg-light">
          {/* Modal Title */}
          <Offcanvas.Title>
            <div className="fs-val-lg"> Advance Filter </div>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="p-3  border-top">
          <>
            <div className="row">
              <div className="col-12 mb-4">
                <label className="fw-semibold mb-1 fs-val-md">Created On</label>
                <Controller
                  name="createdAt"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <DatePickerInput
                      placeholder="Choose"
                      value={value}
                      inpChange={onChange}
                      config={createdOnDateConfig}
                    />
                  )}
                />
              </div>

              {/* Status */}
              <div className="col-6 mb-3">
                <SelectInput
                  label="Status"
                  register={register}
                  name="status"
                  isMandatory={false}
                  options={listView.modalStatusOptions}
                />
              </div>
            </div>
            {/*  Action Button */}
            <div className="text-end w-100 mt-4">
              <button
                className="btn me-1 btn-warning btn-sm"
                type="button"
                onClick={resetFormData}
              >
                Reset
              </button>
              <button
                className="btn btn-primary btn-sm"
                type="button"
                onClick={handleSubmit(onSubmit)}
              >
                Apply
              </button>
            </div>
          </>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default memo(ChargeConfigAdvanceFilterModal);
