import { useAttachAdditionalData } from "@sk/hooks";
import { OmsService } from "@sk/services";
import {
  Alert,
  Amount,
  AppCard,
  DateFormatter,
  HighlightText,
  KeyVal,
  PageLoader,
  Spinner,
  ImgPreviewModal,
  TableHeader,
  Toaster,
  ImgRender,
} from "@sk/uis";
import { useCallback, useEffect, useRef, useState } from "react";
import { Offcanvas } from "react-bootstrap";

import CashDepositAuditLogModal from "../components/CashDepositAuditLogTable";

import CashDepositRemarkModal from "./CashDepositRemarkModal";
import ReconcileOrders from "../components/ReconcileOrders";
import ReconcilePoDetails from "../components/ReconcilePoDetails";

const headers = [
  { label: "Sl.no", width: "10%", isCentered: true },
  { label: "Receipt" },
  { label: "Remarks" },
  { label: "Status" },
];

const attachAdditionalDataConfig = [
  {
    key: "createdBy",
    api: "user",
    loadingKey: "userLoading",
    dataKey: "_createdBy",
    filter: (ids) => ({
      page: 1,
      count: ids.length,
      filter: { _id: { $in: ids } },
      select: "name",
    }),
  },
  {
    key: "modifiedBy",
    api: "user",
    loadingKey: "userLoading",
    dataKey: "_lastUpdatedBy",
    filter: (ids) => ({
      page: 1,
      count: ids.length,
      filter: { _id: { $in: ids } },
      select: "name",
    }),
  },
];
const canvasStyle = { width: "65%" };

const prepareRejectParams = (data, remarks) => {
  const rejectedReceipts = data.receipts
    .filter((x) => x.status)
    .map((x) => ({
      id: x.id,
      status: false,
      remarks,
    }));

  return {
    remarks,
    receipts: rejectedReceipts,
  };
};

const rejectCashDeposit = async (id, params) => {
  const r = await OmsService.rejectCashDeposit(id, params);

  if (r.statusCode != 200) {
    return { type: "error", message: r.resp.message };
  } else {
    return { type: "success", message: r.resp.message };
  }
};
const approveCashDeposit = async (id, params) => {
  const r = await OmsService.approveCashDeposit(id, params);

  if (r.statusCode != 200) {
    return { type: "error", message: r.resp.message };
  } else {
    return { type: "success", message: r.resp.message };
  }
};

const confirm = async (status, id) => {
  let res = await Alert.confirm({
    title: "Please confirm",
    text: `Do you want to ${status} receipts ?`,
    icon: "info",
    okText: "Yes",
    cancelText: "No",
  });

  return res.isConfirmed;
};

const ViewCashDepositModal = ({ show, callback, depositId }) => {
  const [setAdditionalData, attachAllData] = useAttachAdditionalData();

  const [loading, setLoading] = useState(true);

  const [submitting, setSubmitting] = useState(false);

  const [showRemarkModal, setShowRemarkModal] = useState(false);

  const [showImgModal, setShowImgModal] = useState(false);

  const [data, setData] = useState({});

  const remarkModalRef = useRef({
    modalData: {},
    details: { status: "" },
  });

  const imgModalRef = useRef({
    asset: "",
  });

  const loadCashDepositDetails = useCallback(async () => {
    setLoading(true);
    const r = await OmsService.getCashDepositList({
      filter: { _id: depositId },
    });

    const d = Array.isArray(r.resp) ? r.resp[0] : {};

    d.userLoading = true;

    setData(d);

    setLoading(false);

    if (d._id) {
      let tmp = [];

      setAdditionalData([d], attachAdditionalDataConfig, (x) => {
        tmp.push(x);
        if (tmp.length == attachAdditionalDataConfig.length) {
          const t = [...attachAllData([d], tmp)][0];
          setData((v) => ({ ...v, ...t }));
        }
      });
    } else {
      Toaster.error("Failed to fetch data, please try again");
      callback();
    }
  }, [attachAllData, callback, depositId, setAdditionalData]);

  const updateStatus = async () => {
    let r = {};

    const remarks = remarkModalRef.current.modalData?.remarks;

    if (!remarks) return;

    const { status } = remarkModalRef.current.details;
    setSubmitting(true);
    if (status == "reject") {
      const p = prepareRejectParams(data, remarks);

      r = await rejectCashDeposit(depositId, p);
    } else {
      r = await approveCashDeposit(depositId, { remarks });
    }
    setSubmitting(false);

    if (r.type == "error") {
      Toaster.error(r.message);
    } else {
      Toaster.success(r.message);
    }
    loadCashDepositDetails();
  };

  const openRemarkModal = async (status) => {
    const isConfirmed = await confirm(status);

    if (!isConfirmed) return;

    remarkModalRef.current.details = {
      status,
    };
    setShowRemarkModal(true);
  };

  const remarkModalCb = ({ data }) => {
    remarkModalRef.current.modalData = data;
    updateStatus();

    setShowRemarkModal(false);
  };

  useEffect(() => {
    if (show && depositId) {
      loadCashDepositDetails();
    } else {
      callback({ status: "close" });
    }
  }, [show, depositId, callback, loadCashDepositDetails]);

  const triggerCloseModal = () => {
    if (submitting) return;
    callback({ status: "close" });
  };

  const openImagePreviewModal = (id) => {
    imgModalRef.current.asset = { id };
    setShowImgModal(true);
  };

  const closeImagePreviewModal = (id) => {
    imgModalRef.current.asset = {};
    setShowImgModal(false);
  };

  return (
    <>
      <Offcanvas
        show={show}
        onHide={triggerCloseModal}
        placement="end"
        style={canvasStyle}
      >
        <Offcanvas.Header closeButton className="bg-primary">
          <Offcanvas.Title>
            <div className="page-title"> Cash Deposit #{depositId} </div>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="modal-bg">
          {show ? (
            <>
              {loading ? (
                <PageLoader />
              ) : (
                <>
                  <AppCard noShadow={true}>
                    <div className="row">
                      <div className="col-3">
                        <KeyVal label="Cash Deposit" template="col">
                          {data._id}
                        </KeyVal>
                      </div>
                      <div className="col-6">
                        <KeyVal template="col" label="Store Name">
                          {data?.franchiseName} -{data?.franchiseId}
                        </KeyVal>
                      </div>

                      <div className="col-3">
                        <KeyVal label="Status" template="col">
                          <HighlightText status={data?.status} isBadge={true} />
                        </KeyVal>
                      </div>

                      <div className="col-12">
                        <div className="row">
                          <div className="col-3">
                            <KeyVal label="Deposit Amount" template="col">
                              <div className="text-primary fw-bold">
                                <Amount value={data?.depositedAmount} />
                              </div>
                            </KeyVal>
                          </div>

                          <div className="col-3">
                            <KeyVal label="Payment Mode" template="col">
                              <HighlightText
                                type={data.paymentModeStatusType}
                                template={2}
                              >
                                {data?._paymentMode}
                              </HighlightText>
                            </KeyVal>
                          </div>

                          {/* Created At */}
                          <div className="col-3">
                            <KeyVal label="Created At" template="col">
                              <DateFormatter date={data.createdAt} />
                            </KeyVal>
                          </div>

                          {/* Created By */}
                          <div className="col-3">
                            <KeyVal label="Created By" template="col">
                              {data.userLoading ? (
                                <Spinner type="dots" />
                              ) : (
                                data?._createdBy?.name || data.createdBy
                              )}
                            </KeyVal>
                          </div>
                        </div>
                      </div>
                    </div>
                  </AppCard>

                  {data.bankName ? (
                    <>
                      <AppCard title="Bank Details">
                        <div className="row">
                          <div className="col-3">
                            <KeyVal template="col" label="Bank Name">
                              {data?.bankName}
                            </KeyVal>
                          </div>
                          <div className="col-3">
                            <KeyVal template="col" label="Account Holder Name">
                              {data?.bankAccountHolderName || "--"}
                            </KeyVal>
                          </div>
                          <div className="col-3">
                            <KeyVal template="col" label="Account No.">
                              {data?.bankAccountNo || "--"}
                            </KeyVal>
                          </div>
                          <div className="col-3">
                            <KeyVal template="col" label="IFSC Code">
                              {data?.bankIfsc || "--"}
                            </KeyVal>
                          </div>
                        </div>
                      </AppCard>
                    </>
                  ) : null}

                  <AppCard title="Receipt Details">
                    <table className="table table-sm">
                      <TableHeader data={headers} noBg />
                      <tbody>
                        {!data.receipts.length ? (
                          <tr className="fs-val-sm">
                            <td
                              colSpan={headers.length}
                              className="text-center"
                            >
                              No Receipt Found
                            </td>
                          </tr>
                        ) : null}

                        {data?.receipts?.length
                          ? data?.receipts?.map((x, i) => (
                              <tr key={i}>
                                <td className="text-center">{i + 1}</td>
                                <td>
                                  <button
                                    className="btn btn-link fs-val-sm border rounded"
                                    onClick={() => openImagePreviewModal(x.id)}
                                  >
                                    <ImgRender
                                      assetId={x.id}
                                      isAsset={true}
                                      width={90}
                                      height={90}
                                    />
                                  </button>
                                </td>
                                <td>{x.remarks || "N/A"}</td>
                                <td>
                                  <HighlightText
                                    status={x.status ? data.status : "Rejected"}
                                  />
                                </td>
                              </tr>
                            ))
                          : null}
                      </tbody>
                    </table>
                  </AppCard>

                  {/* orders list start */}
                  {data.paymentMode !== "PURCHASE" && (
                    <ReconcileOrders depositId={depositId} />
                  )}
                  {/* orders list end */}

                  {data.paymentMode == "PURCHASE" && (
                    <ReconcilePoDetails purchaseInfo={data.purchaseInfo} />
                  )}

                  {/* Audit Log Table */}
                  <CashDepositAuditLogModal auditLogs={data.auditLog} />

                  <CashDepositRemarkModal
                    show={showRemarkModal}
                    callback={remarkModalCb}
                  />
                </>
              )}
            </>
          ) : null}
        </Offcanvas.Body>
        <Offcanvas.Header>
          <div className="col-12">
            <div className="row">
              {["PendingApproval", "ProofPending"].includes(data.status) &&
              (data.paymentMode == "CASH" ||
                data.paymentMode == "UPI" ||
                data.paymentMode == "PURCHASE") ? (
                <>
                  {data.status != "ProofPending" && (
                    <div className="col-auto">
                      <button
                        className="btn btn-outline-danger  fs-val-md"
                        onClick={() => openRemarkModal("reject")}
                        disabled={submitting}
                      >
                        Reject{" "}
                        {submitting &&
                        remarkModalRef.current.details.status == "reject" ? (
                          <Spinner isSmall />
                        ) : null}
                      </button>
                    </div>
                  )}
                  <div className=" ms-auto col-auto">
                    <button
                      className="btn btn-primary mb-2 fs-val-md"
                      onClick={() => openRemarkModal("approve")}
                      disabled={submitting}
                    >
                      Approve
                      {submitting &&
                      remarkModalRef.current.details.status == "approve" ? (
                        <Spinner isSmall />
                      ) : null}
                    </button>
                  </div>
                </>
              ) : null}
            </div>
          </div>
        </Offcanvas.Header>
      </Offcanvas>

      <ImgPreviewModal
        show={showImgModal}
        handleClose={closeImagePreviewModal}
        assets={[imgModalRef.current.asset]}
      />
    </>
  );
};

export default ViewCashDepositModal;
