import { PageNotFound, ProtectedRoute } from "@sk/uis";
import { RouterProvider, createHashRouter } from "react-router-dom";
import Layout from "./pages/Layout";
import ListCashDepositReconcile from "./pages/cash-deposit-reconcile/list/ListCashDepositReconcile";
import ChargeConfigList from "./pages/charge-config/list/ChargeConfigList";
import CreditDebitList from "./pages/credit-debit/list/CreditDebitList";
import HostToHostList from "./pages/host-to-host/list/HostToHostList";
import KingCoinsCreditList from "./pages/kingcoins-credit/list/KingCoinsCreditList";
import ReceiptList from "./pages/manage-receipts/list/ReceiptList";
import SmartMiniStoreList from "./pages/smart-mini-store/list/SmartMiniStoreList";
import UploadNotificationList from "./pages/upload-notifications/list/UploadNotificationList";
import { memo } from "react";

const rbac = {
  cashReconcile: ["DepositReconcile"],
};

const router = createHashRouter([
  {
    path: "/",
    errorElement: <PageNotFound />,
    children: [
      {
        path: "account",
        element: <Layout />,
        children: [
          {
            path: "charge-config",
            element: (
              <ProtectedRoute>
                <ChargeConfigList />
              </ProtectedRoute>
            ),
          },
          {
            path: "host-to-host",
            element: (
              <ProtectedRoute roles={["ListHostToHost"]}>
                <HostToHostList />
              </ProtectedRoute>
            ),
          },
          {
            path: "upload-notifications",
            element: (
              <ProtectedRoute roles={["ListHostToHost"]}>
                <UploadNotificationList />
              </ProtectedRoute>
            ),
          },
          {
            path: "credit-debit",
            element: (
              <ProtectedRoute roles={["ListHostToHost"]}>
                <CreditDebitList />
              </ProtectedRoute>
            ),
          },

          {
            path: "kingcoins-credit",
            element: (
              <ProtectedRoute roles={["ListHostToHost"]}>
                <KingCoinsCreditList />
              </ProtectedRoute>
            ),
          },

          {
            path: "smart-mini-store",
            element: (
              <ProtectedRoute roles={["ListHostToHost"]}>
                <SmartMiniStoreList />
              </ProtectedRoute>
            ),
          },
          {
            path: "receipt/list",
            element: (
              <ProtectedRoute roles={["ViewReceipt"]}>
                <ReceiptList />
              </ProtectedRoute>
            ),
          },
          {
            path: "cash-deposit-reconcile",
            element: (
              <ProtectedRoute roles={rbac.cashReconcile}>
                <ListCashDepositReconcile />
              </ProtectedRoute>
            ),
          },
        ],
      },
    ],
  },
]);

const Router = () => {
  return <RouterProvider router={router} />;
};

export default memo(Router);
