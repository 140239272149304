import { useAttachAdditionalData } from "@sk/hooks";
import { AccountService, CommonService, UtilityService } from "@sk/services";
import {
  AppliedFilterLabel,
  PageInfo,
  PaginationSummary,
  TextInput,
} from "@sk/uis";
import { set } from "date-fns";
import produce from "immer";
import { debounce } from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import listView from "../constantService";
import UploadNotificationSummaryCard from "./components/UploadNotificationSummaryCard";
import UploadNotificationTable from "./components/UploadNotificationTable";
import UploadNotificationAdvanceFilterModal from "./modals/UploadNotificationAdvanceFilterModal";

const defaultSummaryData = listView.summaryData;

const defaultFilterFormData = listView.filterFormData;

const defaultPagination = listView.pagination;

const defaultSortRef = listView.defaultSortOpt;

const getData = async (params) => {
  const r = await AccountService.getUploadNotificationList(params);
  return Array.isArray(r.resp) ? r.resp : [];
};

const getCount = async (params) => {
  delete params.count;
  delete params.page;
  try {
    const r = await AccountService.getUploadNotificationCount(params);
    return { count: r.statusCode == 200 && r.resp ? r.resp.total || 0 : 0 };
  } catch (error) {
    return new Promise((resolve) => resolve({ count: 0 }));
  }
};

const prepareFilterParams = (pagination = {}, filter = {}, sort = {}) => {
  let p = {
    page: pagination.activePage,
    count: pagination.rowsPerPage,
    filter: {},
  };

  let d = filter || {};
  let searchKey = d.name?.length
    ? { $regex: d.name.trim(), $options: "i" }
    : "";

  if (searchKey) {
    p.filter = {
      $or: [{ batchName: searchKey }, { _id: searchKey }],
    };
  }

  if (d.createdAt?.length) {
    p.filter.createdAt = {
      $gte: set(filter.createdAt[0], {
        hours: 0,
        minutes: 0,
        seconds: 0,
      }),
      $lte: set(filter.createdAt[1], {
        hours: 23,
        minutes: 59,
        seconds: 59,
      }),
    };
  }

  if (d.status) {
    p.filter.status = d.status;
  }
  if (sort.key) {
    p.sort = sort.value == "desc" ? `-${sort.key}` : sort.key;
  }

  return p;
};

const UploadNotificationList = () => {
  const { register, getValues } = useForm({
    defaultValues: listView.filterFormData,
  });

  // Use Navigate Hook
  const navigate = useNavigate();

  const [data, setData] = useState([]);

  const [loadingData, setLoadingData] = useState(true);

  // To set Loading state For Get Count API
  const [loadingTotalRecords, setLoadingTotalRecords] = useState(true);

  const [setAdditionalData, attachAllData] = useAttachAdditionalData();

  const [filterLabels, setFilterLabels] = useState([]);

  // To Show Advance Filter Modal
  const [showAdvFilterModal, setShowAdvFilterModal] = useState(false);

  // To Show Modal details Modal
  const [showViewModal, setShowViewModal] = useState(false);

  const [summaryList, setSummaryList] = useState([...listView.summaryData]);

  const filterDataRef = useRef(defaultFilterFormData);

  const paginationRef = useRef(defaultPagination);

  const sortRef = useRef(defaultSortRef);

  const modalInfoRef = useRef("");

  const creditOptions = useRef([{ label: "Select", value: "" }]);

  const [summaryCard, setSummaryCard] = useState(
    [...defaultSummaryData].map((x) => {
      let p = prepareFilterParams(
        defaultPagination,
        defaultFilterFormData,
        defaultSortRef
      );
      if (p.filter) {
        delete p.filter?.isActive;
      }
      return {
        ...x,
        filterParams: { ...p.filter, ...x.filter },
      };
    })
  );

  useEffect(() => {
    init();
  }, [init]);

  const init = useCallback(async () => {
    applyFilter();
  }, [applyFilter]);

  const applyFilter = useCallback(async () => {
    paginationRef.current = { ...defaultPagination };

    prepareFilterLabels();

    const p = getFilterParams();

    loadCount(p);
    loadList();
    loadCreditOptions();
    setLoadingTotalRecords(true);
    const c = await getCount(p);
    paginationRef.current.totalRecords = c.count;
    setLoadingTotalRecords(false);
  }, [loadCount, loadCreditOptions, loadList, prepareFilterLabels]);

  const loadList = useCallback(async () => {
    // for list
    setLoadingData(true);
    const p = getFilterParams();
    const d = await getData(p);
    let tmp = [];

    // Attaching Additional Data
    setAdditionalData(d, listView.additionalTableDataConfig, (x) => {
      tmp.push(x);
      if (tmp.length == listView.additionalTableDataConfig.length) {
        setData([...attachAllData(d, tmp)]);
      }
    });

    setData(d);
    setLoadingData(false);
  }, [attachAllData, setAdditionalData]);

  const loadCreditOptions = useCallback(async () => {
    let obs = await UtilityService.getCreditReason();

    const d = Array.isArray(obs.resp) ? obs.resp : [];

    let opt = d.map((e) => {
      return { label: e.name, value: e.name };
    });
    creditOptions.current = [...creditOptions.current, ...opt];
  }, []);

  // Summary count
  const loadCount = useCallback(async (filter) => {
    delete filter.count;
    delete filter.page;
    const r = await AccountService.getUploadNotificationCount(filter);
    let d = r?.resp ? r?.resp : {};
    setSummaryList(
      produce((draft) => {
        draft.forEach((e, k) => {
          if (e.key == "total") {
            e.value = d.total || 0;
          } else if (e.key == "approved") {
            e.value = d.APPROVED || 0;
          } else if (e.key == "pending") {
            e.value = d.PENDING || 0;
          } else if (e.key == "rejected") {
            e.value = d.REJECTED || 0;
          }
          e.loading = false;
        });
      })
    );
  }, []);

  // Get Filter Params
  const getFilterParams = () => {
    return prepareFilterParams(
      paginationRef.current,
      filterDataRef.current,
      sortRef.current
    );
  };

  // For Applying Filter Params
  const prepareFilterLabels = useCallback(() => {
    const v = { ...(filterDataRef.current || {}) };
    delete v.name;
    const l = CommonService.prepareAppliedFilterLabels(listView.formLabels, v);
    setFilterLabels(l);
  }, []);

  // Pagination Callback
  const paginationCb = useCallback(
    (data) => {
      paginationRef.current.startSlNo = data.startSlNo;
      paginationRef.current.endSlNo = data.endSlNo;
      paginationRef.current.activePage = data.activePage;
      loadList();
    },
    [loadList]
  );

  const sortCb = useCallback(
    (data) => {
      sortRef.current = { ...data };
      applyFilter();
    },
    [applyFilter]
  );

  // For Searching via Brand
  const onSearch = useCallback(
    debounce(() => {
      filterDataRef.current = {
        ...filterDataRef.current,
        name: getValues("name"),
      };
      applyFilter();
    }, 700),
    [applyFilter, getValues]
  );

  // For Opening Advance Filter Modal
  const openAdvFilterModal = useCallback(() => setShowAdvFilterModal(true), []);

  // For Closing Advance Filer Modal
  const closeAdvFilterModal = useCallback(
    () => setShowAdvFilterModal(false),
    []
  );

  // To Handle Advance Filter
  const advFilterCb = (data) => {
    if (["applied", "reset"].indexOf(data.status) != -1) {
      filterDataRef.current = { ...filterDataRef.current, ...data.formData };
      paginationRef.current = { ...defaultPagination };
      applyFilter();
    }
    closeAdvFilterModal(false);
  };

  const viewCb = (data) => {
    modalInfoRef.current = data;
    setShowViewModal(true);
  };

  const closeViewModal = () => {
    modalInfoRef.current = {};
    setShowViewModal(false);
  };

  const tableCb = useCallback(
    (d) => {
      if (d.status == "success") {
        init();
      }
    },
    [init]
  );

  return (
    <>
      <PageInfo
        title="Upload Notifications"
        breadcrumbs={listView.breadcrumb}
        navigate={navigate}
      />

      {/* Summary Card Overview */}
      <UploadNotificationSummaryCard summaryList={summaryList} />

      {/* Filter  Block  */}
      <div className="row align-items-center">
        <div className="col-6">
          <div className="row">
            {/*  Search Input */}
            <div className="col-8 align-self-center">
              <TextInput
                name="name"
                type="text"
                register={register}
                callback={onSearch}
                placeholder="Search By  Batch Name/ID"
              />
            </div>

            {/* Filter Button */}
            <div className="col-auto">
              <button
                className="btn app-filter-btn"
                type="button"
                onClick={openAdvFilterModal}
              >
                <i className="bi bi-funnel"></i> FILTER
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Filter selected Label */}
      <div className="my-3">
        {filterLabels.length > 0 ? (
          <AppliedFilterLabel labels={filterLabels} />
        ) : null}
      </div>

      {/* PAGINATION SUMMARY */}
      <div className="mb-3">
        <PaginationSummary
          paginationConfig={paginationRef.current}
          loadingTotalRecords={loadingTotalRecords}
        />
      </div>

      {/* Table Component */}
      <UploadNotificationTable
        data={data}
        loading={loadingData}
        paginationConfig={paginationRef.current}
        paginationCb={paginationCb}
        loadingTotalRecords={loadingTotalRecords}
        sortCb={sortCb}
        sort={sortRef.current}
        viewCb={viewCb}
        creditOptions={creditOptions.current}
        callback={tableCb}
      />

      {/* Advance Filter Modal  */}
      <UploadNotificationAdvanceFilterModal
        callback={advFilterCb}
        show={showAdvFilterModal}
        formData={filterDataRef.current}
      />
    </>
  );
};

export default UploadNotificationList;
