import { NavService } from "@sk/services";
import {
  DateFormatter,
  HighlightText,
  NoDataFound,
  PaginationBlock,
  Rbac,
  TableHeader,
  TableSkeletonLoader,
  Amount,
} from "@sk/uis";
import { memo } from "react";
import listView from "../../constantService";

const rbac = {
  viewButton: ["ViewkingCoinsCredit"],
};

const KingCoinsCreditTable = ({
  data,
  sort,
  sortCb,
  loading,
  paginationConfig,
  paginationCb,
  loadingTotalRecords,
  viewCb,
}) => {
  return (
    <>
      <table className="table table-bordered bg-white">
        <TableHeader
          data={listView.defaultHeaders}
          sort={sort}
          sortCb={sortCb}
        />
        <tbody>
          {loading && (
            <TableSkeletonLoader
              rows={10}
              cols={listView.defaultHeaders?.length}
              height={40}
            />
          )}

          {!loading && !data.length ? (
            <tr>
              <td colSpan={listView.defaultHeaders.length}>
                <NoDataFound>KingCoin Credits Not Found </NoDataFound>
              </td>
            </tr>
          ) : null}

          {!loading &&
            data.map((x, index) => (
              <tr key={x._id} className="fs-val-md">
                {/* SL No */}
                <td className="text-center">
                  {paginationConfig.startSlNo + index}
                </td>

                {/* ID */}
                <td>{x?._id}</td>

                {/* BatchId */}
                <td>{x.batchId}</td>

                {/* Customer ID */}
                <td> {x?.customerId || "N/A"}</td>

                {/* Paid For */}
                <td>{x?.pointsFor}</td>

                {/* Amount */}
                <td className="text-center">
                  <Amount value={x?.amount} />
                </td>

                {/* Franchise */}
                <td>
                  {x?._franchise?._id ? (
                    <>
                      <div className="text-primary">
                        {x?._franchise?.name} - ({x?._franchise?._id})
                      </div>
                      <div className="fs-val-sm text-muted">
                        {x?._franchise?.district} - ({x?._franchise?.state})
                      </div>
                    </>
                  ) : (
                    "N/A"
                  )}
                </td>

                {/*  Created At */}
                <td>
                  <DateFormatter date={x.createdAt} />
                  {/* <div>by:{x?._createdBy?.name || "N/A"}</div> */}
                </td>

                {/*  Modified On */}
                <td>
                  {x.modifiedAt ? <DateFormatter date={x.modifiedAt} /> : "N/A"}
                  {/* <div>by:{x?._modifiedBy?.name || "N/A"}</div> */}
                </td>

                {/* Status */}
                <td className="text-center">
                  {<HighlightText status={x.status} />}
                </td>

                {/* Action */}
                <td className="text-center">
                  <Rbac roles={rbac.viewButton}>
                    <button
                      className="btn btn-sm btn-outline-primary me-1 fs-val-xs"
                      onClick={() => {
                        viewCb(x);
                      }}
                    >
                      View
                    </button>
                  </Rbac>
                </td>
              </tr>
            ))}
        </tbody>
      </table>

      <PaginationBlock
        paginationConfig={paginationConfig}
        paginationCb={paginationCb}
        loadingTotalRecords={loadingTotalRecords}
      />
    </>
  );
};

export default memo(KingCoinsCreditTable);
