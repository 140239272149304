import { CommonService, PosService } from "@sk/services";
import { AppCard, TextInput } from "@sk/uis";
import { debounce } from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import ReconcileTable from "./ReconcileTable";

const paginationConfig = {
  totalRecords: 0,
  rowsPerPage: 10,
  activePage: 1,
  startSlNo: 1,
  endSlNo: 10,
};
const defaultFromData = {
  search: "",
};

// Preparing Filter Params
const prepareFilterParams = (pagination = {}, filter = {}, outputType = "") => {
  let p = {
    page: pagination.activePage,
    count: pagination.rowsPerPage,
    filter: {
      cashDepositId: filter.cashDepositId,
    },
  };

  const s = (filter.search || "").trim();

  if (s) {
    p.filter.orderId = { $regex: s, $options: "gi" };
  }

  if (outputType) {
    p.outputType = outputType;
  }

  return p;
};

const getData = async (params) => {
  const r = await PosService.getPosSettlementList(params);
  return r.resp.length ? r.resp : [];
};

function ReconcileOrders({ depositId }) {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [loadingTotalRecords, setLoadingTotalRecords] = useState(true);
  const paginationRef = useRef({ ...paginationConfig });
  const filterDataRef = useRef({ ...defaultFromData });

  const { register, getValues, reset, setValue } = useForm({
    defaultValues: { ...defaultFromData },
  });

  useEffect(() => {
    init();
  }, []);

  const init = () => {
    applyFilter();
  };
  const getCount = async (params) => {
    try {
      let p = {
        ...params,
        // outputType: "count",
        // filter: { cashDepositId: "SKCD150	" },
      };

      const r = await PosService.getPosSettlementList(p);
      return { count: r.statusCode == 200 && r.resp[0] ? r.resp[0].total : 0 };
    } catch (error) {
      return new Promise((resolve) => resolve({ count: 0 }));
    }
  };
  const loadCount = useCallback(async () => {
    setLoadingTotalRecords(true);

    const p = getFilterParams("count");
    const c = await getCount(p);
    paginationRef.current.totalRecords = c.count;

    setLoadingTotalRecords(false);
  }, [getFilterParams]);

  const applyFilter = useCallback(async () => {
    // Resetting pagination
    paginationRef.current = { ...paginationConfig };
    const { search } = getValues();

    filterDataRef.current = {
      ...defaultFromData,
      cashDepositId: depositId,
      search: search,
    };

    loadList();
    loadCount();
  }, [depositId, loadList, loadCount]);

  const getFilterParams = useCallback(
    (outputType) => {
      return prepareFilterParams(
        paginationRef.current,
        filterDataRef.current,
        outputType
      );
    },
    [depositId]
  );

  const loadList = useCallback(async () => {
    setLoading(true);

    const p = getFilterParams("list");

    const d = await getData(p);

    setData(d);
    setLoading(false);
  }, [getFilterParams]);

  const paginationCb = useCallback(
    (data) => {
      paginationRef.current.startSlNo = data.startSlNo;
      paginationRef.current.endSlNo = data.endSlNo;
      paginationRef.current.activePage = data.activePage;
      loadList();
    },
    [loadList]
  );

  const onClickDownload = async () => {
    const p = getFilterParams("download");

    let r = await PosService.getPosSettlementList(p);
    // let r = await PosService.posCashDepositDownload(p);
    if (r.statusCode === 200) {
      const { filename, downloadLink } = r.resp;
      const file = await CommonService.downloadAsset(downloadLink);
    }
  };

  const searchCb = useCallback(
    debounce(() => {
      filterDataRef.current = { ...getValues() };
      applyFilter();
    }, 800),
    [applyFilter, setValue]
  );

  return (
    <AppCard title="Orders List">
      <div className="d-flex justify-content-between w-100">
        <div className="w-50">
          <TextInput
            name="search"
            type="text"
            label=""
            register={register}
            placeholder="Search By OrderId"
            callback={searchCb}
          />
        </div>
        <div>
          <Button
            className="btn btn-primary fs-val-md me-2 mt-1"
            onClick={onClickDownload}
          >
            Download
          </Button>
        </div>
      </div>

      <ReconcileTable
        data={data}
        loading={loading}
        paginationConfig={paginationRef.current}
        paginationCb={paginationCb}
        loadingTotalRecords={loadingTotalRecords}
      />
    </AppCard>
  );
}

export default ReconcileOrders;
