import {
  Amount,
  HighlightText,
  NoDataFound,
  PaginationBlock,
  Rbac,
  Spinner,
  TableHeader,
  TableSkeletonLoader,
  DateFormatter,
} from "@sk/uis";

import formatDistanceToNow from "date-fns/formatDistanceToNow";
import { useCallback, useRef, useState } from "react";
import ViewReceiptModal from "../modal/ViewReceiptModal";

const headers = [
  { label: "Sl.no", isCentered: true },
  { label: "Receipt ID", isCentered: true },
  { label: "Payment Mode", isCentered: true, width: "6%" },
  { label: "Franchise", width: "20%" },
  { label: "Remarks" },
  { label: "To Bank" },
  { label: "Amount", isCentered: true },
  { label: "Created On" },
  { label: "Status", isCentered: true },
  { label: "Actions", isCentered: true },
];

const rbac = {
  viewButton: ["ViewReceipt"],
};

const Table = ({
  data,
  loading,
  sort,
  sortCb,
  paginationConfig,
  paginationCb,
  loadingTotalRecords,
  callback,
}) => {
  const [showViewModal, setShowViewModal] = useState(false);

  const viewModalRef = useRef({ id: "" });

  const viewModalCb = useCallback(
    (payload) => {
      viewModalRef.current.id = "";
      if (payload.action == "rejected" || payload.action == "pendingApproval") {
        let t = setTimeout(() => {
          callback({ status: "success" });
          clearTimeout(t);
        }, 100);
      }
      setShowViewModal(false);
    },
    [callback]
  );

  const openViewModal = useCallback((id) => {
    viewModalRef.current.id = id;
    setShowViewModal(true);
  }, []);

  return (
    <>
      <table className="table table-bordered bg-white">
        <TableHeader data={headers} sort={sort} sortCb={sortCb} />

        <tbody>
          {loading ? (
            <TableSkeletonLoader
              rows={paginationConfig.rowsPerPage}
              cols={headers.length}
            />
          ) : null}

          {!loading && !data.length ? (
            <tr>
              <td colSpan={headers.length}>
                <NoDataFound>No Receipt found</NoDataFound>
              </td>
            </tr>
          ) : null}

          {!loading && data.length
            ? data.map((x, index) => (
                <tr key={x._id} className="fs-val-md">
                  {/* SL No */}
                  <td className="text-center">
                    {paginationConfig.startSlNo + index}
                  </td>

                  {/*  Id*/}
                  <td className="text-center fw-semibold text-primary">
                    {x._id}
                  </td>

                  {/* Payment Mode */}
                  <td className="text-center">{x.paymentType}</td>

                  <td>
                    {x.franchiseLoading ? (
                      <Spinner type="dots" />
                    ) : (
                      <>
                        <div className="text-primary mb-2">
                          {x._franchise.name}-{x.franchiseId}
                        </div>
                        <div className="fs-val-sm mb-1">
                          <span className="text-muted">State : </span>
                          <span className="fw-semibold me-2">
                            {x?._franchise?.state || "N/A"}
                          </span>
                          <span className="text-muted">District : </span>
                          <span className="fw-semibold">
                            {x?._franchise?.district || "N/A"}
                          </span>
                        </div>
                        <div className="fs-val-sm">
                          {formatDistanceToNow(
                            new Date(x?._franchise?.createdAt)
                          )}
                        </div>
                      </>
                    )}
                  </td>
                  <td className="text-break">{x.makerComments || "N/A"}</td>
                  <td>{x.bank}</td>
                  <td className="text-center">
                    {<Amount value={x.amount} decimalPlace={2} />}
                  </td>
                  <td>
                    <div>
                      <DateFormatter date={x.createdAt} />
                    </div>

                    {x.createdLoading ? (
                      <Spinner type="dots" />
                    ) : (
                      <div> by {x._createdBy.name || "N/A"} </div>
                    )}
                  </td>

                  <td className="text-center">
                    {<HighlightText status={x.status} />}
                  </td>
                  <td className="text-center">
                    <Rbac roles={rbac.viewButton}>
                      <button
                        className="btn btn-sm btn-outline-primary me-1 fs-val-xs"
                        onClick={() => openViewModal(x._id)}
                      >
                        View
                      </button>
                    </Rbac>
                  </td>
                </tr>
              ))
            : null}
        </tbody>
      </table>

      <PaginationBlock
        paginationConfig={paginationConfig}
        paginationCb={paginationCb}
        loadingTotalRecords={loadingTotalRecords}
      />

      <ViewReceiptModal
        callback={viewModalCb}
        show={showViewModal}
        id={viewModalRef.current.id}
      />
    </>
  );
};

export default Table;
