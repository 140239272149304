/* eslint-disable jsx-a11y/label-has-associated-control */
import { useAttachAdditionalData } from "@sk/hooks";
import { AuthService, AccountService, CommonService } from "@sk/services";

import {
  Amount,
  AppTitle,
  DateFormatter,
  HighlightText,
  KeyVal,
  PageLoader,
  Spinner,
  Toaster,
} from "@sk/uis";
import { formatDistanceToNow } from "date-fns";

import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";

import { Alert, Offcanvas } from "react-bootstrap";

import produce from "immer";

import AuditLogManageReceipt from "../components/AuditLogManageReceipt";

import ReceiptRemarksModal from "./ReceiptRemarksModal";

import { receiptView } from "../../constantService";

// Canvas Style
const style = {
  offCanvasHeaderStyle: {
    backgroundColor: "#e4edff",
  },
  offCanvasStyle: {
    width: "60%",
  },
};

const loginUserId = AuthService.getLoggedInEmp().userId;

const ViewReceiptModal = ({ show, callback, id }) => {
  const [setAdditionalData, attachAllData] = useAttachAdditionalData();

  const [pageLoading, setPageLoading] = useState(true);

  const [showReceiptRemarksModal, setShowReceiptRemarksModal] = useState(false);

  const [warnings, setWarnings] = useState({
    isFirstReceipt: false,
    isDistanceMore: false,
  });

  const [checkedInputs, setCheckedInputs] = useState({
    isFirstReceiptChecked: false,
    isDistanceMoreChecked: false,
  });

  const [submitting, setSubmitting] = useState(false);

  const [details, setDetails] = useState({});

  const remarkModalRef = useRef({ feature: "" });

  const isWarnings = useMemo(() => {
    return Object.values(warnings).some((x) => x);
  }, [warnings]);

  const isWarningsChecked = useMemo(() => {
    if (["Rejected", "Accounted"].indexOf(details.status) != -1) {
      return true;
    }
    let list = Object.keys(warnings).filter((x) => {
      return warnings[x];
    });
    return list.every((x) => checkedInputs[x + "Checked"]);
  }, [details.status, warnings, checkedInputs]);

  useEffect(() => {
    if (show) {
      if (id) {
        loadReceiptDetails(id);
      } else {
        Toaster.error("Invalid ID");
        triggerCloseModal();
      }
    } else {
      resetData();
    }
  }, [id, show, triggerCloseModal, loadReceiptDetails, resetData]);

  // To Close Modal
  const triggerCloseModal = useCallback(
    (action = "close") => {
      setPageLoading(true);
      callback({ action });
    },
    [callback]
  );

  const loadReceiptDetails = useCallback(async () => {
    setPageLoading(true);
    const r = await AccountService.getReceiptById(id);

    const d = r.resp || {};
    const f = await AccountService.getReceiptList({
      page: 1,
      count: 10,
      filter: { franchiseId: d.franchiseId },
    });
    const fr = f.resp || [];

    if (fr.length <= 1) {
      // if current franchise i only having one or less then it is first receipt
      setWarnings((prev) => {
        return {
          ...prev,
          isFirstReceipt: true,
        };
      });
    }

    setDetails(d);
    d.franchiseLoading = true;
    d.createdLoading = true;
    d.modifiedLoading = true;

    if (d._id) {
      let tmp = [];
      // Attach User Info
      setAdditionalData(
        [d],
        [...receiptView.attachAdditionalDataConfig],
        (x) => {
          tmp.push(x);

          if (tmp.length == receiptView.attachAdditionalDataConfig.length) {
            const t = [...attachAllData([d], tmp)][0];
            if (t.geoLocation) {
              let lat1 = t.geoLocation?.lat || 77.4421584;
              let lon1 = t.geoLocation?.long || 12.886582;
              let lat2 =
                t._franchise?.shop_details?.geolocation?.coordinates[0];
              let lon2 =
                t._franchise?.shop_details?.geolocation?.coordinates[1];

              let distance = CommonService.distanceBetweenCoordinates({
                lat1,
                lon1,
                lat2,
                lon2,
              });

              distance = CommonService.roundedByDecimalPlace(distance, 2);

              setWarnings(
                produce((draft) => {
                  draft.isDistanceMore = distance <= 10 ? false : true;
                })
              );
              t._distance = distance;
            }
            setDetails((v) => ({ ...v, ...t }));
          }
        }
      );
    } else {
      Toaster.error("Failed to fetch data, please try again");
      triggerCloseModal();
    }

    setPageLoading(false);
  }, [attachAllData, id, setAdditionalData, triggerCloseModal]);

  const openRemarksModal = (feature) => {
    remarkModalRef.current.feature = feature;
    setShowReceiptRemarksModal(true);
  };

  const receiptRemarkCb = (payload) => {
    if (["cancel", "reject"].indexOf(payload.action) != -1) {
      onRejectReceipt(payload.formData, payload.action);
    } else if (payload.action == "sendForApproval") {
      onSendForApproval(payload.formData);
    } else if (payload.action == "approve") {
      onApprove(payload.formData);
    }

    setShowReceiptRemarksModal(false);
  };

  const onRejectReceipt = async (formData, action) => {
    setSubmitting(true);
    const p = {
      rejectedReason: formData.remarks ? formData.remarks : formData.reason,
    };
    const r = await AccountService.rejectReceipt(id, p);

    if (r.resp?.[0]?.message) {
      Toaster.error(r.resp?.[0]?.message);
      setSubmitting(false);
      return;
    }

    let msg = "";
    if (action == "reject") {
      msg = `Receipt id : ${id} is rejected successfully `;
    } else {
      msg = `Receipt id : ${id} has been Canceled successfully `;
    }
    Toaster.success(msg);
    callback({ action: "rejected" });

    setSubmitting(false);
  };

  const onSendForApproval = async (formData) => {
    setSubmitting(true);
    const p = {
      makerComments: formData.remarks,
      status: "Pending Approval",
    };
    const r = await AccountService.sendToApproveReceipt(id, p);

    if (r.resp?.[0]?.message) {
      Toaster.error(r.resp?.[0]?.message);
      setSubmitting(false);
      return;
    }

    Toaster.success(`Receipt id : ${id} is send for Approval successfully `);
    callback({ action: "pendingApproval" });

    setSubmitting(false);
  };

  const onApprove = async (formData) => {
    setSubmitting(true);
    const p = {
      makerComments: formData.remarks,
    };
    const r = await AccountService.approveReceipt(id, p);

    if (r.resp?.message) {
      Toaster.error(r.resp?.message);
      setSubmitting(false);
      return;
    }

    Toaster.success(`Receipt id : ${id} is  Approved successfully `);
    callback({ action: "approve" });

    setSubmitting(false);
  };

  const onCheckboxChange = (e) => {
    setCheckedInputs(
      produce((draft) => {
        draft[e.target.name] = e.target.checked;
      })
    );
  };

  const resetData = useCallback(() => {
    setWarnings({
      isFirstReceipt: false,
      isDistanceMore: false,
    });
    setCheckedInputs({
      isFirstReceiptChecked: false,
      isDistanceMoreChecked: false,
    });
  }, []);

  return (
    <>
      <Offcanvas
        show={show}
        onHide={triggerCloseModal}
        backdrop="static"
        keyboard={false}
        placement="end"
        style={style.offCanvasStyle}
      >
        <Offcanvas.Header
          closeButton
          closeVariant="white"
          style={style.offCanvasHeaderStyle}
        >
          {/* Modal Title */}
          <AppTitle
            title={`View Receipt #${id} `}
            className="fs-val-lg text-dark px-2"
          />
        </Offcanvas.Header>
        <Offcanvas.Body className="p-0">
          {pageLoading ? (
            <PageLoader />
          ) : (
            show && (
              <>
                {isWarnings &&
                  ["Rejected", "Accounted"].indexOf(details.status) == -1 && (
                    <div className="p-4 bg-white border-bottom">
                      <div className="row">
                        <div className="col-12">
                          <Alert variant="danger">
                            <div className="fw-normal fs-val-lg mb-3">
                              <span className="fw-semibold">Warnings</span> :
                              This receipt is suspicious, Please check all
                              Warnings before approving all
                            </div>
                            {warnings.isFirstReceipt && (
                              <div>
                                <input
                                  type="checkbox"
                                  className=" form-check-input me-1"
                                  name="isFirstReceiptChecked"
                                  checked={checkedInputs.isFirstReceiptChecked}
                                  onChange={onCheckboxChange}
                                />
                                <span className="form-check-label">
                                  This Receipt is 1st Receipt for this Retailer.
                                </span>
                              </div>
                            )}

                            {warnings.isDistanceMore && (
                              <div>
                                <input
                                  type="checkbox"
                                  className=" form-check-input me-1"
                                  name="isDistanceMoreChecked"
                                  checked={checkedInputs.isDistanceMoreChecked}
                                  onChange={onCheckboxChange}
                                />
                                <span className="form-check-label">
                                  Distance between Retailer GeoCode & Receipt
                                  Geocode is more than {details._distance} km.
                                </span>
                              </div>
                            )}
                          </Alert>
                        </div>
                      </div>
                    </div>
                  )}

                {/* Franchise Details */}
                <div className="p-4 bg-white">
                  <div className="row">
                    <div className="col-12">
                      <div className="fw-semibold fs-val-lg mb-3">
                        Franchise Details
                      </div>
                    </div>
                    {/* Franchise Name */}
                    <div className="col-3 mb-4">
                      <KeyVal label="Name" template="col">
                        {details.franchiseLoading ? (
                          <Spinner type="dots" />
                        ) : (
                          details?._franchise?.name
                        )}
                      </KeyVal>
                    </div>

                    {/* Franchise ID */}
                    <div className="col-3 mb-4">
                      <KeyVal label="Franchise ID" template="col">
                        {details?.franchiseId}
                      </KeyVal>
                    </div>

                    {/* Franchise State/District */}
                    <div className="col-3 mb-4">
                      <KeyVal label="State / District" template="col">
                        {details.franchiseLoading ? (
                          <Spinner type="dots" />
                        ) : (
                          details?._franchise?.state +
                          "/" +
                          details?._franchise?.town
                        )}
                      </KeyVal>
                    </div>

                    {/* Franchise Account Number */}
                    <div className="col-3 mb-4">
                      <KeyVal label="Account No." template="col">
                        {details?.accountNo}
                      </KeyVal>
                    </div>

                    {/* Franchise Mobile */}
                    <div className="col-3 mb-4">
                      <KeyVal label="Mobile" template="col">
                        {details.franchiseLoading ? (
                          <Spinner type="dots" />
                        ) : (
                          details?._franchise?.contact_details?.[0]?.mobile
                        )}
                      </KeyVal>
                    </div>

                    {/* Created on  */}
                    <div className="col-3 mb-4">
                      <KeyVal label="Created on" template="col">
                        {details.franchiseLoading ? (
                          <Spinner type="dots" />
                        ) : (
                          <>
                            <DateFormatter
                              date={details?._franchise?.createdAt}
                            />
                            <div className="fs-val-sm text-danger fw-normal">
                              {formatDistanceToNow(
                                new Date(details?._franchise?.createdAt)
                              )}
                            </div>
                          </>
                        )}
                      </KeyVal>
                    </div>
                  </div>
                </div>

                {/* Receipt Details */}
                <div className="p-4 bg-light border-bottom">
                  <div className="row">
                    <div className="col-12">
                      <div className="fw-semibold fs-val-lg mb-3">
                        Receipt Details
                      </div>
                    </div>
                    {/* Receipt ID  */}
                    <div className="col-3 mb-4">
                      <KeyVal label="ID" template="col">
                        {details._id}
                      </KeyVal>
                    </div>

                    <div className="col-3 mb-4">
                      <KeyVal label="Amount" template="col">
                        <Amount value={details?.amount} decimalPlace={2} />
                      </KeyVal>
                    </div>

                    {/* Status */}
                    <div className="col-3 mb-4">
                      <KeyVal label="Status" template="col">
                        <HighlightText status={details?.status} />
                      </KeyVal>
                    </div>

                    {/* Payment Type */}
                    <div className="col-3 mb-4">
                      <KeyVal label="Payment Type" template="col">
                        {details?.paymentType}
                      </KeyVal>
                    </div>

                    {/* Wallet Types */}
                    <div className="col-3 mb-4">
                      <KeyVal label="Mobile" template="col">
                        {details?.walletType}
                      </KeyVal>
                    </div>

                    {/* Created on  */}
                    <div className="col-3 mb-4">
                      <KeyVal label="Created on" template="col">
                        <DateFormatter date={details?.createdAt} />
                      </KeyVal>
                    </div>

                    {/* Created By  */}
                    <div className="col-3 mb-4">
                      <KeyVal label="Created By" template="col">
                        {details.createdLoading ? (
                          <Spinner type="dots" />
                        ) : (
                          <span>{details?._createdBy?.name || "N/A"}</span>
                        )}
                      </KeyVal>
                    </div>

                    {/* Created on  */}
                    <div className="col-3 mb-4">
                      <KeyVal label="Last Updated" template="col">
                        <DateFormatter date={details?.lastUpdated} />
                      </KeyVal>
                    </div>

                    {/* Modified By  */}
                    {details?.modifiedBy ? (
                      <div className="col-3 mb-4">
                        <KeyVal label="Last Modified By" template="col">
                          {details?.modifiedLoading ? (
                            <Spinner />
                          ) : (
                            <span>{details?._modifiedBy?.name || "N/A"}</span>
                          )}
                        </KeyVal>
                      </div>
                    ) : null}
                  </div>
                </div>

                {/* Bank Details */}
                <div className="p-4 bg-white border-bottom">
                  <div className="row">
                    <div className="col-12">
                      <div className="fw-semibold fs-val-lg mb-3">
                        Bank Details
                      </div>
                    </div>
                    {/* Receipt ID  */}
                    <div className="col-3 mb-4">
                      <KeyVal label="To Bank" template="col">
                        {details?.bank}
                      </KeyVal>
                    </div>

                    <div className="col-3 mb-4">
                      <KeyVal label="To Bank IFSC Code" template="col">
                        {details?.ifscCode || "N/A"}
                      </KeyVal>
                    </div>

                    {/* Status */}
                    <div className="col-3 mb-4">
                      <KeyVal label="Receipt Charge Status " template="col">
                        <HighlightText
                          status={details?.receiptChargeData.status}
                        />
                      </KeyVal>
                    </div>

                    {/* Status */}
                    <div className="col-3 mb-4">
                      <KeyVal label="Receipt Charge Amount " template="col">
                        <Amount
                          value={details?.receiptChargeData?.amount}
                          decimalPlace={2}
                        />
                      </KeyVal>
                    </div>

                    {/* Cheque No  */}
                    {details?.chequeNo ? (
                      <div className="col-3 mb-4">
                        <KeyVal label="Cheque Number " template="col">
                          {details?.chequeNo}
                        </KeyVal>
                      </div>
                    ) : null}

                    {/* UTR Number  */}
                    {details?.utr ? (
                      <div className="col-3 mb-4">
                        <KeyVal label="UTR Number" template="col">
                          {details?.utr}
                        </KeyVal>
                      </div>
                    ) : null}

                    {/* UTR Number  */}
                    {details?.fromBank ? (
                      <div className="col-3 mb-4">
                        <KeyVal label="From Bank" template="col">
                          {details?.fromBank}
                        </KeyVal>
                      </div>
                    ) : null}
                  </div>
                </div>

                <AuditLogManageReceipt
                  auditLogs={details?.auditLogList || []}
                />
              </>
            )
          )}
        </Offcanvas.Body>
        <Offcanvas.Header>
          <div className="col-12 text-end">
            {["Pending Approval", "Created"].indexOf(details.status) != -1 &&
              details.modifiedBy != loginUserId && (
                <>
                  <button
                    className="btn btn-outline-danger me-1"
                    onClick={() => openRemarksModal("reject")}
                    disabled={submitting || !isWarningsChecked}
                  >
                    Reject Receipt
                    {submitting ? <Spinner isSmall={true} /> : null}
                  </button>
                </>
              )}
            {details.status == "Created" && (
              <button
                className="btn btn-primary me-1"
                disabled={submitting || !isWarningsChecked}
                onClick={() => openRemarksModal("sendForApproval")}
              >
                Send for Approval
                {submitting ? <Spinner isSmall={true} /> : null}
              </button>
            )}

            {details.status == "Pending Approval" &&
              details.modifiedBy != loginUserId && (
                <button
                  className="btn btn-primary me-1"
                  disabled={submitting || !isWarningsChecked}
                  onClick={() => openRemarksModal("approve")}
                >
                  Approve Receipt
                  {submitting ? <Spinner isSmall={true} /> : null}
                </button>
              )}

            {details.status == "Pending Approval" &&
              details.modifiedBy == loginUserId && (
                <button
                  className="btn btn-danger me-1"
                  disabled={submitting || !isWarningsChecked}
                  onClick={() => openRemarksModal("cancel")}
                >
                  Cancel Receipt
                  {submitting ? <Spinner isSmall={true} /> : null}
                </button>
              )}
          </div>
        </Offcanvas.Header>
      </Offcanvas>

      <ReceiptRemarksModal
        show={showReceiptRemarksModal}
        callback={receiptRemarkCb}
        feature={remarkModalRef.current.feature}
      />
    </>
  );
};

export default memo(ViewReceiptModal);
