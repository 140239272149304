import {
  DateFormatter,
  HighlightText,
  NoDataFound,
  Rbac,
  TableHeader,
  TableSkeletonLoader,
} from "@sk/uis";
import { memo } from "react";
import listView from "../constantService";

const rbac = {
  editButton: ["EditChargeConfig"],
  viewButton: ["ViewChargeConfig"],
};

const ChargeConfigTable = ({ data, sort, sortCb, loading, viewCb, editCb }) => {
  return (
    <>
      <table className="table table-bordered bg-white">
        <TableHeader
          data={listView.defaultHeaders}
          sort={sort}
          sortCb={sortCb}
        />
        <tbody>
          {loading && (
            <TableSkeletonLoader
              rows={10}
              cols={listView.defaultHeaders?.length}
              height={40}
            />
          )}

          {!loading && !data?.length ? (
            <tr>
              <td colSpan={listView.defaultHeaders.length}>
                <NoDataFound>Notification Template Not Found </NoDataFound>
              </td>
            </tr>
          ) : null}

          {!loading &&
            (data || []).map((x, index) => (
              <tr key={x._id + index} className="fs-val-md">
                {/* SL No */}
                <td className="text-center">{1 + index}</td>

                {/* Bank ID */}
                <td>{x?.bankId}</td>

                {/* Bank Name */}
                <td>{x?.bank}</td>

                {/* Charges Table */}
                <td>
                  <table className="table table-bordered bg-white">
                    <TableHeader data={listView.defaultChargesHeaders} />
                    <tbody>
                      {x.slab.map((k, i) => (
                        <tr key={i}>
                          {/* Sl No */}
                          <td className="text-center">{i + 1}</td>

                          {/* From */}
                          <td className="text-center">{k.from}</td>

                          {/* To */}
                          <td className="text-center">{k.to}</td>

                          {/* Charge Type */}
                          <td className="text-center">{k.chargeAmount.type}</td>

                          {/* Charge Value */}
                          <td className="text-center">
                            {k.chargeAmount.value}
                          </td>

                          {/* Max Value */}
                          <td className="text-center">
                            {k.chargeAmount.maxValue}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </td>

                {/*  Created At */}
                <td>
                  <DateFormatter date={x.createdAt} />
                  <div>by {x?._createdBy?.name || "N/A"}</div>
                </td>

                {/* Status */}
                <td className="text-center">
                  <HighlightText status={x?.isActive ? "Active" : "Inactive"} />
                </td>

                {/* Action */}
                <td className="text-center">
                  <Rbac roles={rbac.viewButton}>
                    <button
                      className="btn btn-sm btn-outline-primary me-1 fs-val-xs"
                      onClick={() => {
                        viewCb(x);
                      }}
                    >
                      View
                    </button>
                  </Rbac>
                  <Rbac roles={rbac.editButton}>
                    <button
                      className="btn btn-sm btn-outline-warning me-1 fs-val-xs"
                      onClick={() => {
                        editCb(x);
                      }}
                    >
                      Edit
                    </button>
                  </Rbac>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </>
  );
};

export default memo(ChargeConfigTable);
