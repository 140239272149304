import { SelectInput, TextareaInput } from "@sk/uis";
import { memo, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useForm, useWatch } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const rejectReasonsOptions = [
  { label: "Select Reason", value: "" },

  { label: "Wrong Amount Entered", value: "Wrong Amount Entered" },

  { label: "Invalid Deposit Request", value: "Invalid Deposit Request" },
  { label: "Duplicate Deposit Request", value: "Duplicate Deposit Request" },
  {
    label: "Invalid Deposit Details Provided",
    value: "Invalid Deposit Details Provided",
  },
  {
    label: "Amount is not received to Stroking Bank",
    value: "Amount is not received to Stroking Bank",
  },
  {
    label: "Request is already raised under express deposit",
    value: "Request is already raised under express deposit",
  },
  {
    label: "Wrong Payment Type Selected",
    value: "Wrong Payment Type Selected",
  },
  { label: "Others", value: "Others" },
];

const validationSchema = yup.object({
  reason: yup.string().when("feature", {
    is: (feature) => feature == "reject",
    then: (schema) => schema.label("Reason").required("Reason is required"),
  }),

  remarks: yup
    .string()
    .trim()
    .when(["reason", "feature"], {
      is: (reason, feature) => reason == "Others" && feature == "reject",
      then: (schema) => schema.label("Remarks").required("Remarks is required"),
    })
    .when("feature", {
      is: (feature) => feature == "sendForApproval" || feature == "approve",
      then: (schema) => schema.label("Remarks").required("Remarks is required"),
    }),
});

const ReceiptRemarksModal = ({ show, callback, feature }) => {
  const {
    register,
    control,
    reset,
    setValue,
    handleSubmit,

    formState: { errors },
  } = useForm({
    defaultValues: {
      reason: "",
      remarks: "",
      feature: "",
    },
    resolver: yupResolver(validationSchema),
  });

  const [title, setTitle] = useState("");

  const reason = useWatch({
    control,
    name: "reason",
  });

  useEffect(() => {
    if (!show) {
      reset();
      if (feature == "reject") {
        setTitle("Reason for Reject Receipt");
      } else if (feature == "cancel") {
        setTitle("Reason for Cancel Receipt");
      } else {
        setTitle("Remark for Receipt Approval");
      }
    } else {
      setValue("feature", feature);
    }
  }, [reset, show, feature, setValue]);

  const triggerCloseModal = () => {
    callback({ action: "close" });
  };

  const onSubmit = (data) => {
    callback({ action: feature, formData: data });
  };

  return (
    <Modal show={show} onHide={triggerCloseModal} centered>
      <Modal.Header closeButton>
        <Modal.Title className="mb-0">{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="col-12">
          {["cancel", "reject"].indexOf(feature) != -1 && (
            <SelectInput
              register={register}
              name="reason"
              error={errors?.reason?.message}
              options={rejectReasonsOptions}
              label={feature == "reject" ? "Rejected Reason" : "Cancel Reason"}
              isMandatory={true}
            />
          )}

          {(reason == "Others" ||
            ["cancel", "reject"].indexOf(feature) == -1) && (
            <TextareaInput
              name="remarks"
              register={register}
              label="Remarks"
              isMandatory={true}
              error={errors?.remarks?.message}
            />
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="col-12">
          <button className="btn btn-primary" onClick={handleSubmit(onSubmit)}>
            Submit
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default ReceiptRemarksModal;
