import { useAttachAdditionalData } from "@sk/hooks";
import { AccountService, CommonService, UtilityService } from "@sk/services";
import {
  AppliedFilterLabel,
  PageInfo,
  PaginationSummary,
  TextInput,
  Rbac,
} from "@sk/uis";
import { set } from "date-fns";
import { debounce } from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import listView from "../constantService";
import CreditDebitSummaryCard from "./components/CreditDebitSummaryCard";
import CreditDebitTable from "./components/CreditDebitTable";
import CreditDebitAdvanceFilter from "./modals/CreditDebitAdvanceFilter";
import CreditDebitViewModal from "./modals/CreditDebitViewModal";
import CreditDebitFormModal from "./modals/CreditDebitFormModal";

const defaultSummaryData = listView.summaryData;

const defaultFilterFormData = listView.filterFormData;

const defaultPagination = listView.pagination;

const defaultSortOpt = { key: "createdAt", value: "dec" };

const rbac = {
  createDebit: ["CreateDebitNote"],
  createCredit: ["CreateCreditNote"],
};

const getData = async (params) => {
  const r = await AccountService.getCreditDebitList(params);
  return Array.isArray(r.resp) ? r.resp : [];
};

const getCount = async (params) => {
  delete params.count;
  delete params.page;
  try {
    const r = await AccountService.getCreditDebitCount(params);
    return { count: r.statusCode == 200 && r.resp ? r.resp : 0 };
  } catch (error) {
    return new Promise((resolve) => resolve({ count: 0 }));
  }
};

const prepareFilterParams = (pagination = {}, filter = {}, sort = {}) => {
  let p = {
    page: pagination.activePage,
    count: pagination.rowsPerPage,
    filter: {},
  };

  let d = filter || {};
  let searchKey = d.name?.length
    ? { $regex: d.name.trim(), $options: "i" }
    : "";

  if (sort?.key) {
    p.sort = {
      [sort.key]: sort.value == "asc" ? 1 : -1,
    };
  }

  if (searchKey) {
    p.filter = {
      $or: [{ batchId: searchKey }, { _id: searchKey }],
    };
  }

  if (d.createdAt?.length) {
    p.filter.createdAt = {
      $gte: set(filter.createdAt[0], {
        hours: 0,
        minutes: 0,
        seconds: 0,
      }),
      $lte: set(filter.createdAt[1], {
        hours: 23,
        minutes: 59,
        seconds: 59,
      }),
    };
  }
  if (d.status) {
    p.filter.status = d.status;
  }

  if (d.type) {
    p.filter.type = d.type;
  }

  if (d.franchise?.length) {
    p.filter.franchiseId = d.franchise[0].value?._id;
  }

  if (d?.state?.length) {
    if (!p.filter.franchise) {
      p.filter.franchise = {};
    }
    p.filter.franchise.state = d.state;
  }

  if (d.district) {
    p.filter.franchise.district = d.district;
  }

  if (d.town) {
    p.filter.franchise.city = d.town;
  }

  if (sort.key) {
    p.sort = sort.value == "desc" ? `-${sort.key}` : sort.key;
  }

  return p;
};

const CreditDebitList = () => {
  const { register, getValues } = useForm({
    defaultValues: listView.filterFormData,
  });

  // Use Navigate Hook
  const navigate = useNavigate();

  const [data, setData] = useState([]);

  const [loadingData, setLoadingData] = useState(true);

  // To set Loading state For Get Count API
  const [loadingTotalRecords, setLoadingTotalRecords] = useState(true);

  const [setAdditionalData, attachAllData] = useAttachAdditionalData();

  const [filterLabels, setFilterLabels] = useState([]);

  // To Show Advance Filter Modal
  const [showAdvFilterModal, setShowAdvFilterModal] = useState(false);

  // To Show View Modal details Modal
  const [showViewModal, setShowViewModal] = useState(false);

  // To Show Credit / Debit Form Modal details Modal
  const [showFormModal, setShowFormModal] = useState(false);

  const filterDataRef = useRef(defaultFilterFormData);

  const paginationRef = useRef({ ...defaultPagination });

  const sortRef = useRef({ ...defaultSortOpt });

  // state data
  const stateListRef = useRef([]);

  const formModalRef = useRef({});

  const viewModalRef = useRef({});

  const [summaryCard, setSummaryCard] = useState([]);

  useEffect(() => {
    init();
  }, [init]);

  const init = useCallback(async () => {
    applyFilter();
    loadStateList();
  }, [applyFilter, loadStateList]);

  const applyFilter = useCallback(async () => {
    paginationRef.current = { ...defaultPagination };
    prepareFilterLabels();
    updateSummaryCard();
    loadList();
    setLoadingTotalRecords(true);
    const p = getFilterParams();
    const c = await getCount(p);
    paginationRef.current.totalRecords = c.count;
    setLoadingTotalRecords(false);
  }, [loadList, prepareFilterLabels, updateSummaryCard]);

  const updateSummaryCard = useCallback(() => {
    let s = [...defaultSummaryData].map((x) => {
      let p = getFilterParams();
      if (p.filter) {
        delete p.filter?.isActive;
      }
      return {
        ...x,
        filterParams: { ...p.filter, ...x.filter },
      };
    });
    setSummaryCard(s);
  }, []);

  // Default State Value
  const loadStateList = useCallback(async () => {
    let r = await UtilityService.getStateList();

    let list = (r.resp || []).map((e) => {
      return { label: e.name, value: e.name };
    });
    list.unshift({ label: "All", value: "" });
    stateListRef.current = [...list];
  }, [stateListRef]);

  const loadList = useCallback(async () => {
    // for list
    setLoadingData(true);
    setData([]);
    const p = getFilterParams();
    const d = await getData(p);
    let tmp = [];

    // Attaching Additional Data
    setAdditionalData(d, listView.additionalTableDataConfig, (x) => {
      tmp.push(x);
      if (tmp.length == listView.additionalTableDataConfig.length) {
        setData([...attachAllData(d, tmp)]);
      }
    });

    setData(d);
    setLoadingData(false);
  }, [attachAllData, setAdditionalData]);

  // Get Filter Params
  const getFilterParams = () => {
    return prepareFilterParams(
      paginationRef.current,
      filterDataRef.current,
      sortRef.current
    );
  };

  // For Applying Filter Params
  const prepareFilterLabels = useCallback(() => {
    const v = { ...(filterDataRef.current || {}) };
    delete v.name;
    if (v.noOfRows == 10) {
      delete v.noOfRows;
    }
    const l = CommonService.prepareAppliedFilterLabels(listView.formLabels, v);
    setFilterLabels(l);
  }, []);

  // Pagination Callback
  const paginationCb = useCallback(
    (data) => {
      paginationRef.current.startSlNo = data.startSlNo;
      paginationRef.current.endSlNo = data.endSlNo;
      paginationRef.current.activePage = data.activePage;
      loadList();
    },
    [loadList]
  );

  const sortCb = useCallback(
    (data) => {
      sortRef.current = { ...data };
      applyFilter();
    },
    [applyFilter]
  );

  const tableCb = useCallback(
    (d) => {
      if (d.status == "success") {
        init();
      }
    },
    [init]
  );

  // For Searching via Brand
  const onSearch = useCallback(
    debounce(() => {
      applyFilter();
    }, 700),
    [applyFilter, getValues]
  );

  // For Opening Advance Filter Modal
  const openAdvFilterModal = useCallback(() => setShowAdvFilterModal(true), []);

  // For Closing Advance Filer Modal
  const closeAdvFilterModal = useCallback(
    () => setShowAdvFilterModal(false),
    []
  );

  // To Handle Advance Filter
  const advFilterCb = (data) => {
    if (["applied", "reset"].indexOf(data.status) != -1) {
      filterDataRef.current = { ...filterDataRef.current, ...data.formData };
      if (filterDataRef.current?.noOfRows) {
        noOfRowChange(filterDataRef.current?.noOfRows);
      }
      applyFilter();
    }
    closeAdvFilterModal(false);
  };

  const noOfRowChange = (noOfRows) => {
    paginationRef.current = {
      totalRecords: 0,
      rowsPerPage: noOfRows,
      activePage: 1,
      startSlNo: 1,
      endSlNo: noOfRows,
    };
  };

  const viewCb = (data) => {
    viewModalRef.current = data;
    setShowViewModal(true);
  };

  const closeViewModal = useCallback(() => {
    viewModalRef.current = {};
    setShowViewModal(false);
  }, []);

  const closeFormModal = useCallback(
    (d) => {
      formModalRef.current = {};
      setShowFormModal(false);
      if (d.status == "success") {
        init();
      }
    },
    [init]
  );

  const openCreditModal = (type) => {
    formModalRef.current = { type: type };
    setShowFormModal(true);
  };

  return (
    <>
      <PageInfo
        title="Credit Debit"
        breadcrumbs={listView.breadcrumb}
        navigate={navigate}
      />

      {/* Summary Card Overview */}
      <div className="row mb-2">
        {summaryCard.map((card, i) => (
          <div className="col" key={i}>
            <CreditDebitSummaryCard
              label={card.label}
              color={card.color}
              filterParams={card.filterParams}
              img={card.img}
            />
          </div>
        ))}
      </div>

      {/* Filter  Block  */}
      <div className="row align-items-center">
        <div className="col-6">
          <div className="row">
            {/*  Search Input */}
            <div className="col-8 align-self-center">
              <TextInput
                name="name"
                type="text"
                register={register}
                callback={onSearch}
                placeholder="Search By  Credit ID/ Batch ID"
              />
            </div>

            {/* Filter Button */}
            <div className="col-auto">
              <button
                className="btn app-filter-btn"
                type="button"
                onClick={openAdvFilterModal}
              >
                <i className="bi bi-funnel"></i> FILTER
              </button>
            </div>
          </div>
        </div>
        <div className="col-auto ms-auto">
          <Rbac roles={rbac.createCredit}>
            <button
              className="btn btn-success me-2"
              onClick={() => openCreditModal("Credit")}
            >
              Add Credit Note
            </button>
          </Rbac>

          <Rbac roles={rbac.createDebit}>
            <button
              className="btn btn-primary me-2"
              onClick={() => openCreditModal("Debit")}
            >
              Add Debit Note
            </button>
          </Rbac>
        </div>
      </div>

      {/* Filter selected Label */}
      <div className="my-3">
        {filterLabels.length > 0 ? (
          <AppliedFilterLabel labels={filterLabels} />
        ) : null}
      </div>

      {/* PAGINATION SUMMARY */}
      <div className="mb-3">
        <PaginationSummary
          paginationConfig={paginationRef.current}
          loadingTotalRecords={loadingTotalRecords}
        />
      </div>

      {/* Table Component */}
      <CreditDebitTable
        data={data}
        loading={loadingData}
        paginationConfig={paginationRef.current}
        paginationCb={paginationCb}
        loadingTotalRecords={loadingTotalRecords}
        sortCb={sortCb}
        sort={sortRef.current}
        viewCb={viewCb}
        callback={tableCb}
      />

      {/* Advance Filter Modal  */}
      <CreditDebitAdvanceFilter
        callback={advFilterCb}
        show={showAdvFilterModal}
        formData={filterDataRef.current}
        stateOptions={stateListRef.current}
      />

      {/* Form  Modal */}
      <CreditDebitFormModal
        show={showFormModal}
        callback={closeFormModal}
        type={formModalRef.current.type}
      />

      {/* View Modal */}
      <CreditDebitViewModal
        show={showViewModal}
        callback={closeViewModal}
        creditNoteId={viewModalRef.current._id}
      />
    </>
  );
};

export default CreditDebitList;
