import { useAttachAdditionalData } from "@sk/hooks";
import { CommissionService, CommonService } from "@sk/services";
import {
  AppliedFilterLabel,
  PageInfo,
  PaginationSummary,
  TextInput,
} from "@sk/uis";
import { set } from "date-fns";
import { debounce } from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import listView from "../constantService";
import KingCoinsCreditSummaryCard from "./components/KingCoinsCreditSummaryCard";
import KingCoinsCreditTable from "./components/KingCoinsCreditTable";
import KingCoinsCreditAdvanceFilterModal from "./modals/KingCoinsCreditAdvanceFilterModal";
import KingCoinsCreditViewModal from "./modals/KingCoinsCreditViewModal";

const defaultSummaryData = listView.summaryData;

const defaultFilterFormData = listView.filterFormData;

const defaultPagination = listView.pagination;

const defaultSort = listView.defaultSortOpt;

const getData = async (params) => {
  const r = await CommissionService.getManualCredits(params);
  return Array.isArray(r.resp) ? r.resp : [];
};

const getCount = async (params) => {
  delete params.count;
  delete params.page;
  try {
    const r = await CommissionService.getManualCreditCount(params);
    return { count: r.statusCode == 200 && r.resp ? r.resp : 0 };
  } catch (error) {
    return new Promise((resolve) => resolve({ count: 0 }));
  }
};

const prepareFilterParams = (pagination = {}, filter = {}, sort = {}) => {
  let p = {
    page: pagination.activePage,
    count: pagination.rowsPerPage,
    filter: {},
  };

  let d = filter || {};
  let searchKey = d.name?.length
    ? { $regex: d.name.trim(), $options: "i" }
    : "";

  if (searchKey) {
    p.filter = {
      $or: [
        { customerId: searchKey },
        { batchId: searchKey },
        { _id: searchKey },
      ],
    };
  }

  if (d?.createdAt?.length) {
    p.filter.createdAt = {
      $gte: set(filter.createdAt[0], {
        hours: 0,
        minutes: 0,
        seconds: 0,
      }),
      $lte: set(filter.createdAt[1], {
        hours: 23,
        minutes: 59,
        seconds: 59,
      }),
    };
  }

  if (d?.status) {
    p.filter.status = d.status;
  }

  if (d?.franchise?.length) {
    p.filter.franchiseId = d.franchise?.[0]?.value?._id;
  }

  if (sort?.key) {
    p.sort = sort.value == "desc" ? `-${sort.key}` : sort.key;
  }

  return p;
};

const KingCoinsCreditList = () => {
  const { register, getValues } = useForm({
    defaultValues: listView.filterFormData,
  });

  // Use Navigate Hook
  const navigate = useNavigate();

  const [data, setData] = useState([]);

  const [loadingData, setLoadingData] = useState(true);

  // To set Loading state For Get Count API
  const [loadingTotalRecords, setLoadingTotalRecords] = useState(true);

  const [setAdditionalData, attachAllData] = useAttachAdditionalData();

  const [filterLabels, setFilterLabels] = useState([]);

  // To Show Advance Filter Modal
  const [showAdvFilterModal, setShowAdvFilterModal] = useState(false);

  const digitalPartnerOptionsRef = useRef([]);

  // To Show Modal details Modal
  const [showViewModal, setShowViewModal] = useState({
    status: false,
    data: {},
  });

  const filterDataRef = useRef(defaultFilterFormData);

  const paginationRef = useRef(defaultPagination);

  const sortRef = useRef(defaultSort);

  const [summaryCard, setSummaryCard] = useState([]);

  useEffect(() => {
    init();
  }, [init]);

  const init = useCallback(async () => {
    applyFilter();
  }, [applyFilter]);

  const applyFilter = useCallback(async () => {
    paginationRef.current = { ...defaultPagination };

    prepareFilterLabels();
    loadSummaryCard();
    loadList();

    setLoadingTotalRecords(true);
    const p = getFilterParams();
    const c = await getCount(p);
    paginationRef.current.totalRecords = c.count;
    setLoadingTotalRecords(false);
  }, [loadList, loadSummaryCard, prepareFilterLabels]);

  const loadList = useCallback(async () => {
    // for list
    setLoadingData(true);
    const p = getFilterParams();
    const d = await getData(p);
    let tmp = [];

    // Attaching Additional Data
    setAdditionalData(d, listView.additionalTableDataConfig, (x) => {
      tmp.push(x);
      if (tmp.length == listView.additionalTableDataConfig.length) {
        setData([...attachAllData(d, tmp)]);
      }
    });

    setData(d);
    setLoadingData(false);
  }, [attachAllData, setAdditionalData]);

  // Get Filter Params
  const getFilterParams = () => {
    return prepareFilterParams(
      paginationRef.current,
      filterDataRef.current,
      sortRef.current
    );
  };

  const loadSummaryCard = useCallback(() => {
    let s = [...defaultSummaryData].map((x) => {
      let p = getFilterParams();
      if (p.filter && x.label != "Total") {
        delete p.filter?.status;
      }
      return {
        ...x,
        filterParams: { ...p.filter, ...x.filter },
      };
    });
    setSummaryCard(s);
  }, []);

  // For Applying Filter Params
  const prepareFilterLabels = useCallback(() => {
    const v = { ...(filterDataRef.current || {}) };
    delete v.name;
    let s = digitalPartnerOptionsRef.current.find((k) => k.value === v.partner);
    s?.label ? (v.partner = s?.label) : "";
    const l = CommonService.prepareAppliedFilterLabels(listView.formLabels, v);
    setFilterLabels(l);
  }, []);

  // Pagination Callback
  const paginationCb = useCallback(
    (data) => {
      paginationRef.current.startSlNo = data.startSlNo;
      paginationRef.current.endSlNo = data.endSlNo;
      paginationRef.current.activePage = data.activePage;
      loadList();
    },
    [loadList]
  );

  const sortCb = useCallback(
    (data) => {
      sortRef.current = { ...data };
      applyFilter();
    },
    [applyFilter]
  );

  // For Searching via Brand
  const onSearch = useCallback(
    debounce(() => {
      filterDataRef.current = {
        ...filterDataRef.current,
        name: getValues("name"),
      };
      applyFilter();
    }, 700),
    [applyFilter, getValues]
  );

  // For Opening Advance Filter Modal
  const openAdvFilterModal = useCallback(() => setShowAdvFilterModal(true), []);

  // For Closing Advance Filer Modal
  const closeAdvFilterModal = useCallback(
    () => setShowAdvFilterModal(false),
    []
  );

  // To Handle Advance Filter
  const advFilterCb = (data) => {
    if (["applied", "reset"].indexOf(data.status) != -1) {
      filterDataRef.current = { ...filterDataRef.current, ...data.formData };
      paginationRef.current = { ...defaultPagination };
      applyFilter();
    }
    closeAdvFilterModal(false);
  };

  const viewCb = (data) => {
    setShowViewModal({ status: true, data: data });
  };

  const closeViewModal = () => {
    setShowViewModal({ status: false, data: {} });
  };

  return (
    <>
      <PageInfo
        title="KingCoins Credit"
        breadcrumbs={listView.breadcrumb}
        navigate={navigate}
      />

      {/* Summary Card Overview */}
      <div className="row mb-2">
        {summaryCard.map((card, i) => (
          <div className="col" key={i}>
            <KingCoinsCreditSummaryCard
              label={card.label}
              color={card.color}
              filterParams={card.filterParams}
              img={card.img}
            />
          </div>
        ))}
      </div>

      {/* Filter  Block  */}
      <div className="row align-items-center">
        <div className="col-6">
          <div className="row">
            {/*  Search Input */}
            <div className="col-8 align-self-center">
              <TextInput
                name="name"
                type="text"
                register={register}
                callback={onSearch}
                placeholder="Search By  Id/ Batch ID/ Customer ID"
              />
            </div>

            {/* Filter Button */}
            <div className="col-auto">
              <button
                className="btn app-filter-btn"
                type="button"
                onClick={openAdvFilterModal}
              >
                <i className="bi bi-funnel"></i> FILTER
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Filter selected Label */}
      <div className="my-3">
        {filterLabels.length > 0 ? (
          <AppliedFilterLabel labels={filterLabels} />
        ) : null}
      </div>

      {/* PAGINATION SUMMARY */}
      <div className="mb-3">
        <PaginationSummary
          paginationConfig={paginationRef.current}
          loadingTotalRecords={loadingTotalRecords}
        />
      </div>

      {/* Table Component */}
      <KingCoinsCreditTable
        data={data}
        loading={loadingData}
        paginationConfig={paginationRef.current}
        paginationCb={paginationCb}
        loadingTotalRecords={loadingTotalRecords}
        sortCb={sortCb}
        sort={sortRef.current}
        viewCb={viewCb}
      />

      {/* Advance Filter Modal  */}
      <KingCoinsCreditAdvanceFilterModal
        callback={advFilterCb}
        show={showAdvFilterModal}
      />

      <KingCoinsCreditViewModal
        show={showViewModal.status}
        callback={closeViewModal}
        id={showViewModal.data._id}
      />
    </>
  );
};

export default KingCoinsCreditList;
